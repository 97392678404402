import React from "react";
import StrategicPlanningList from "../../components/Top20SPC/StrategicPlanningList";

import { strategicPlanningListDummy } from "../../components/types";

const StrategicPlanningListPage = () => {
  return (
    <div>
      <StrategicPlanningList PlanningList={strategicPlanningListDummy} />
    </div>
  );
};

export default StrategicPlanningListPage;
