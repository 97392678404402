import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";

const LoginPage = () => {
  const navigate = useNavigate()
  useEffect(() => {
    if(localStorage.getItem("token")) {
      navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return <LoginForm />;
};

export default LoginPage;
