import React from "react";
import CurrentMonth from "../../components/Performance/CurrentMonth";

const CurrentMonthPage = () => {
  return (
    <div>
      <CurrentMonth />
    </div>
  );
};

export default CurrentMonthPage;
