import React, { useEffect, useState } from "react";
import { Form, Link, useParams } from "react-router-dom";

import Button from "../../../UI/button";
import { tokenLoader } from "../../../UI/auth";
import Back from "../../../image/back.png";
import Spinner from "../../../UI/Spinner";
import { IContactList } from "../../../UI/interface";

const ContactsListFacility = () => {
  const { RFE_ID } = useParams();
  const [contactList, setContactList] = useState<IContactList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  // Fetch contact list data based on RFE_ID
  useEffect(() => {
    const fetchContactList = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/externalsources/facilitycontact/list/${RFE_ID}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await response.json();
      setContactList(jsonResponse);
      setIsLoading(false);
    };
    fetchContactList();
  }, [RFE_ID]);

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div>
          <div>
            <Link to="/ExternalReferralSourcesList">
              <img
                src={Back}
                className="h-10 md:h-10 rounded-full"
                alt="Back Button"
              />
            </Link>
            <div className="text-center">
              <h1 className="text-[30px] mt-[-40px]">Contact List</h1>
            </div>
          </div>
          <div>
            <Form className="mt-6 p-2 border-2 rounded-md">
              <div className="my-2 h-[600px] text-left bg-white overflow-scroll">
                <table className="w-full">
                  <thead>
                    <tr className="bg-blue-900 h-8 top-0 sticky">
                      <th className="pl-1 font-normal">Name</th>
                      <th className=" pl-1 font-normal">Position</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="text-black">
                    {contactList.map((contact) => {
                      return (
                        <tr
                          className="odd:bg-white even:bg-slate-200 border-b-2"
                          key={contact.ContactID}
                        >
                          <td className="w-[160px] md:w-[] pl-3">
                            {contact.Contact_Fullname}
                          </td>
                          <td className="w-[75px] pl-3">
                            {contact.Contact_Position}
                          </td>
                          <td className="w-[120px] text-center">
                            <Link to={`${contact.ContactID}`}>
                              <Button>Details</Button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="mt-2">
                <Link to={`/ExternalReferralSourcesList/ContactList/${RFE_ID}/addContact`}>
                  <Button>Add Contact</Button>
                </Link>
              </div>
            </Form>
            {isLoading && <Spinner/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsListFacility;
