import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import LoginPage from "./Pages/LoginPage";
import { action as logoutAction } from "./Pages/Logout";
import HomePage from "./Pages/HomePage";

//Performance
import CurrentMonthPage from "./Pages/Performance/CurrentMonthPage";
import PreviousMonthPage from "./Pages/Performance/PreviousMonthPage";
import COPPage from "./Pages/Performance/COPPage";
import PatientListPage from "./Pages/Performance/PatientListPage";

//External Sources
import ReferralSourceListPage from "./Pages/ExternalSources/ReferralSourceListPage";
import FacilityFormPage from "./Pages/ExternalSources/ReferralSources/FacilityFormPage";
import ContactListPage from "./Pages/ExternalSources/ReferralSources/ContactListPage";
import ContactFormPage from "./Pages/ExternalSources/ReferralSources/ContactFormPage";
import VisitHistoryListPage from "./Pages/ExternalSources/VisitHistoryListPage";
import ViewHistoryDetailsPage from "./Pages/ExternalSources/ViewHistoryDetailsPage";
import LastAdmitListPage from "./Pages/ExternalSources/LastAdmitListPage";
import JoinCallVisitListPage from "./Pages/ExternalSources/JoinCallVisitListPage";
import JointCallDetailsPage from "./Pages/ExternalSources/JointCallDetailsPage";

//Meetings
import MeetingVisitsPage from "./Pages/Meetings/MeetingsVisitsPage";
import MeetingCurrentListPage from "./Pages/Meetings/MeetingCurrentListPage";
import MeetingPreviousListPage from "./Pages/Meetings/MeetingPreviousListPage";
import MeetingDetailsPage from "./Pages/Meetings/MeetingDetailsPage";
import AttachReceiptPage from "./Pages/Meetings/AttachReceiptPage";
import ReceiptListPage from "./Pages/Meetings/ReceiptListPage";
import MeetingPrintOutPage from "./Pages/Meetings/MeetingPrintOutPage";
import ViewMerPage from "./Pages/Meetings/PDFView/ViewMerPage";
import ViewVisitPage from "./Pages/Meetings/PDFView/ViewVisitPage";

//Presentation
import MarketerPresentationPage from "./Pages/Presentation/MarketerPresentationPage";
import PatientSurveyPage from "./Pages/Presentation/PatientSurveyPage";
import SurveyFormPage from "./Pages/Presentation/SurveyFormPage";
import CovidQuestionnairePage from "./Pages/Presentation/CovidQuestionarePage";

//Top 20 SPC
import StrategicPlanningListPage from "./Pages/Top20SPC/StrategicPlanningListPage";
import SPCAddPage from "./Pages/Top20SPC/SPCAddPage";
import SPCRankingListPage from "./Pages/Top20SPC/SPCRankingListPage";
import SPCRankingAddPage from "./Pages/Top20SPC/SPCRankingAddPage";

//More
import HrPage from "./Pages/More/HrPage";
import CreateViewLeavePage from "./Pages/More/CreateViewLeavePage";
import InsuranceListPage from "./Pages/More/InsuranceListPage";

//UI
import { tokenLoader } from "./UI/auth";
import BusinessOpportunityCodePage from "./Pages/Performance/BusinessOpportunityCodePage";
import ReceiptDetailsPage from "./Pages/Meetings/ReceiptDetailsPage";


const router = createBrowserRouter([
  {
    path: "/",
    loader: tokenLoader,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      // Login Route
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "logout",
        action: logoutAction,
      },
      // Performance Route
      {
        path: "PerformanceCurrent",
        element: <CurrentMonthPage />,
      },
      {
        path: "PerformancePrevious",
        element: <PreviousMonthPage />,
      },
      {
        path: "PerformanceCOP",
        element: <COPPage />,
      },
      {
        path: "PatientList",
        element: <PatientListPage />,
      },
      {
        path: "BusinessOpportunityCode",
        element: <BusinessOpportunityCodePage />,
      },
      // External Sources Route
      {
        path: "ExternalReferralSourcesList",
        element: <ReferralSourceListPage />,
      },
      {
        path: "ExternalReferralSourcesList/facility",
        element: <FacilityFormPage />,
      },
      {
        path: "ExternalReferralSourcesList/:RFE_ID",
        element: <FacilityFormPage />,
      },
      {
        path: "ExternalReferralSourcesList/ContactList/:RFE_ID",
        element: <ContactListPage />,
      },
      {
        path: "ExternalReferralSourcesList/ContactList/:RFE_ID/:ContactID",
        element: <ContactFormPage />,
      },
      {
        path: "ExternalReferralSourcesList/ContactList/:RFE_ID/addContact",
        element: <ContactFormPage />,
      },
      {
        path: "ExternalReferralSourcesList/ViewHistory",
        element: <VisitHistoryListPage />,
      },
      {
        path: "ExternalReferralSourcesList/ViewHistory/Details",
        element: <ViewHistoryDetailsPage />,
      },
      {
        path: "ExternalSourcesLastAdmit",
        element: <LastAdmitListPage />,
      },
      {
        path: "ExternalSourcesJointCall",
        element: <JoinCallVisitListPage />,
      },
      {
        path: "ExternalSourcesJointCall/Details",
        element: <JointCallDetailsPage />,
      },
      // Meetings Route
      {
        path: "meetingVisit",
        element: <MeetingVisitsPage />,
      },
      {
        path: "meetingCurrent",
        element: <MeetingCurrentListPage />,
      },
      {
        path: "meetingCurrent/:VisitCTR",
        element: <MeetingDetailsPage />,
      },
      {
        path: "meetingPrevious",
        element: <MeetingPreviousListPage />,
      },
      {
        path: "meetingPrevious/:VisitCTR",
        element: <MeetingDetailsPage />,
      },
      {
        path: "meetingCurrent/:VisitCTR/:fullName/attachReceipt",
        element: <AttachReceiptPage />,
      },
      {
        path: "meetingPrevious/:VisitCTR/:fullName/attachReceipt",
        element: <AttachReceiptPage />,
      },
      {
        path: "meetingCurrent/:VisitCTR/ViewReceipt",
        element: <ReceiptListPage />,
      },
      {
        path: "meetingPrevious/:VisitCTR/ViewReceipt",
        element: <ReceiptListPage />,
      },
      {
        path: "meetingCurrent/:VisitCTR/ViewReceipt/:Receipt_CTR",
        element: <ReceiptDetailsPage />,
      },
      {
        path: "meetingPrevious/:VisitCTR/ViewReceipt/:Receipt_CTR",
        element: <ReceiptDetailsPage />,
      },
      {
        path: "meetingPrintOut",
        element: <MeetingPrintOutPage />,
      },
      {
        path: "meetingPrintOut/:ReimbursementDateID/:CutoffDateFrom/:CutoffDateTo/:ReimbursementDateDescription",
        element: <ViewMerPage />,
      },
      {
        path: "meetingPrintOut/:CutoffDateFrom/:CutoffDateTo/:ReimbursementDateDescription",
        element: <ViewVisitPage />,
      },
      // Presentation Route
      {
        path: "MarketerPresentation",
        element: <MarketerPresentationPage />,
      },
      {
        path: "PatientSurvey",
        element: <PatientSurveyPage />,
      },
      {
        path: "PatientSurvey/PreAdmission",
        element: <SurveyFormPage />,
      },
      {
        path: "CovidQuestionaire",
        element: <CovidQuestionnairePage />,
      },
      // Top 20 SPC Route
      {
        path: "StrategicPlanningList",
        element: <StrategicPlanningListPage />,
      },
      {
        path: "StrategicPlanningList/Add",
        element: <SPCAddPage />,
      },
      {
        path: "StrategicPlanningList/Ranking",
        element: <SPCRankingListPage />,
      },
      {
        path: "StrategicPlanningList/Ranking/Add",
        element: <SPCRankingAddPage />,
      },
      //More
      {
        path: "HrSlVl",
        element: <HrPage />,
      },
      {
        path: "HrSlVl/CreateViewLeaveRequest",
        element: <CreateViewLeavePage />,
      },
      {
        path: "InsuranceList",
        element: <InsuranceListPage />,
      },
    ],
  },
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
