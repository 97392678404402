import React from "react";
import { Form } from "react-router-dom";
import Button from "../../UI/button";

const CreateViewLeaveForm = () => {
  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <Form>
          <div>
            <div className="md:flex">
              <ul className="w-[210px]">
                <li>
                  <label htmlFor="">Search</label>
                  <input type="text" />
                </li>
                <li>
                  <Button>Search</Button>
                  <Button>Clear</Button>
                </li>
              </ul>
              <div className="border-2 ml-1">
                <h1 className="text-center">Filter By</h1>
                <ul className="">
                  <li>
                    <h1>Request Leave</h1>
                    <label htmlFor="">From</label>
                    <input
                      type="date"
                      className="w-[115px] ml-1 pl-1 text-black"
                    />
                    <label htmlFor="" className="ml-1">
                      To
                    </label>
                    <input
                      type="date"
                      className="w-[115px] ml-1 pl-1 text-black"
                    />
                  </li>
                  <ul className="flex">
                    <li className="w-[125px]">
                      <label htmlFor="">Leave Status</label>
                      <select name="" id="" className="w-[120px] text-black">
                        <option value="Pending">Pending</option>
                      </select>
                    </li>
                    <li className="w-[125px] ml-2">
                      <label htmlFor="">Pending Status</label>
                      <select name="" id="" className="w-[120px] text-black">
                        <option value="test"></option>
                      </select>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateViewLeaveForm;
