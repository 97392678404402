import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  container: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
  },
  image: {
    width: 75,
    height: 75,
  },
  label: {
    fontSize: 10,
    fontFamily: "Helvetica-Bold",
    margin: 2,
    textAlign: "left",
  },
  midWidth: {
    width: 100,
  },
  narrowWidth : {
    width: 50,
  },
  wideWidth: {
    width: 175,
  },
  dataContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 12,
  },
  commonText: {
    fontSize: 10,
    margin: 2,
  },
  iconStyle: {
    width: 10,
    height: 10,
  },
  line: {
    height: 1,
    backgroundColor: "#000",
    marginVertical: 10,
    width: "100%",
  },
  labelText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    marginRight: 5,
  },
});
