import React from "react";
import MeetingDetails from "../../components/Meetings/MeetingDetailsForm";

const MeetingDetailsPage = () => {
  return (
    <div>
      <MeetingDetails />
    </div>
  );
};

export default MeetingDetailsPage;
