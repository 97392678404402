import React from "react";
import MoreHrSlVl from "../../components/More/MoreHrSlVl";

const HrPage = () => {
  return (
    <div>
      <MoreHrSlVl />
    </div>
  );
};

export default HrPage;
