const Button = (props: any) => {
  return (
    <button
      type={props.type || "button"}
      className={`bg-[#1da9e9] text-white py-1 px-5 rounded-md border-2 shadow-inner cursor-pointer ${props.className}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
