import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Page, Text, View, Document, Image, pdf } from "@react-pdf/renderer";
import moment from "moment-timezone";

import HeaderPdf from "./HeaderPdf";
import css from "./ViewVisit.Style";
import FooterPdf from "./FooterPdf";
import check from "./BlackCheckmark.png";
import { tokenLoader } from "../../../UI/auth";
import Spinner from "../../../UI/Spinner";
import { ICutOffVisit } from "../../../UI/interface";
import { showErrorAlert } from "../../../UI/alert";

const ViewVisit: React.FC = () => {
  const { CutoffDateFrom, CutoffDateTo, ReimbursementDateDescription } =
    useParams();
  const [tableData, setTableData] = useState<ICutOffVisit[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visitPdf, setVisitPdf] = useState<File | null>(null);
  const navigate = useNavigate()

  useEffect(() => {
    const animationFrameId = requestAnimationFrame(() => {
      setIsLoading(false);
    });

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/report/visitExpenseList/${CutoffDateFrom}/${CutoffDateTo}/${ReimbursementDateDescription}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await response.json();
      setTableData(jsonResponse);
      setIsLoading(false);

      if (!jsonResponse || jsonResponse.length === 0) {
        showErrorAlert("No data found for the selected criteria.");
        navigate(-1)
      }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CutoffDateFrom, CutoffDateTo, ReimbursementDateDescription]);

  const generatePDF = (tableData: any) => {
    return (
      <Document>
        <Page size={"LEGAL"} orientation={"landscape"}>
          <View>
            <View fixed>
              <HeaderPdf tableData={tableData} />
            </View>
            {tableData.map((cutOffDate: any, index: any) => (
              <View key={index} style={css.dataContainer}>
                <Text style={[css.commonText, css.midWidth]}>
                  {moment(cutOffDate.VisitDate, "YYYY/MM/DD")
                    .tz("UTC")
                    .format("MM/DD/YYYY")}
                </Text>
                <Text style={[css.commonText, css.midWidth]}>
                  {moment((cutOffDate.VisitTime).trim().padStart(6, "0"), "HHmmss").format("hh:mm:ss A")}
                </Text>
                <Text style={[css.commonText, css.narrowWidth]}>
                  {cutOffDate.UnlistedSource ? (
                    <Image src={check} style={css.iconStyle} />
                  ) : (
                    " "
                  )}
                </Text>
                <Text style={[css.commonText, css.wideWidth]}>
                  {cutOffDate.EstablishmentName}
                </Text>
                <Text style={[css.commonText, css.wideWidth]}>
                  {cutOffDate.activity}
                </Text>
                <Text style={[css.commonText, css.midWidth]}>
                  {cutOffDate.Mileage.toFixed(2)}
                </Text>
                <Text style={[css.commonText, css.midWidth]}>
                  {cutOffDate.Marketing_Expense.toFixed(2)}
                </Text>
                <Text style={[css.commonText, css.wideWidth]}>
                  {cutOffDate.ModifyDate && cutOffDate.ModifyTime
                    ? `${moment(cutOffDate.ModifyDate, "YYYY/MM/DD")
                        .tz("UTC")
                        .format("MM/DD/YYYY")}, ${moment(
                        cutOffDate.ModifyTime,
                        "HHmmss"
                      ).format("hh:mm:ss A")}`
                    : " "}
                </Text>
              </View>
            ))}
          </View>
          <View>
            <FooterPdf tableData={tableData} />
          </View>
        </Page>
      </Document>
    );
  };

  useEffect(() => {
    const generateAndSetPdfFile = async () => {
      const pdfComponent = generatePDF(tableData);
      const pdfBlob = await pdf(pdfComponent).toBlob();
      const pdfFile = new File([pdfBlob], "mer.pdf", {
        type: "application/pdf",
      });
      setVisitPdf(pdfFile);
    };
    generateAndSetPdfFile();
  }, [tableData]);

  if (!tableData || tableData.length === 0) {
    return <Spinner />;
  }

  return (
    <div>
      {visitPdf && (
        <iframe
          src={URL.createObjectURL(visitPdf)}
          style={{
            display: "flex",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "0",
            top: "0",
          }}
          title="PDF Viewer"
        />
      )}
      {isLoading && <Spinner />}
    </div>
  );
};

export default ViewVisit;
