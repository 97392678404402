import React from "react";
import { Form } from "react-router-dom";

import BackButton from "../../UI/BackButton";

const COP: React.FC = () => {
  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Performance - COP</h1>
          </div>
        </div>
        <Form>
          <div className="mt-6 p-2 border-2 rounded-md">
            <ul className="text-center">
              <li className="mt-10">
                <label> Business Opportunity</label>
              </li>
              <li>
                <label htmlFor="create" className="ml-[5px]">
                  Create
                </label>
                <input
                  type="text"
                  id="create"
                  className="w-[80px] ml-[30px] h-8 mt-10 px-2 rounded-md text-black text-right"
                />
              </li>
              <li>
                <label htmlFor="own" className="ml-[17px]">
                  Own
                </label>
                <input
                  type="text"
                  id="own"
                  className="w-[80px] ml-[30px] h-8 mt-6 px-2 rounded-md text-black text-right"
                />
              </li>
              <li>
                <label htmlFor="protect">Protect</label>
                <input
                  type="text"
                  id="protect"
                  className="w-[80px] ml-[30px] h-8 mt-6 px-2 rounded-md text-black text-right"
                />
              </li>
              <li>
                <label htmlFor="total" className="ml-[16px]">
                  Total
                </label>
                <input
                  type="text"
                  id="total"
                  className="w-[80px] ml-[30px] h-8 mt-6 mb-10 px-2 rounded-md text-black text-right"
                />
              </li>
            </ul>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default COP;
