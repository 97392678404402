import React, { FormEvent, useRef } from "react";
import { Form, Link, useNavigate } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";

const SPCRankingForm: React.FC = () => {
  const rankingInputRef = useRef<HTMLInputElement>(null);
  const referralSourceInputRef = useRef<HTMLInputElement>(null);
  const QTDReferralsInputRef = useRef<HTMLInputElement>(null);
  const goalInputRef = useRef<HTMLInputElement>(null);
  const targetInputRef = useRef<HTMLInputElement>(null);
  const targetAccountInputRef = useRef<HTMLTextAreaElement>(null);
  const identifiedInputRef = useRef<HTMLTextAreaElement>(null);
  const createdByInputRef = useRef<HTMLTextAreaElement>(null);
  const month1InputRef = useRef<HTMLTextAreaElement>(null);
  const month2InputRef = useRef<HTMLTextAreaElement>(null);
  const month3InputRef = useRef<HTMLTextAreaElement>(null);
  const isDeltedInputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const strategicPlanSubmitHandler = (event: FormEvent) => {
    event.preventDefault();

    const enteredRanking = rankingInputRef.current!.value;
    const enteredReferralSource = referralSourceInputRef.current!.value;
    const enteredQTDReferrals = QTDReferralsInputRef.current!.value;
    const enteredGoal = goalInputRef.current!.value;
    const enteredTarget = targetInputRef.current!.value;
    const enteredTargetAccount = targetAccountInputRef.current!.value;
    const enteredIdentified = identifiedInputRef.current!.value;
    const enteredCreatedBy = createdByInputRef.current!.value;
    const enteredMonth1 = month1InputRef.current!.value;
    const enteredMonth2 = month2InputRef.current!.value;
    const enteredMonth3 = month3InputRef.current!.value;
    const enteredIsDeleted = isDeltedInputRef.current!.checked;

    console.log(enteredRanking);
    console.log(enteredReferralSource);
    console.log(enteredQTDReferrals);
    console.log(enteredGoal);
    console.log(enteredTarget);
    console.log(enteredTargetAccount);
    console.log(enteredIdentified);
    console.log(enteredCreatedBy);
    console.log(enteredMonth1);
    console.log(enteredMonth2);
    console.log(enteredMonth3);
    console.log(enteredIsDeleted);

    navigate("/StrategicPlanningList/Ranking");
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px] ml-5 md:ml-0 ">
              Strategic Plan Ranking
            </h1>
          </div>
        </div>
        <Form onSubmit={strategicPlanSubmitHandler}>
          <div className="mt-8 p-2 border-2 rounded-md">
            <ul>
              <li className="mt-4">
                <label>Ranking:</label>
                <input
                  type="text"
                  className="w-[80px] ml-3 md:w-[120px] md:ml-[62px] h-8 px-2 rounded-md text-black text-center"
                  ref={rankingInputRef}
                />
                <label className="ml-[30px] md:ml-[230px] lg:ml-[80px]">
                  COP Code:
                </label>
                <label className="ml-10">CREATE</label>
              </li>
              <li className="mt-4">
                <label>Referral Source</label>
                <input
                  type="text"
                  className="w-[290px] md:w-[480px] md:ml-4 lg:w-[320px] px-2 h-8 mt-2 rounded-md text-black"
                  ref={referralSourceInputRef}
                />
                <a href="/">
                  <button className="text-gray-600 ml-3 md:ml-6" disabled>
                    Select
                  </button>
                </a>
              </li>
              <li>
                <ul className="flex mt-4 w-full">
                  <li className="w-[120px] md:w-[227px] lg:w-[169px]">
                    <label htmlFor="">QTD Referrals</label>
                    <input
                      type="text"
                      className="w-[120px] md:w-[227px] lg:w-[169px] h-8 mt-2 px-2 rounded-md text-black"
                      ref={QTDReferralsInputRef}
                    />
                  </li>
                  <li className="w-[120px] ml-3 md:w-[227px] lg:w-[169px]">
                    <label htmlFor="">Goal</label>
                    <input
                      type="text"
                      className="w-[120px] md:w-[227px] lg:w-[169px] h-8 mt-2 px-2 rounded-md text-black"
                      ref={goalInputRef}
                    />
                  </li>
                  <li className="w-[80px] ml-3 md:w-[227px] lg:w-[169px]">
                    <label htmlFor="">Target #</label>
                    <input
                      type="text"
                      className="w-[90px] md:w-[227px] lg:w-[169px] h-8 mt-2 px-2 rounded-md text-black"
                      ref={targetInputRef}
                    />
                  </li>
                </ul>
              </li>
              <li className="mt-4">
                <label htmlFor="">Why is this a target account?</label>
                <textarea
                  rows={2}
                  className="w-full mt-2 px-2 rounded-md text-black"
                  ref={targetAccountInputRef}
                ></textarea>
              </li>
              <li className="mt-4">
                <label htmlFor="">Needs Identified</label>
                <textarea
                  rows={2}
                  className="w-full mt-2 px-2 rounded-md text-black"
                  ref={identifiedInputRef}
                ></textarea>
              </li>
              <li className="mt-4">
                <label htmlFor="">Value Created By CL</label>
                <textarea
                  rows={2}
                  className="w-full mt-2 px-2 rounded-md text-black"
                  ref={createdByInputRef}
                ></textarea>
              </li>
              <li className="mt-4">
                <label htmlFor="">Next Steps - Month 1</label>
                <textarea
                  rows={2}
                  className="w-full mt-2 px-2 rounded-md text-black"
                  ref={month1InputRef}
                ></textarea>
              </li>
              <li className="mt-4">
                <label htmlFor="">Next Steps - Month 2</label>
                <textarea
                  rows={2}
                  className="w-full mt-2 px-2 rounded-md text-black"
                  ref={month2InputRef}
                ></textarea>
              </li>
              <li className="mt-4">
                <label htmlFor="">Next Steps - Month 3</label>
                <textarea
                  rows={2}
                  className="w-full mt-2 px-2 rounded-md text-black"
                  ref={month3InputRef}
                ></textarea>
              </li>
              <li className="mt-4 mb-4">
                <input type="checkbox" ref={isDeltedInputRef} />
                <label htmlFor="" className="ml-2">
                  IsDeleted?
                </label>
              </li>
            </ul>
          </div>
          <div className="mt-4">
            <Button type="submit" className="w-[90px]">
              Save
            </Button>
            <Link to=".." relative="path">
              <Button className="w-[90px] ml-3">Close</Button>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SPCRankingForm;
