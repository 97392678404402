import ReceiptDetails from "../../components/Meetings/ReceiptDetails";

const ReceiptDetailsPage = () => {
    return(
        <div>
            <ReceiptDetails />
        </div>
    )
}

export default ReceiptDetailsPage