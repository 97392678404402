import React, { useEffect, useState } from "react";
import { Form, Link } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";
import { tokenLoader } from "../../UI/auth";
import Spinner from "../../UI/Spinner";
import { IFacilityList } from "../../UI/interface";


const MeetingsPreviousList = () => {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [facilityList, setFacilityList] = useState<IFacilityList[]>([]);
  const [countVisits, setCountVisits] = useState<number>(0);
  const [mileage, setMileage] = useState<number | null>(null);
  const [totalReimbursement, setTotalReimbursement] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  const selectDateHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const clearHandler = () => {
    setSelectedDate("");
    setFacilityList([]);
  };

  const fetchFacilityList = async () => {
    setIsLoading(true);
    const token = tokenLoader();
    const responseFacilityList = await fetch(
      `${process.env.REACT_APP_API_URL}/meetings/visits/${selectedDate}`,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
    const jsonResponseFacility = await responseFacilityList.json();
    setFacilityList(jsonResponseFacility);
    setIsLoading(false);
  };

  //Mileage
  useEffect(() => {
    if (facilityList) {
      const sumOfMileage = facilityList.reduce(
        (acc, facilityList) => acc + facilityList.Mileage,
        0
      );
      setMileage(sumOfMileage);
    }
  }, [facilityList]);

  // Total Visits
  useEffect(() => {
    if (facilityList) {
      const filteredVisits = facilityList.length;
      setCountVisits(filteredVisits);
    }
  }, [facilityList]);

  // Total Reimbursement
  useEffect(() => {
    if (facilityList) {
      const sumOfReimbursement = facilityList.reduce(
        (acc, facilityList) => acc + facilityList.MileageReimbursement,
        0
      );
      setTotalReimbursement(sumOfReimbursement);
    }
  }, [facilityList]);

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Meetings - Previous</h1>
          </div>
        </div>
        <Form className="mt-8 p-2 border-2 rounded-md">
          <div>
            <ul className="flex justify-end mr-2 mt-2">
              <li className="mr-6">Legend:</li>
              <li className="mr-4">LS</li>
              <li>US</li>
            </ul>
          </div>
          <div className="flex mt-2 justify-start">
            <div>
              <label htmlFor="selectDate">Select Date</label>
              <input
                id="selectDate"
                type="date"
                className=" w-[115px] ml-2 md:w-[160px] h-8 rounded-md text-center text-black cursor-pointer"
                onChange={selectDateHandler}
                value={selectedDate}
              />
            </div>
            <ul className="flex items-center">
              <li>
                <Button
                  onClick={fetchFacilityList}
                  className="w-[65px] ml-1 pl-0 pr-0 md:w-[96px] md:ml-4"
                >
                  Search
                </Button>
              </li>
              <li>
                <Button
                  className="w-[65px] ml-1 pl-0 pr-0 md:w-[96px] md:ml-4"
                  onClick={clearHandler}
                >
                  Clear
                </Button>
              </li>
            </ul>
          </div>
          <div className="h-[430px] mt-4 mb-4 bg-white overflow-scroll">
            <table className="w-full">
              <thead>
                <tr className="bg-blue-900 text-left h-8 top-0 sticky">
                  <th className="font-normal pl-1">Facility Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="text-black">
                {facilityList.map((facility) => {
                  return (
                    <tr
                      key={facility.VisitCTR}
                      className="odd:bg-white even:bg-slate-200 border-b-2 h-8"
                    >
                      <td className="pl-3">{`${facility.EstablishmentName}`}</td>
                      <td className="text-center w-[100px] md:w-[150px]">
                        <Link to={`${facility.VisitCTR}`}>
                          <Button>More</Button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Form>
        {isLoading && <Spinner />}
        <div>
          <ul>
            <li className="flex justify-between items-center mt-5">
              <div>
                <input
                  id="recalculated"
                  type="checkbox"
                  className="cursor-pointer"
                  disabled
                />{" "}
                <label htmlFor="recalculated">Recalculated</label>
              </div>
              <div>
                <label htmlFor="totalMileage" className="mr-2">
                  Total Mileage
                </label>
                <input
                  id="totalMileage"
                  type="number"
                  className="w-[65px] md:w-[120px] h-8 px-2 rounded-md text-black text-left"
                  value={mileage?.toFixed(2) || ""}
                  readOnly
                />
              </div>
            </li>
            <li className="mt-4 mb-4 text-right">
              <label htmlFor="totalVisits" className="mr-2">
                Total Visits
              </label>
              <input
                id="totalVisits"
                type="text"
                className="w-[65px] md:w-[120px] h-8 px-2 rounded-md text-black text-left"
                value={countVisits || 0}
                readOnly
              />
            </li>
            <li className="mt-4 mb-4 text-right">
              <label htmlFor="totalReimbursement" className="mr-2">
                Total Reimbursement
              </label>
              <input
                id="totalReimbursement"
                type="text"
                className="w-[65px] md:w-[120px] h-8 px-2 rounded-md text-black text-left"
                value={totalReimbursement?.toFixed(2) || ""}
                readOnly
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MeetingsPreviousList;
