import React from "react";
import MeetingsPrintOut from "../../components/Meetings/MeetingsPrintOutForm";

const MeetingPringOutPage = () => {
  return (
    <div>
      <MeetingsPrintOut />
    </div>
  );
};

export default MeetingPringOutPage;
