import React from "react";

import SPCRankingForm from "../../components/Top20SPC/SPCRankingForm";

const SPCRankingAddPage = () => {
  return (
    <div>
      <SPCRankingForm />
    </div>
  );
};

export default SPCRankingAddPage;
