import React from "react";
import { Link } from "react-router-dom";

import BackButton from "../../UI/BackButton";

const PatientSurvey: React.FC = () => {
  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div className="py-6 px-4 text-center text-white">
          <div>
            <BackButton />
            <div className="text-center">
              <h1 className="text-[30px] mt-[-40px]">Patient Survey</h1>
            </div>
          </div>
          <div className="mt-6 p-2 border-2 rounded-md">
            <div className="mt-8">
              <p>
                We are under live beta testing for these surveys. Please report
                all bugs and suggestions.
              </p>
            </div>
            <Link to="/PatientSurvey/PreAdmission">
              <button className="w-full py-2 px-6 mt-[80px] rounded-md bg-[#13457a] ">
                {" "}
                Pre-Admission
              </button>
            </Link>
            <Link to="">
              <button className="w-full py-2 px-6 mt-6 rounded-md bg-[#13457a]">
                Post Admission
              </button>
            </Link>
            <Link to="">
              <button className="w-full py-2 px-6 mt-6 mb-[320px] rounded-md bg-[#13457a]">
                Pre/Post Discharged
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientSurvey;
