import React from 'react';
import ContactForm from '../../../components/ExternalSources/ReferralSources/ContactForm';

const ContactFormPage = () => {
    return (
        <div>
            <ContactForm />
        </div>
    )
}

export default ContactFormPage;