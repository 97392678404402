//External Sources


export interface viewHistoryList {
  id: string;
  date: string;
  remarks: string;
}

export const ViewHistoryListDummy: viewHistoryList[] = [
  {
    id: "history1",
    date: "12-31-2020",
    remarks: "keep it up",
  },
  {
    id: "history2",
    date: "12-31-2020",
    remarks: "keep it up",
  },
];

export interface jointCallList {
  id: string;
  BDM: string;
  date: string;
  purpose: string;
  completed: boolean;
}

export const jointCallListDummy: jointCallList[] = [
  {
    id: "list1",
    BDM: "Montano, Genesis",
    date: "07/22/2022",
    purpose: "",
    completed: true,
  },
  {
    id: "list2",
    BDM: "Montano, Genesis",
    date: "07/22/2022",
    purpose: "working it out",
    completed: false,
  },
];


//Top 20 SPC
export interface strategicPlanningList {
  id: string;
  titleName: string;
  startDate: string;
  endDate: string;
}

export const strategicPlanningListDummy: strategicPlanningList[] = [
  {
    id: "SPL1",
    titleName: "Q42020",
    startDate: "07/20/2022",
    endDate: "07/20/2022",
  },
  {
    id: "SPL2",
    titleName: "Q42021",
    startDate: "07/20/2022",
    endDate: "07/20/2022",
  },
];

export interface rankingList {
  id: string;
  rank: number;
  facilityname: string;
  COPCode: string;
  type: string;
}

export const rankingListDummy: rankingList[] = [
  {
    id: "rank1",
    rank: 20,
    facilityname: "John Muir Hospital-Concord",
    COPCode: "CREATE",
    type: "Hospital",
  },
  {
    id: "rank2",
    rank: 19,
    facilityname: "John Muir Hospital-Concord",
    COPCode: "CREATE",
    type: "Hospital",
  },
];

//More
export interface MoreInsuranceList {
  id: string;
  insuranceName: string;
  forApproval: boolean;
}

export const insuranceList: MoreInsuranceList[] = [
  {
    id: "IL1",
    insuranceName: "CareCemtrox",
    forApproval: true,
  },
  {
    id: "IL2",
    insuranceName: "Chinese Community Health Plan",
    forApproval: false,
  },
];
