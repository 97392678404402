import React, { useRef } from "react";
import { Form } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";

const MarketersPresentation: React.FC = () => {
  const presentationInputRef = useRef<HTMLSelectElement>(null);

  const playSubmitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const enteredPresentation = presentationInputRef.current!.value;
    console.log(enteredPresentation);
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Presentation</h1>
          </div>
        </div>
        <Form onSubmit={playSubmitHandler}>
          <div className="h-[600px] mt-6 p-2 border-2 rounded-md border-white">
            <ul className="my-3">
              <li>
                <select
                  name=""
                  id=""
                  className="w-[354px] md:w-[706px] lg:w-[532px] h-10 px-2 rounded-md text-black"
                  ref={presentationInputRef}
                >
                  <option value="presentation"></option>
                  <option value="New Presentation">New Presentation</option>
                </select>
              </li>
              <li className="text-right mt-3">
                <Button type="submit" className="w-[100px]">
                  Play
                </Button>
              </li>
            </ul>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MarketersPresentation;
