import React, { useState, useEffect, createContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Back from "../../../image/back.png";
import { tokenLoader } from "../../../UI/auth";
import MainFacility from "./MainFacility";
import AddressFacility from "./AddressFacility";
import OthersFacility from "./OthersFacility";
import { IFormData } from "../../../UI/interface";
import {
  alertLoading,
  closeAlert,
  showErrorAlert,
  successAlert,
  warningAlert,
} from "../../../UI/alert";

export const FormContext = createContext<any>({});

const FacilityForm = () => {
  const { RFE_ID } = useParams();
  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  const [post, setPost] = useState<IFormData[]>([]);
  const navigate = useNavigate();

  const [formData, setFormData] = useState<IFormData>({
    RFE_ID: "",
    FULL_NAME: "",
    RFE_FACILITY_NAME: "",
    RFE_SITE_TYPE: "",
    RFE_SITE_TYPE_CODE: "",
    RFE_NOTE: "",
    RFE_SHORT_NAME: "",
    RFE_BO_CODE: "",
    RFE_RATING: "",
    RFE_RATING_CODE: "",
    RFE_IsActive: true,
    RFE_ADDRESS: "",
    RFE_CITY: "",
    RFE_STATE_CODE: "",
    RFE_ZIP_CODE: "",
    RFE_PHONE_1: "",
    RFE_PHONE_2: "",
    RFE_PHONE_3: "",
    RFE_FAX: "",
    GEO_LATITUDE: "",
    GEO_LONGITUDE: "",
    RFE_EMAIL: "",
    RFE_WEBSITE: "",
  });

  // Fetch facility details data based on RFE_ID
  useEffect(() => {
    setIsLoading(true);
    const token = tokenLoader();
    const formDetails = async () => {
      const detailsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/externalsources/details/${RFE_ID}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await detailsResponse.json();
      setFormData((prevFormData) => ({
        ...prevFormData,
        RFE_ID: jsonResponse[0].RFE_ID,
        FULL_NAME: jsonResponse[0].FULL_NAME,
        RFE_FACILITY_NAME: jsonResponse[0].RFE_FACILITY_NAME,
        RFE_SITE_TYPE: jsonResponse[0].RFE_SITE_TYPE,
        RFE_SITE_TYPE_CODE: jsonResponse[0].RFE_SITE_TYPE_CODE,
        RFE_NOTE: jsonResponse[0].RFE_NOTE,
        RFE_SHORT_NAME: jsonResponse[0].RFE_SHORT_NAME.trim(),
        RFE_BO_CODE: jsonResponse[0].RFE_BO_CODE.trim(),
        RFE_RATING: jsonResponse[0].RFE_RATING,
        RFE_IsActive: jsonResponse[0].RFE_IsActive,
        RFE_ADDRESS: jsonResponse[0].RFE_ADDRESS.trim(),
        RFE_CITY: jsonResponse[0].RFE_CITY,
        RFE_STATE_CODE: jsonResponse[0].RFE_STATE_CODE,
        RFE_ZIP_CODE: jsonResponse[0].RFE_ZIP_CODE,
        RFE_PHONE_1: jsonResponse[0].RFE_PHONE_1.trim(),
        RFE_PHONE_2: jsonResponse[0].RFE_PHONE_2.trim(),
        RFE_PHONE_3: jsonResponse[0].RFE_PHONE_3.trim(),
        RFE_FAX: jsonResponse[0].RFE_FAX.trim(),
        GEO_LATITUDE: jsonResponse[0].GEO_LATITUDE,
        GEO_LONGITUDE: jsonResponse[0].GEO_LONGITUDE,
        RFE_EMAIL: jsonResponse[0].RFE_EMAIL.trim(),
        RFE_WEBSITE: jsonResponse[0].RFE_WEBSITE.trim(),
      }));
      setIsLoading(false);
      setIsUpdateMode(true);
    };
    formDetails();
  }, [RFE_ID]);

  //Add new facility
  useEffect(() => {
    if (!isUpdateMode && !RFE_ID) {
      const addFacility = async () => {
        const token = tokenLoader();
        const addResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/externalSources/checkMaxId`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        const jsonResponse = await addResponse.json();
        setFormData((prevFormData) => ({
          ...prevFormData,
          RFE_ID: jsonResponse.RFE_ID,
          FULL_NAME: jsonResponse.FULL_NAME,
        }));
        setIsUpdateMode(false);
        setIsLoading(false);
      };
      addFacility();
    }
  }, [isUpdateMode, RFE_ID]);

  // Get Geolocation

  const getGeolocation = async () => {
    const { RFE_ADDRESS, RFE_CITY, RFE_STATE_CODE, RFE_ZIP_CODE } = formData;
    const addressString = `${RFE_ADDRESS}, ${RFE_CITY}, ${RFE_STATE_CODE} ${RFE_ZIP_CODE}`;

    try {
      setIsLoading(true);
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          addressString
        )}&key=${process.env.REACT_APP_GOOGLE_API}`
      );
      const geocodingData = await response.json();

      const { results } = geocodingData;

      setIsLoading(false);
      if (results && results.length > 0) {
        const { geometry } = results[0];
        const { location } = geometry;
        return location;
      } else {
        throw new Error("Geocoding response is empty or invalid.");
      }
    } catch (error) {
      throw new Error("Error occurred during geocoding.");
    }
  };

  //Handlers
  const handleChange = (event: React.ChangeEvent) => {
    const { name, value, type, checked }: any = event.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const goToNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const goToPreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const coordinates = await getGeolocation();

      if (isUpdateMode && RFE_ID) {
        const updatedFacility = {
          RFE_FACILITY_NAME: formData.RFE_FACILITY_NAME,
          RFE_TYPE_OF_SITE: formData.RFE_SITE_TYPE_CODE,
          RFE_NOTE: formData.RFE_NOTE,
          RFE_SHORT_NAME: formData.RFE_SHORT_NAME,
          RFE_RATING: formData.RFE_RATING,
          RFE_IsActive: formData.RFE_IsActive,
          RFE_ADDRESS: formData.RFE_ADDRESS,
          RFE_CITY: formData.RFE_CITY,
          RFE_STATE_CODE: formData.RFE_STATE_CODE,
          RFE_ZIP_CODE: formData.RFE_ZIP_CODE,
          RFE_PHONE_1: formData.RFE_PHONE_1,
          RFE_PHONE_2: formData.RFE_PHONE_2,
          RFE_PHONE_3: formData.RFE_PHONE_3,
          RFE_FAX: formData.RFE_FAX,
          RFE_EMAIL: formData.RFE_EMAIL,
          RFE_WEBSITE: formData.RFE_WEBSITE,
        };

        alertLoading("Submitting", "Please wait ...");
        const token = tokenLoader();
        const updateFacility = await fetch(
          `${process.env.REACT_APP_API_URL}/externalsources/facility/update/${RFE_ID}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(updatedFacility),
          }
        );
        closeAlert();
        successAlert("Update Success");
        navigate(-1);

        const update = await updateFacility.json();
        setPost([...post, update]);
        navigate(-1);
      }

      if (!isUpdateMode && !RFE_ID) {
        const addFacility = {
          RFE_ID: formData.RFE_ID,
          RFE_FACILITY_NAME: formData.RFE_FACILITY_NAME,
          RFE_TYPE_OF_SITE: formData.RFE_SITE_TYPE_CODE,
          RFE_NOTE: formData.RFE_NOTE,
          RFE_SHORT_NAME: formData.RFE_SHORT_NAME,
          RFE_RATING: formData.RFE_RATING,
          RFE_IsActive: formData.RFE_IsActive,
          RFE_ADDRESS: formData.RFE_ADDRESS,
          RFE_CITY: formData.RFE_CITY,
          RFE_STATE_CODE: formData.RFE_STATE_CODE,
          RFE_ZIP_CODE: formData.RFE_ZIP_CODE,
          RFE_PHONE_1: formData.RFE_PHONE_1,
          RFE_PHONE_2: formData.RFE_PHONE_2,
          RFE_PHONE_3: formData.RFE_PHONE_3,
          RFE_FAX: formData.RFE_FAX,
          GEO_LATITUDE: coordinates.lat,
          GEO_LONGITUDE: coordinates.lng,
          RFE_EMAIL: formData.RFE_EMAIL,
          RFE_WEBSITE: formData.RFE_WEBSITE,
        };

        alertLoading("Submitting", "Please wait ...");
        const token = tokenLoader();
        const createFacility = await fetch(
          `${process.env.REACT_APP_API_URL}/externalSources/createFacility`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(addFacility),
          }
        );

        closeAlert();
        successAlert(`${formData.RFE_FACILITY_NAME} was Created`);
        navigate(-1);

        const update = await createFacility.json();
        setPost([...post, update]);
      }
    } catch (error: any) {
      // warningAlert(
      //   "facility not created. Please contact your administrator. Thank you!",
      //   "Error"
      // );
      showErrorAlert(`${error.message}`)
      return error;
    }
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div>
          <div>
            <Link to="/ExternalReferralSourcesList">
              <img
                src={Back}
                className="h-10 md:h-10 rounded-full"
                alt="Back Button"
              />
            </Link>
          </div>
          <div className="text-[20px] mt-[-40px]">
            <ul className="flex items-center h-10 ml-[45px]">
              <li className="mx-2">
                <button
                  type="button"
                  className={step === 1 ? "underline" : ""}
                  onClick={() => setStep(1)}
                >
                  MAIN
                </button>
              </li>
              <li className="mx-2">
                <button
                  type="button"
                  className={step === 2 ? "underline" : ""}
                  onClick={() => setStep(2)}
                >
                  ADDRESS
                </button>
              </li>
              <li className="mx-2">
                <button
                  type="button"
                  className={step === 3 ? "underline" : ""}
                  onClick={() => setStep(3)}
                >
                  OTHERS
                </button>
              </li>
            </ul>
          </div>
        </div>
        <FormContext.Provider
          value={{
            RFE_ID,
            step,
            formData,
            setFormData,
            getGeolocation,
            handleChange,
            handleSubmit,
            goToNextStep,
            goToPreviousStep,
            isLoading,
          }}
        >
          {step === 1 && <MainFacility />}
          {step === 2 && <AddressFacility />}
          {step === 3 && <OthersFacility />}
        </FormContext.Provider>
      </div>
    </div>
  );
};

export default FacilityForm;
