import React from "react";
import ExternalReferralSourcesList from "../../components/ExternalSources/EReferralSourcesList";


const ReferralSourceListPage = () => {
  return (
    <div>
      <ExternalReferralSourcesList/>
    </div>
  );
};

export default ReferralSourceListPage;
