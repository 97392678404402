import React from "react";
import MeetingsPreviousList from "../../components/Meetings/MeetingsPreviousList";

const MeetingPreviousListPage = () => {
  return (
    <div>
      <MeetingsPreviousList />
    </div>
  );
};

export default MeetingPreviousListPage;
