import React from "react";
import { Link } from "react-router-dom";

interface ModalType {
  isVisible: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalType> = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center "
      onClick={() => onClose()}
    >
      <div className="bg-blue-300 w-[300px] rounded-md ">
        <div className="py-6 px-4 text-center text-white">
          <Link to="/meetingVisit">
            <button className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]">
              Meetings - Visit
            </button>
          </Link>
          <Link to="/meetingCurrent">
            <button className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]">
              Meetings - Current
            </button>
          </Link>
          <Link to="/meetingPrevious">
            <button className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]">
              Meetings - Previous
            </button>
          </Link>
          <Link to="/meetingPrintOut">
            <button className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]">
              Meetings - Print Out
            </button>
          </Link>
          <button
            className=" w-full py-2 px-6 mt-10 mb-4 rounded-md bg-[#13547a]"
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
