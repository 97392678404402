import React from "react";
import CovidQuestionare from "../../components/Presentation/CovidQuestionaire";

const CovidQuestionarePage = () => {
  return (
    <div>
      <CovidQuestionare />
    </div>
  );
};

export default CovidQuestionarePage;
