import React, { useState, useEffect } from "react";
import { Form, Link, useNavigate } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";
import { tokenLoader } from "../../UI/auth";
import Spinner from "../../UI/Spinner";
import { showErrorAlert, comingSoonAlert } from "../../UI/alert";
import { IReferralSourceList } from "../../UI/interface";


const ExternalReferralSourcesList = () => {
  const [inputSearch, setInputSearch] = useState<string>("");
  const [referralList, setReferralList] = useState<IReferralSourceList[]>([]);
  const [selectedFacility, setSelectedFacility] =
    useState<IReferralSourceList | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const getReferralList = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/externalsources/list`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await response.json();
      setReferralList(jsonResponse);
      setIsLoading(false);
    };
    getReferralList();
  }, []);

  const inputFacilityHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputSearch(event.target.value);
  };

  const searchHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const filteredData = referralList.filter((item) =>
      item.RFE_FACILITY_NAME.toLowerCase().includes(
        inputSearch.toLocaleLowerCase()
      )
    );
    setReferralList(filteredData);
    setIsLoading(true);
  };

  const resetHandler = () => {
    setInputSearch("");

    const fetchReferralList = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/externalsources/list`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await response.json();
      setReferralList(jsonResponse);
      setIsLoading(false);
    };
    fetchReferralList();
  };

  const selectedFacilityHandler = (facility: IReferralSourceList) => {
    setSelectedFacility(facility);
  };

  const facilityDetailsHandler = () => {
    if (!selectedFacility) {
      showErrorAlert("Please select a facility to view the details.");
    } else {
      navigate(`${selectedFacility.RFE_ID}`);
    }
  };

  const contactFacilityHandler = () => {
    if (!selectedFacility) {
      showErrorAlert("Please select a facility to view and contact.");
    } else {
      navigate(`ContactList/${selectedFacility.RFE_ID}`);
    }
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div className="relative">
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Referral Source List</h1>
          </div>
        </div>

        <Form className="mt-6 p-2 border-2 rounded-md" onSubmit={searchHandler}>
          <div className="flex justify-start mt-4">
            <div>
              <input
                type="text"
                className="w-full md:w-[400px] lg:w-[325px] px-2 h-8 rounded-md text-black"
                onChange={inputFacilityHandler}
                value={inputSearch}
              />
            </div>
            <ul className="flex items-center">
              <li>
                <Button type="submit" className="w-[92px] ml-3">
                  Search
                </Button>
              </li>
              <li>
                <Button className=" w-[92px] ml-2" onClick={resetHandler}>
                  Clear
                </Button>
              </li>
            </ul>
          </div>

          <div className="h-[570px] border-2 mt-4 bg-white overflow-scroll">
            <table className="w-full  mb-4">
              <thead className="bg-blue-900 text-left h-8 sticky top-0">
                <tr>
                  <th className="font-normal pl-1 w-[260px] md:w-[580px] lg:w-[430px]">
                    Facility Name
                  </th>
                  <th className="font-normal pl-1">BO Code</th>
                </tr>
              </thead>
              <tbody className="text-black overflow-y-auto">
                {referralList.map((sourceList) => {
                  return (
                    <tr
                      key={sourceList.RFE_ID}
                      className="odd:bg-white even:bg-slate-200 h-8 border-b-2 hover:bg-blue-400 active:bg-blue-300 focus:ring focus:ring-blue-400"
                      onClick={() => selectedFacilityHandler(sourceList)}
                    >
                      <td className="pl-3">{`${sourceList.RFE_FACILITY_NAME}`}</td>
                      <td className="pl-3">{`${sourceList.RFE_BO_CODE}`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div>
            <ul className="flex flex-wrap justify-around mb-4">
              <li>
                <Button
                  className="w-[140px] lg:w-[126px] mt-2"
                  onClick={facilityDetailsHandler}
                >
                  Details
                </Button>
              </li>
              <li>
                <Button
                  className="w-[140px] lg:w-[126px] mt-2"
                  onClick={contactFacilityHandler}
                >
                  Contact
                </Button>
              </li>
              <li>
                <Link to={`/ExternalReferralSourcesList/facility`}>
                  <Button className="w-[140px] lg:w-[126px] mt-2">Add</Button>
                </Link>
              </li>
              <li>
                <Button
                  className="w-[140px] lg:w-[133px] mt-2"
                  onClick={comingSoonAlert}
                >
                  View History
                </Button>
              </li>
            </ul>
          </div>
        </Form>
        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

export default ExternalReferralSourcesList;
