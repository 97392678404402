import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

//Modals
import PerformanceModal from "../modals/PerformanceModal";
import ExternalSourcesModal from "../modals/ExternalSourcesModal";
import MeetingsModal from "../modals/MeetingsModal";
import PresentationModal from "../modals/PresentationModal";
import SPCModal from "../modals/SPCModal";
import MoreModal from "../modals/MoreModal";

//Images
import Back from "../image/back.png";
import Performance from "../image/performance.png";
import ExternalSources from "../image/external-sources.png";
import Meetings from "../image/meetings.png";
import Presentation from "../image/Presentation.png";
import SPC from "../image/SPC.png";
import More from "../image/more.png";

import { tokenLoader } from "../UI/auth";
import { successAlert, confirmationAlert } from "../UI/alert";

const Home: React.FC = () => {
  // Check if Login and if not redirect
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  //Performance Modal
  const [showModalPer, setShowModalPer] = useState(false);
  const openModalHandlerPer = () => setShowModalPer(true);
  const closeModalHandlerPer = () => setShowModalPer(false);

  //External Sources Modal
  const [showModalES, setShowModalES] = useState(false);
  const openModalHandlerES = () => setShowModalES(true);
  const closeModalHandlerES = () => setShowModalES(false);

  //Meetings Modal
  const [showModalM, setShowModalM] = useState(false);
  const openModalHandlerM = () => setShowModalM(true);
  const closeModalHandlerM = () => setShowModalM(false);

  //Presentation Modal
  const [showModalPre, setShowModalPre] = useState(false);
  const openModalHandlerPre = () => setShowModalPre(true);
  const closeModalHandlerPre = () => setShowModalPre(false);

  //SPC Modal
  const [showModalSPC, setShowModalSPC] = useState(false);
  const openModalHandlerSPC = () => setShowModalSPC(true);
  const closeModalHandlerSPC = () => setShowModalSPC(false);

  //More Modal
  const [ShowModalMore, setShowModalMore] = useState(false);
  const openModalHandlerMore = () => setShowModalMore(true);
  const closeModalHandlerMore = () => setShowModalMore(false);

  // Check if Login and if not redirect
  useEffect(() => {
    const token = tokenLoader();

    if (token) {
      setIsLoggedIn(true);
    } else {
      setRedirectToLogin(true);
    }
  }, []);

  const navigate = useNavigate();

  const logoutHandler = async () => {
    const result = await confirmationAlert("Are you sure you want to log out?");

    if (result.isConfirmed) {
      localStorage.removeItem("token");
      successAlert("Logout Successfully");
      navigate("/login");
    }
  };

  if (redirectToLogin) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div className="relative">
          <button onClick={logoutHandler}>
            <img
              id="back"
              src={Back}
              className="h-10 md:-14 rounded-full"
              alt="Back Button"
            />
          </button>
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Home</h1>
          </div>
        </div>
        <div className="mt-20 p-3 md:p-10 text-center">
          <div className="flex justify-between mt-10">
            <button type="button" onClick={openModalHandlerPer}>
              <img
                src={Performance}
                className="h-20 md:h-36 lg:h-28 rounded-full"
                alt="performance"
              />
              Performance
            </button>
            <button type="button" onClick={openModalHandlerES}>
              <img
                src={ExternalSources}
                className="h-20 md:h-36 lg:h-28 ml-4 rounded-full"
                alt="External Sources"
              />
              External Sources
            </button>
            <button type="button" onClick={openModalHandlerM}>
              <img
                src={Meetings}
                className="h-20 md:h-36 lg:h-28 rounded-full"
                alt="Meetings"
              />
              Meetings
            </button>
          </div>
          <div className="flex justify-between mt-20 md:mt-28">
            <button type="button" onClick={openModalHandlerPre}>
              <img
                src={Presentation}
                className="h-20 md:h-36 lg:h-28 rounded-full"
                alt="Presentation"
              />{" "}
              Presentation
            </button>
            <button type="button" onClick={openModalHandlerSPC}>
              <img
                src={SPC}
                className="h-20 md:h-36 lg:h-28 rounded-full"
                alt="SPC"
              />{" "}
              SPC
            </button>
            <button type="button" onClick={openModalHandlerMore}>
              <img
                src={More}
                className="h-20 md:h-36 lg:h-28 rounded-full"
                alt="More"
              />{" "}
              More
            </button>
          </div>
        </div>
        <PerformanceModal
          isVisible={showModalPer}
          onClose={closeModalHandlerPer}
        />
        <ExternalSourcesModal
          isVisible={showModalES}
          onClose={closeModalHandlerES}
        />
        <MeetingsModal isVisible={showModalM} onClose={closeModalHandlerM} />
        <PresentationModal
          isVisible={showModalPre}
          onClose={closeModalHandlerPre}
        />
        <SPCModal isVisible={showModalSPC} onClose={closeModalHandlerSPC} />
        <MoreModal isVisible={ShowModalMore} onClose={closeModalHandlerMore} />
      </div>
    </div>
  );
};

export default Home;
