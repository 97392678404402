import React from "react";
import JointCallVisitList from "../../components/ExternalSources/ESJoinCallVisitList";

import { jointCallListDummy } from "../../components/types";

const JoinCallVisitListPage = () => {
  return (
    <div>
      <JointCallVisitList jointCallList={jointCallListDummy} />
    </div>
  );
};

export default JoinCallVisitListPage;
