import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { showErrorAlert } from "../../../UI/alert";

import Button from "../../../UI/button";
import Spinner from "../../../UI/Spinner";
import { FormContext } from "./FacilityForm";
import { tokenLoader } from "../../../UI/auth";
import { ITypeOfSite, IRating } from "../../../UI/interface";

const MainFacility = () => {
  const { formData, setFormData, handleChange, goToNextStep, isLoading } =
    useContext(FormContext);
  const [typeOfSite, setTypeOfSite] = useState<ITypeOfSite[]>([]);
  const [starRating, setStarRating] = useState<IRating[]>([]);

  // Fetch type of site data
  useEffect(() => {
    const fetchTypeOfSite = async () => {
      const token = tokenLoader();
      const siteResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/rfe/typeofsite`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await siteResponse.json();
      setTypeOfSite(jsonResponse);
    };
    fetchTypeOfSite();
  }, []);

  // Fetch star rating data
  useEffect(() => {
    const fetchRating = async () => {
      const token = tokenLoader();
      const ratingResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/rfe/starrating`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await ratingResponse.json();
      setStarRating(jsonResponse);
    };
    fetchRating();
  }, []);

  const handleStarValueChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSiteValueChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNextClick = () => {
    if (!formData.RFE_FACILITY_NAME.trim())
      return showErrorAlert(
        "Please input a Facility Name",
        "Facility Name is Empty"
      );
    if (!formData.RFE_SITE_TYPE_CODE)
      return showErrorAlert(
        "Please select Type of Site",
        "Type of Site is Empty"
      );
    goToNextStep();
  };

  return (
    <div>
      <div className="mt-8 p-2 border-2 rounded-md">
        <ul>
          <li>
            <label htmlFor="referralSiteId" className="sm-max:block">
              Referral Site ID
            </label>
            <input
              id="referralSiteId"
              name="RFE_ID"
              type="text"
              className="w-[100px] ml-[30px] sm-max:w-full sm-max:ml-0 md:w-[200px] mb-4 h-8 px-2 rounded-md text-black"
              value={formData.RFE_ID}
              onChange={handleChange}
              disabled
            />
          </li>
          <li>
            <label htmlFor="owner" className="sm-max:block">
              Owner
            </label>
            <input
              id="owner"
              name="FULL_NAME"
              type="text"
              className="w-[219px] ml-[87px] sm-max:w-full sm-max:ml-0 md:w-[571px] lg:w-[397px] h-8 mb-4 px-2 rounded-md text-black"
              value={formData.FULL_NAME}
              onChange={handleChange}
              disabled
            />
          </li>
          <li>
            <label htmlFor="facilityName" className="sm-max:block">
              Facility Name
            </label>
            <input
              id="facilityName"
              name="RFE_FACILITY_NAME"
              type="text"
              className="w-[219px] ml-[40px] sm-max:w-full sm-max:ml-0 md:w-[571px] lg:w-[397px] h-8 mb-4 px-2 rounded-md text-black"
              value={formData.RFE_FACILITY_NAME}
              onChange={handleChange}
              required
            />
          </li>
          <li>
            <label htmlFor="site" className="sm-max:block">
              Type of Site
            </label>
            <select
              id="site"
              name="RFE_SITE_TYPE_CODE"
              className="w-[219px] ml-[52px] sm-max:w-full sm-max:ml-0 md:w-[571px] lg:w-[397px] h-8 mb-4 px-2 rounded-md text-black"
              value={formData.RFE_SITE_TYPE_CODE}
              onChange={handleSiteValueChange}
            >
              <option value=""></option>
              {typeOfSite.map((typeOfSiteOption) => (
                <option
                  key={typeOfSiteOption.RFE_SITE_TYPE_CODE}
                  value={typeOfSiteOption.RFE_SITE_TYPE_CODE}
                >
                  {typeOfSiteOption.RFE_SITE_TYPE}
                </option>
              ))}
            </select>
          </li>
          <li>
            <textarea
              id="typeNote"
              name="RFE_NOTE"
              className="w-full mb-4 px-2 rounded-md text-black sm-max:w-full"
              placeholder="Type Note"
              rows={4}
              value={formData.RFE_NOTE}
              onChange={handleChange}
            ></textarea>
          </li>
          <li>
            <label htmlFor="shortName" className="sm-max:block">
              Short Name
            </label>
            <input
              id="shortName"
              type="text"
              name="RFE_SHORT_NAME"
              className="w-[219px] ml-[49px] sm-max:w-full sm-max:ml-0 md:w-[571px] lg:w-[397px] h-8 mb-4 px-2 rounded-md text-black"
              value={formData.RFE_SHORT_NAME}
              onChange={handleChange}
            />
          </li>
          <li>
            <label htmlFor="bdo" className="sm-max:block">
              BDO
            </label>
            <input
              id="bdo"
              type="text"
              name="RFE_BO_CODE"
              className="w-[100px] ml-[102px] sm-max:w-full sm-max:ml-0 md:w-[200px] h-8 mb-4 px-2 rounded-md text-black"
              value={formData.RFE_BO_CODE}
              onChange={handleChange}
              disabled
            />
          </li>
          <li>
            <label htmlFor="rating" className="sm-max:block">
              Rating
            </label>
            <select
              id="rating"
              name="RFE_RATING"
              className="w-[100px] ml-[89px] sm-max:w-full sm-max:ml-0 md:w-[200px] h-8 mb-4 px-2 rounded-md text-black"
              value={formData.RFE_RATING}
              onChange={handleStarValueChange}
            >
              <option value=""></option>
              {starRating.map((ratingOption) => (
                <option
                  key={ratingOption.RFE_RATING_CODE}
                  value={ratingOption.RFE_RATING_CODE}
                >
                  {ratingOption.RFE_RATING}
                </option>
              ))}
            </select>
          </li>
          <li>
            <input
              id="active"
              name="RFE_IsActive"
              type="checkbox"
              className="mb-4 mb-4 cursor-pointer mr-1"
              checked={formData.RFE_IsActive}
              onChange={handleChange}
            />
            <label htmlFor="active">Active</label>
          </li>
        </ul>
      </div>
      {isLoading && <Spinner />}
      <div className="mt-6 text-right">
        <Link to=".." relative="path">
          <Button className="w-[100px]">Cancel</Button>
        </Link>
        <Button
          type="button"
          className="w-[100px] ml-[12px]"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default MainFacility;
