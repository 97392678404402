import React, { useEffect, useRef, useState } from "react";
import { Form, Link, useLocation, useParams } from "react-router-dom";
import moment from "moment-timezone";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";
import { tokenLoader } from "../../UI/auth";
import Spinner from "../../UI/Spinner";
import { successAlert, showErrorAlert, alertLoading, closeAlert } from "../../UI/alert";

import { IDetails, IUpdate } from "../../UI/interface";

const MeetingDetails: React.FC = () => {
  const { VisitCTR } = useParams();
  const location = useLocation();

  const [itemDetails, setItemDetails] = useState<IDetails | null>(null);
  const [post, setPost] = useState<IUpdate[]>([]);
  const [isEditable, setIsEditable] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const visitIdInputRef = useRef<HTMLInputElement>(null);
  const employeeIdInputRef = useRef<HTMLInputElement>(null);
  const marketerInputRef = useRef<HTMLInputElement>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);
  const timeInputRef = useRef<HTMLInputElement>(null);
  const mileageInputRef = useRef<HTMLInputElement>(null);
  const unlistedSourceInputRef = useRef<HTMLInputElement>(null);
  const geolocationInputRef = useRef<HTMLInputElement>(null);
  const facilityInputRef = useRef<HTMLInputElement>(null);
  const addressInputRef = useRef<HTMLTextAreaElement>(null);
  const geoLatitudeInputRef = useRef<HTMLInputElement>(null);
  const geoLongitudeInputRef = useRef<HTMLInputElement>(null);
  const contactPersonInputRef = useRef<HTMLInputElement>(null);
  const activityInputRef = useRef<HTMLInputElement>(null);
  const marketingExpenseInputRef = useRef<HTMLInputElement>(null);
  const remarksInputRef = useRef<HTMLTextAreaElement>(null);


  //get the details of the facility from the backend
  useEffect(() => {
    setIsLoading(true);
    const token = tokenLoader();
    const fetchMeetingDetails = async () => {
      const responseMeetingDetails = await fetch(
        `${process.env.REACT_APP_API_URL}/meetings/visitdetails/${VisitCTR}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const fetchDetails = await responseMeetingDetails.json();
      setItemDetails(fetchDetails[0]);
      setIsLoading(false);

      if (fetchDetails[0]) {
        setIsEditable(false);
      }
    };
    fetchMeetingDetails();
  }, [VisitCTR]);

  // fetch Data from the server then auto fill-up the form
  useEffect(() => {
    const mapping = {
      VisitCTR: visitIdInputRef,
      EmployeeID: employeeIdInputRef,
      FULL_NAME: marketerInputRef,
      VisitDate: dateInputRef,
      VisitTime: timeInputRef,
      Mileage: mileageInputRef,
      UnlistedSource: unlistedSourceInputRef,
      EstablishmentName: facilityInputRef,
      EstablishmentAddress: addressInputRef,
      geoLatitude: geoLatitudeInputRef,
      geoLongitude: geoLongitudeInputRef,
      Contact_Fullname: contactPersonInputRef,
      Activity: activityInputRef,
      Marketing_Expense: marketingExpenseInputRef,
      Activity_Description: remarksInputRef,
    };

    if (itemDetails) {
      for (const [key, ref] of Object.entries(mapping)) {
        if (ref.current) {
          const value =
            key === "VisitDate"
              ? moment(itemDetails[key]).utc().format("MM/DD/YYYY")
              : key === "VisitTime"
              ? moment(
                  itemDetails[key].trim().padStart(6, "0"),
                  "HHmmss"
                ).format("hh:mm:ss A")
              : (itemDetails as Record<string, any>)[key];
          ref.current.value = value;
        }
      }
    }
  }, [itemDetails]);

  const detailsFormSubmitHandler = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const meetingRemarkUpdate: IUpdate = {
      VisitCTR: visitIdInputRef.current!.value,
      Activity_Description: remarksInputRef.current!.value,
    };

    alertLoading("Submitting", "Please wait ...");
    const token = tokenLoader();
    const updateRemarks = await fetch(
      `${process.env.REACT_APP_API_URL}/meetings/updateremarks`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(meetingRemarkUpdate),
      }
    );

    if (!updateRemarks.ok)
      return showErrorAlert(
        "The server encountered an internal error, Please contact the server administrator",
        "Internal Server Error"
      );
    if ("update") {
      closeAlert();
      return successAlert("Update Remarks Successfully")
    };

    const updateMeeting = await updateRemarks.json();
    setPost([...post, updateMeeting]);
    setIsLoading(false);
  };

  if (!itemDetails) {
    return <Spinner />;
  }

  const condition = location.pathname.includes("/meetingCurrent/");

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Meeting Details</h1>
          </div>
        </div>

        {/* Form */}
        <Form onSubmit={detailsFormSubmitHandler}>
          <div className="mt-6 p-2 border-2 rounded-md border-white">
            <ul className="mt-3 flex justify-between">
              <li>
                <label htmlFor="visitId">Visit ID</label>
                <input
                  id="visitId"
                  className="w-full px-2  md:w-[230px] md:ml-9 lg:w-40  lg:ml-[30px]  h-8 rounded-md text-black"
                  type="text"
                  ref={visitIdInputRef}
                  disabled={!isEditable}
                />
              </li>
              <li className="ml-3">
                <label htmlFor="employeeId">Employee ID</label>
                <input
                  id="employeeId"
                  className="w-full md:w-52 md:ml-2 lg:w-[140px] lg:ml-[12px] px-2  h-8 rounded-md text-black"
                  type="text"
                  ref={employeeIdInputRef}
                  disabled={!isEditable}
                />
              </li>
            </ul>
            <ul className="mt-4 ">
              <li className="flex items-center">
                <label htmlFor="marketer">Marketer</label>
                <input
                  id="marketer"
                  className="flex-1 ml-2 md:ml-[22px] lg:ml-[16px] px-2 h-8 rounded-md text-black"
                  type="text"
                  ref={marketerInputRef}
                  disabled={!isEditable}
                />
              </li>
              <li className="mt-4 flex items-center">
                <label htmlFor="date">Date</label>
                <input
                  id="date"
                  className="flex-1 ml-10 md:ml-[52px] lg:ml-[45px] px-2 h-8 rounded-md text-black cursor-pointer"
                  type="text"
                  ref={dateInputRef}
                  disabled={!isEditable}
                />
              </li>
            </ul>
            <ul className="mt-4 flex">
              <li className="flex items-center">
                <label htmlFor="time">Time</label>
                <input
                  id="time"
                  className="w-full ml-2 md:ml-[51px] lg:ml-[44px] h-8 pl-2 text-black rounded-md"
                  type="text"
                  ref={timeInputRef}
                  disabled={!isEditable}
                />
              </li>
              <li className="flex items-center ml-3">
                <label htmlFor="mileage">Mileage</label>
                <input
                  id="mileage"
                  className="w-full ml-2 h-8 pl-2 text-black rounded-md"
                  type="text"
                  ref={mileageInputRef}
                  disabled={!isEditable}
                />
              </li>
            </ul>
            <ul className="columns-2 mt-4">
              <li>
                <input
                  id="source"
                  className="cursor-pointer mr-1"
                  type="checkbox"
                  checked={itemDetails.UnlistedSource}
                  ref={unlistedSourceInputRef}
                  disabled={!isEditable}
                />
                <label htmlFor="source">Unlisted Source</label>
              </li>
              <li>
                <input
                  id="geolocation"
                  className="cursor-pointer mr-1"
                  type="checkbox"
                  checked={false}
                  ref={geolocationInputRef}
                  disabled={!isEditable}
                />
                <label htmlFor="geolocation">No Geolocation</label>
              </li>
            </ul>
            <ul>
              <li className="mt-4">
                <label htmlFor="facilityName">Facility Name</label>
                <input
                  id="facilityName"
                  className="w-full px-2 h-8 mt-2 rounded-md text-black"
                  type="text"
                  ref={facilityInputRef}
                  disabled={!isEditable}
                />
              </li>
              <li className="mt-4">
                <label htmlFor="address">Address</label>
                <textarea
                  id="address"
                  className="w-full px-2 mt-2 rounded-md text-black"
                  rows={4}
                  ref={addressInputRef}
                  disabled={!isEditable}
                ></textarea>
              </li>
              <ul>
                <li className="mt-4 md:flex">
                  <label htmlFor="geoLatitude" className="md:w-[210px]">
                    Geo-Latitude
                  </label>
                  <input
                    id="geoLatitude"
                    className="w-full px-2 h-8 rounded-md text-black"
                    type="text"
                    ref={geoLatitudeInputRef}
                    disabled={!isEditable}
                  />
                </li>
                <li className="mt-4 md:flex">
                  <label htmlFor="geoLongitude" className="md:w-[210px]">
                    Geo-Longitude
                  </label>
                  <input
                    id="geoLongitude"
                    className="w-full px-2 h-8 rounded-md text-black"
                    type="text"
                    ref={geoLongitudeInputRef}
                    disabled={!isEditable}
                  />
                </li>
                <li className="mt-4 md:flex">
                  <label htmlFor="contactPerson" className="md:w-[210px]">
                    Contact Person
                  </label>
                  <input
                    id="contactPerson"
                    className="w-full px-2 h-8 rounded-md text-black"
                    type="text"
                    ref={contactPersonInputRef}
                    disabled={!isEditable}
                  />
                </li>
                <li className="mt-4 md:flex">
                  <label htmlFor="activity" className="md:w-[210px]">
                    Activity
                  </label>
                  <input
                    id="activity"
                    className="w-full px-2 h-8 rounded-md text-black"
                    type="text"
                    ref={activityInputRef}
                    disabled={!isEditable}
                  />
                </li>
                <li className="mt-4 md:flex">
                  <label htmlFor="marketingExpense" className="md:w-[210px]">
                    Marketing Expense
                  </label>
                  <input
                    id="marketingExpense"
                    className="w-full px-2 h-8 rounded-md text-black"
                    type="text"
                    ref={marketingExpenseInputRef}
                    disabled={!isEditable}
                  />
                </li>
              </ul>
              <li className="my-4">
                <label htmlFor="remarks" className="align-top">
                  Remarks
                </label>
                <textarea
                  id="remarks"
                  className="w-full px-2 rounded-md text-black"
                  rows={4}
                  ref={remarksInputRef}
                ></textarea>
              </li>
            </ul>
          </div>
          <div>
            <ul className="flex flex-wrap justify-around">
              <li>
                {condition ? (
                  <Link
                    to={`/meetingCurrent/${VisitCTR}/${itemDetails.FULL_NAME}/attachReceipt`}
                  >
                    <Button className="w-[160px] lg:w-[131px] lg:px-3 mt-4">
                      Attach Receipt
                    </Button>
                  </Link>
                ) : (
                  <Link
                    to={`/meetingPrevious/${VisitCTR}/${itemDetails.FULL_NAME}/attachReceipt`}
                  >
                    <Button className="w-[160px] lg:w-[131px] lg:px-3 mt-4">
                      Attach Receipt
                    </Button>
                  </Link>
                )}
              </li>
              <li>
                {condition ? (
                  <Link to={`/meetingCurrent/${VisitCTR}/ViewReceipt`}>
                    <Button className="w-[150px] md:w-[160px] lg:w-[131px] lg:px-3 mt-4">
                      View Receipt
                    </Button>
                  </Link>
                ) : (
                  <Link to={`/meetingPrevious/${VisitCTR}/ViewReceipt`}>
                    <Button className="w-[150px] md:w-[160px] lg:w-[131px] lg:px-3 mt-4">
                      View Receipt
                    </Button>
                  </Link>
                )}
              </li>
              <li>
                <Link to=".." relative="path">
                  <Button className="w-[160px] md:w-[160px] lg:w-[131px] lg:px-3 mt-4">
                    Cancel
                  </Button>
                </Link>
              </li>
              <li>
                <Button
                  id="update"
                  type="submit"
                  className="md:w-[160px] w-[150px] lg:w-[131px] lg:px-3 mt-4"
                >
                  Save
                </Button>
              </li>
            </ul>
          </div>
        </Form>
        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

export default MeetingDetails;
