import { Link } from "react-router-dom";

import Back from "../image/back.png";

const BackButton = () => {
  return (
    <div>
      <Link to=".." relative="path">
        <img
          src={Back}
          className="h-10 md:h-10 rounded-full"
          alt="Back Button"
        />
      </Link>
    </div>
  );
};

export default BackButton;
