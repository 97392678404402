import React from "react";
import JointCallDetails from "../../components/ExternalSources/ESJoinCallDetails";

const JointCallDetailsPage = () => {
  return (
    <div>
      <JointCallDetails />
    </div>
  );
};

export default JointCallDetailsPage;
