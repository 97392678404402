import React from "react";
import { Text, View } from "@react-pdf/renderer";

// import css from "./ViewMer.Style";
import css from "./ViewMer.Style"
import { ITableViewMer } from "../../../UI/interface";


const CutOff: React.FC<ITableViewMer> = ({ tableData }) => {
  if (tableData.length === 0) {
    return null;
  }

  const totalData = tableData[0];

  return (
    <div>
      <View style={css.cutOffContainer}>
        {/* Header */}
        <View style={css.tableHeader}>
          <Text>Cut-off Total:</Text>
        </View>
        {/* Body */}
        <View style={css.tableBody}>
          {/* column 1 */}
          <View>
            <View style={css.tableBodyC1}>
              <Text>Reimburse Mileage</Text>
              <Text style={{ marginLeft: 30, flex: 1 }}>
                {totalData.dailyMileage.toFixed(3)}
              </Text>
            </View>
          </View>
          {/* column 2 */}
          <View style={{ flex: 1, marginLeft: 40 }}>
            <View style={css.tableBodyC2}>
              <Text>Adjustment</Text>
              <Text style={{ marginLeft: 22 }}>
                {totalData.adjustment.toFixed(3)}
              </Text>
            </View>
            <View style={css.tableBodyC2}>
              <Text>Car Insurance</Text>
              <Text style={{ marginLeft: 7 }}>
                {totalData.carInsurance.toFixed(3)}
              </Text>
            </View>
            <View style={css.tableBodyC2}>
              <Text>Food</Text>
              <Text style={{ marginLeft: 55 }}>
                {totalData.food.toFixed(3)}
              </Text>
            </View>
            <View style={css.tableBodyC2}>
              <Text>Gas</Text>
              <Text style={{ marginLeft: 59 }}>
                {totalData.gasoline.toFixed(3)}
              </Text>
            </View>
          </View>

          {/* column 3 */}
          <View style={{ flex: 1, marginLeft: 0 }}>
            <View style={css.tableBodyC2}>
              <Text>Gift</Text>
              <Text style={{ marginLeft: 70 }}>
                {totalData.gift.toFixed(3)}
              </Text>
            </View>
            <View style={css.tableBodyC2}>
              <Text>Accomodation</Text>
              <Text style={{ marginLeft: 13 }}>
                {totalData.hotelAccomodation.toFixed(3)}
              </Text>
            </View>
            <View style={css.tableBodyC2}>
              <Text>Office Supplies</Text>
              <Text style={{ marginLeft: 8 }}>
                {totalData.officeSupplies.toFixed(3)}
              </Text>
            </View>
            <View style={css.tableBodyC2}>
              <Text>Parking</Text>
              <Text style={{ marginLeft: 48 }}>
                {totalData.parking.toFixed(3)}
              </Text>
            </View>
          </View>

          {/* column 4 */}
          <View style={{ flex: 1, marginLeft: 0 }}>
            <View style={css.tableBodyC2}>
              <Text>Toll</Text>
              <Text style={{ marginLeft: 34 }}>
                {totalData.toll.toFixed(3)}
              </Text>
            </View>
            <View style={css.tableBodyC2}>
              <Text>Transpo</Text>
              <Text style={{ marginLeft: 10 }}>
                {totalData.transport.toFixed(3)}
              </Text>
            </View>
          </View>
        </View>

        {/* Footer */}
        <View style={css.tableFooter}>
          <View>
            <View style={css.tableBodyC1}>
              <Text>Total Reimburseable:</Text>
              <Text style={{ marginLeft: 10, flex: 1 }}>
                {totalData.totalReimburseable.toFixed(3)}
              </Text>
            </View>
          </View>
          <View style={{ flex: 1, marginLeft: 70 }}>
            <View style={css.tableBodyC2}>
              <Text>Total Mktg. Expense:</Text>
              <Text style={{ marginLeft: 100 }}>
                {totalData.totalMarketingExpense.toFixed(3)}
              </Text>
            </View>
          </View>
          <View style={{ flex: 1, marginLeft: 100 }}>
            <View style={css.dailyTotal}>
              <Text>Cut-off Total:</Text>
              <Text style={{ marginLeft: 5 }}>
                {totalData.dailyTotal.toFixed(3)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </div>
  );
};

export default CutOff;
