import { Form } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";

const CovidQuestionare = () => {
  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Covid Questionaire</h1>
          </div>
        </div>
        <Form>
          <div className="mt-8 p-2 border-2 rounded-md">
            <ul>
              <li>
                <p>
                  * COVID Test Questionaire must be filled up by field employees
                  on a daily basis.
                </p>
              </li>
              <li>
                <label htmlFor="temperature">Temperature</label>
                <input
                  type="text"
                  id="temperature"
                  className="w-[120px] mt-4 ml-[18px] px-2 h-8 rounded-md"
                />
              </li>
              <li className="mt-4">
                <label>Scale</label>
                <Button className="w-[119px] ml-[71px]">Celsius</Button>
                <Button className="w-[119px] ml-2">Fahrenheit</Button>
              </li>
              <li className="mt-4">
                <p>
                  1. Are you isolating or quarantining because you may have been
                  exposed to a person with COVID-19 or are worried that you may
                  be sick with COVID-19?
                </p>
                <Button className="w-[80px] ml-2 mt-2">Yes</Button>
                <Button className="w-[80px] ml-4">No</Button>
              </li>
              <li className="mt-4">
                <p>
                  2. Within the past 14 days, have you been in close physical
                  contact (6 feet or closer for at least 15 minutes) with a
                  person who is known to have laboratory-confirmed COVID-19 or
                  with anyone who has any symptoms consistent with COVID-19?
                </p>
                <Button className="w-[80px] ml-2 mt-2">Yes</Button>
                <Button className="w-[80px] ml-4">No</Button>
              </li>
              <li className="mt-4">
                <p>
                  3. Have you experienced any of the following symptoms in the
                  past 48 hours:
                </p>
                <Button className="w-[80px] ml-2 mt-2">Yes</Button>
                <Button className="w-[80px] ml-4">No</Button>
              </li>
              <li className="w-full border-2 p-2 mt-2 rounded-md">
                <ul>
                  <p className="text-center">
                    If Yes on 3, Select any that following:
                  </p>
                  <li className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      id="fever"
                      className="mr-2 h-5 w-5"
                    />
                    <label htmlFor="fever">Fever or Chills</label>
                  </li>
                  <li className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      id="cough"
                      className="mr-2 h-5 w-5"
                    />
                    <label htmlFor="cough">Cough</label>
                  </li>
                  <li className="flex items-center mt-3">
                    <input type="checkbox" id="sore" className="mr-2 h-5 w-5" />
                    <label htmlFor="sore">Sore Throat</label>
                  </li>
                  <li className="flex items-center mt-3">
                    <input type="checkbox" id="nose" className="mr-2 h-5 w-5" />
                    <label htmlFor="nose">Runny or Stuffy Nose</label>
                  </li>
                  <li className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      id="muscle"
                      className="mr-2 h-5 w-5"
                    />
                    <label htmlFor="muscle">Muscle or Body Aches</label>
                  </li>
                  <li className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      id="fatigued"
                      className="mr-2 h-5 w-5"
                    />
                    <label htmlFor="fatigued">Fatigued(Tiredness)</label>
                  </li>
                  <li className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      id="diarrhea"
                      className="mr-2 h-5 w-5"
                    />
                    <label htmlFor="diarrhea">
                      Vomiting, Nausea or Diarrhea
                    </label>
                  </li>
                  <li className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      id="breath"
                      className="mr-2 h-5 w-5"
                    />
                    <label htmlFor="breath">
                      Shortness of Breath/ Difficulty Breathing
                    </label>
                  </li>
                  <li className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      id="headache"
                      className="mr-2 h-5 w-5"
                    />
                    <label htmlFor="headache">Headache</label>
                  </li>
                  <li className="flex items-center mt-3 mb-3">
                    <input type="checkbox" id="tast" className="mr-2 h-5 w-5" />
                    <label htmlFor="tast">New Loss of Tast or Smell</label>
                  </li>
                </ul>
              </li>
              <li className="mt-4">
                <p>
                  4. Are you currently waiting on the results of a COVID-19
                  test?
                </p>
                <Button className="w-[80px] ml-2 mt-2">Yes</Button>
                <Button className="w-[80px] ml-4">No</Button>
              </li>
              <li className="flex items-center mt-6">
                <input type="checkbox" id="tast" className="mr-2 h-5 w-5" />
                <p>
                  I certify that the information submitted in this application
                  is true and correct to the best of my knowledge
                </p>
              </li>
            </ul>
          </div>
          <div>
            <Button className="w-full mt-4">CDC Guidelines</Button>
            <Button className="w-[180px] md:w-[357px] lg:w-[270px] mt-4">
              Submit
            </Button>
            <Button className="w-[180px] md:w-[357px] lg:w-[270px] ml-3">
              Close
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CovidQuestionare;
