import React from "react";
import { comingSoonAlert } from "../UI/alert";

interface ModalType {
  isVisible: boolean;
  onClose: () => void;
}

const PerformanceModal: React.FC<ModalType> = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
      onClick={() => onClose()}
    >
      <div className="bg-blue-300 w-[300px] rounded-md">
        <div className="py-6 px-4 text-center text-white">
          <button
            className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]"
            onClick={comingSoonAlert}
          >
            Performance - Current Month
          </button>
          <button
            className="w-full py-2 px-3 mt-4 rounded-md bg-[#13547a]"
            onClick={comingSoonAlert}
          >
            Performance - Previous Months
          </button>
          <button
            className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]"
            onClick={comingSoonAlert}
          >
            Performance - COP
          </button>
          <button
            className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]"
            onClick={comingSoonAlert}
          >
            Performance Not Full
          </button>
          <button
            className="w-full py-2 px-6 mt-10 rounded-md bg-[#13547a]"
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PerformanceModal;
