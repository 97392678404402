import React, { useRef } from "react";
import { Form, Link, useNavigate } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";

const JointCallDetails: React.FC = () => {
  const jointCallDateInputRef = useRef<HTMLInputElement>(null);
  const initiatedByInputRef = useRef<HTMLSelectElement>(null);
  const marketerInputRef = useRef<HTMLSelectElement>(null);
  const referralSourceInputRef = useRef<HTMLSelectElement>(null);
  const purposeInputRef = useRef<HTMLTextAreaElement>(null);
  const completedInputRef = useRef<HTMLInputElement>(null);
  const createdByInputRef = useRef<HTMLInputElement>(null);
  const createdDateInputRef = useRef<HTMLInputElement>(null);
  const createdTimeInputRef = useRef<HTMLInputElement>(null);
  const modifiedByInputRef = useRef<HTMLInputElement>(null);
  const modifiedDateInputRef = useRef<HTMLInputElement>(null);
  const modifiedTimeInputRef = useRef<HTMLInputElement>(null);
  const noteInputRef = useRef<HTMLTextAreaElement>(null);

  const navigate = useNavigate();

  const jointCallDetailHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const jointCallDetailsData = {
      jointCallDate: jointCallDateInputRef.current!.value,
      initiatedBy: initiatedByInputRef.current!.value,
      marketer: marketerInputRef.current!.value,
      referralSource: referralSourceInputRef.current!.value,
      purpose: purposeInputRef.current!.value,
      completed: completedInputRef.current!.checked,
      createdBy: createdByInputRef.current!.value,
      createdDate: createdDateInputRef.current!.value,
      createdTime: createdTimeInputRef.current!.value,
      modifiedBy: modifiedByInputRef.current!.value,
      modifiedDate: modifiedDateInputRef.current!.value,
      modifiedTime: modifiedTimeInputRef.current!.value,
      note: noteInputRef.current!.value,
    };

    console.log(jointCallDetailsData);

    navigate("/ExternalSourcesJointCall");
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-lg">
        {/* Header */}
        <div>
          <BackButton />
          <div>
            <h1 className="text-center text-[30px] mt-[-40px]">
              Joint Call Detail
            </h1>
          </div>
        </div>
        <Form onSubmit={jointCallDetailHandler}>
          <div className="mt-6 p-2 border-2 rounded-md">
            {/* Form */}
            <div>
              <ul>
                <li>
                  <label htmlFor="jointCallDate">Joint Call Date</label>
                  <input
                    type="date"
                    id="JointCallDate"
                    className="ml-[40px] md:w-[213px] lg:w-[160px] mt-4 h-8 rounded-md text-black px-2"
                    ref={jointCallDateInputRef}
                  />
                </li>
                <li>
                  <label htmlFor="initiatedBy">Initiated By</label>
                  <select
                    id="initiatedBy"
                    className="w-[212px] ml-[62px] md:w-[564px] lg:w-[326px] mt-4 h-8 px-2 rounded-md text-black"
                    ref={initiatedByInputRef}
                  >
                    <option value="test"></option>
                    <option value="test2">Montano, Genesis</option>
                  </select>
                </li>
                <li>
                  <label htmlFor="marketer">Marketer</label>
                  <select
                    name=""
                    id="marketer"
                    className="w-[212px] ml-[77px] md:w-[564px] lg:w-[326px] mt-4 h-8 px-2 rounded-md text-black"
                    ref={marketerInputRef}
                  >
                    <option value="marketer"></option>
                    <option value="marketer1">Garcia, Kiani</option>
                  </select>
                </li>
                <li>
                  <label>Referral Source</label>
                  <select
                    name=""
                    className="w-[212px] ml-[34px] md:w-[564px] lg:w-[326px] mt-4 mb-4 h-8 px-2 rounded-md text-black"
                    ref={referralSourceInputRef}
                  >
                    <option value="source"></option>
                    <option value="source1">All Saints</option>
                  </select>
                </li>
                <li>
                  <label htmlFor="purpose">Purpose</label>
                  <textarea
                    name=""
                    id="purpose"
                    rows={4}
                    className="w-full mt-2 px-2 rounded-md text-black"
                    ref={purposeInputRef}
                  ></textarea>
                </li>
              </ul>
              <ul className="flex items-center mt-4">
                <li>
                  <input
                    type="checkbox"
                    id="completed"
                    className="cursor-pointer"
                    ref={completedInputRef}
                  />
                  <label htmlFor="completed" className="ml-1 cursor-pointer">
                    Completed
                  </label>
                </li>
                <li className="ml-[46px]">
                  <Button>View</Button>
                </li>
              </ul>
              <ul>
                <li>
                  <label htmlFor="createdBy">Created By</label>
                  <input
                    type="text"
                    id="createdBy"
                    className="w-[212px] ml-[65px] md:w-[564px] lg:w-[326px] mt-4 h-8 px-2 rounded-md text-black"
                    ref={createdByInputRef}
                  />
                </li>
                <li>
                  <label htmlFor="createdDate">Created Date</label>
                  <input
                    type="date"
                    id="createdDate"
                    className="ml-[48px] md:w-[213px] lg:w-[160px] mt-4 h-8 px-2 rounded-md text-black"
                    ref={createdDateInputRef}
                  />
                </li>
                <li>
                  <label htmlFor="createdTime">Created Time</label>
                  <input
                    type="time"
                    id="createdTime"
                    className="w-[153px] ml-[47px] md:w-[213px] lg:w-[160px] mt-4 h-8 px-2 rounded-md text-black"
                    ref={createdTimeInputRef}
                  />
                </li>
                <li>
                  <label htmlFor="modifiedBy">Modified By</label>
                  <input
                    type="text"
                    id="modifiedBy"
                    className="w-[212px] ml-[56px] md:w-[564px] lg:w-[326px] mt-4 h-8 px-2 rounded-md text-black"
                    ref={modifiedByInputRef}
                  />
                </li>
                <li>
                  <label htmlFor="modifiedDate">Modified Date</label>
                  <input
                    type="date"
                    id="modifiedDate"
                    className="ml-[40px] md:w-[213px] lg:w-[160px] mt-4 h-8 px-2 rounded-md text-black"
                    ref={modifiedDateInputRef}
                  />
                </li>
                <li>
                  <label htmlFor="modifiedTime">Modified Time</label>
                  <input
                    type="time"
                    id="modifiedTime"
                    className="w-[153px] ml-[38px] md:w-[213px] lg:w-[160px] mt-4 h-8 px-2 rounded-md text-black"
                    ref={modifiedTimeInputRef}
                  />
                </li>
                <li>
                  <textarea
                    rows={4}
                    className="w-full mt-4 mb-2 px-2 rounded-md text-black"
                    ref={noteInputRef}
                  ></textarea>
                </li>
              </ul>
            </div>
            {/* Button */}
            <div>
              <Button className="w-[170px] md:w-[161px] lg:w-[111px]">
                Upload
              </Button>
              <Button className="w-[170px] md:w-[161px] lg:w-[111px] ml-3 md:ml-5 lg:ml-2">
                Download
              </Button>
              <Link to=".." relative="path">
                <Button className="w-[170px] md:w-[161px] lg:w-[111px] md:ml-5 lg:ml-2">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                className="w-[170px] md:w-[161px] lg:w-[111px] ml-3 mt-4 md:ml-5 lg:ml-2"
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default JointCallDetails;
