import React from "react";
import SPCAdd from "../../components/Top20SPC/SPCAdd";

const SPCAddPage = () => {
  return (
    <div>
      <SPCAdd />
    </div>
  );
};

export default SPCAddPage;
