import { Form } from "react-router-dom"
import BackButton from "../../UI/BackButton"

const BusinessOpportunityCode = () => {
    return (
        <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
            <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
                {/* Header */}
                <div className="relative">
                    <BackButton />
                    <div className="text-center">
                        <h1 className="ml-[15px] text-[23px] mt-[-40px]">Business Opportunity Code</h1>
                    </div>
                </div>

                <Form className="mt-8 border-2 rounded-md">
                    <div className="h-[700px] bg-white overflow-scroll">
                        <table className="w-full">
                            <thead className="bg-blue-900 text-left h-8 sticky top-0">
                                <tr>
                                    <th className="landscape:hidden pl-1 w-[200px] md:w-[450px]">Facility</th>
                                    <th className="portrait:hidden pl-1 w-[450px] md:w-[300px]">Facility</th>
                                    <th className="pl-1 ml-[50px]">Type of Site</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default BusinessOpportunityCode