import { useState, useEffect } from "react";
import { Form, Link } from "react-router-dom";

import BackButton from "../../UI/BackButton";
import Button from "../../UI/button";
import { tokenLoader } from "../../UI/auth";
import Spinner from "../../UI/Spinner";
import moment from "moment-timezone";

import { IFacilityList } from "../../UI/interface";

const MeetingCurrentList = () => {
  const [currentDate, setCurrentDate] = useState<string>("");
  const [facilityList, setFacilityList] = useState<IFacilityList[]>([]);
  const [countVisits, setCountVisits] = useState<number>(0);
  const [mileage, setMileage] = useState<number | null>(null);
  const [totalReimbursement, setTotalReimbursement] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const currentDate = moment().tz(moment.tz.guess()).format("MM-DD-YYYY");
    setCurrentDate(currentDate);
  }, []);

  useEffect(() => {
    if (currentDate) {
      setIsLoading(true);
      const fetchFacilityList = async () => {
        const token = tokenLoader();
        const responseFacilityList = await fetch(
          `${process.env.REACT_APP_API_URL}/meetings/visits/${currentDate}`,
          { headers: { Authorization: "Bearer " + token } }
        );
        const jsonResponseFacility = await responseFacilityList.json();
        setFacilityList(jsonResponseFacility);
        setIsLoading(false);
      };
      fetchFacilityList();
    }
  }, [currentDate]);

  // Mileage
  useEffect(() => {
    if (facilityList) {
      const sumOfMileage = facilityList.reduce(
        (acc, facilityList) => acc + facilityList.Mileage,
        0
      );
      setMileage(sumOfMileage);
    }
  }, [facilityList]);

  //Visits
  useEffect(() => {
    if (facilityList) {
      const filteredVisits = facilityList.length;
      setCountVisits(filteredVisits);
    }
  }, [facilityList]);

  //Reimbursement
  useEffect(() => {
    if (facilityList) {
      const sumOfReimbursement = facilityList.reduce(
        (acc, facilityList) => acc + facilityList.MileageReimbursement,
        0
      );
      setTotalReimbursement(sumOfReimbursement);
    }
  }, [facilityList]);

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Meetings Current</h1>
          </div>
        </div>
        <Form className="mt-8 p-2 border-2 rounded-md">
          <div className="flex justify-between align-center py-2 mt-4">
            <div>
              <label htmlFor="today">Today</label>
              <input
                id="today"
                type="text"
                className="w-[130px] ml-2 md:w-[160px] h-8 rounded-md text-center text-black cursor-pointer"
                defaultValue={currentDate}
                readOnly
              />
            </div>
            <div className="flex">
              <p className="mr-6">Legend:</p>
              <p className="mr-4">LS</p>
              <p>US</p>
            </div>
          </div>
          <div className="h-[420px] mt-4 mb-4 bg-white overflow-scroll">
            <table className="w-full">
              <thead>
                <tr className="bg-blue-900 h-8 text-left top-0 sticky">
                  <th className="pl-1 font-normal">Facility Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="text-black">
                {facilityList.map((facility) => {
                  return (
                    <tr
                      key={facility.VisitCTR}
                      className="odd:bg-white h-8 even:bg-slate-200 border-b-2"
                    >
                      <td className="pl-3 mr-20">{`${facility.EstablishmentName}`}</td>
                      <td className="text-center w-[100px] md:w-[150px] ">
                        <Link to={`${facility.VisitCTR}`}>
                          <Button>More</Button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Form>
        {isLoading && <Spinner />}
        <div>
          <ul>
            <li className="mt-4 text-right">
              <label htmlFor="totalMileage" className="mr-2">
                Total Mileage
              </label>
              <input
                id="totalMileage"
                type="text"
                className="w-[65px] md:w-[120px] h-8  px-2 rounded-md text-black text-left"
                value={mileage?.toFixed(2) || ""}
                readOnly
              />
            </li>
            <li className="mt-4 text-right">
              <label htmlFor="totalVisits" className="mr-2">
                Total Visits
              </label>
              <input
                id="totalVisits"
                type="text"
                className="w-[65px] md:w-[120px] h-8 px-2 rounded-md text-black text-left"
                value={countVisits || 0}
                readOnly
              />
            </li>
            <li className="mt-4 mb-4 text-right">
              <label htmlFor="totalReimbursement" className="mr-2">
                Total Reimbursement
              </label>
              <input
                id="totalReimbursement"
                type="text"
                className="w-[65px] md:w-[120px] h-8 px-2 rounded-md text-black text-left"
                value={totalReimbursement?.toFixed(2) || ""}
                readOnly
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MeetingCurrentList;
