import React from "react";
import AttachReceipt from "../../components/Meetings/AddReceipt/AttachReceipt";

const AttachReceiptPage = () => {
  return (
    <div>
      <AttachReceipt />
    </div>
  );
};

export default AttachReceiptPage;
