import React, { useEffect, useState, useRef } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";

import BackButton from "../../UI/BackButton";
import Button from "../../UI/button";
import Spinner from "../../UI/Spinner";
import { tokenLoader } from "../../UI/auth";
import {
  successAlert,
  showErrorAlert,
  confirmationAlert,
  alertLoading,
  closeAlert,
} from "../../UI/alert";
import { IReceiptDetails, IUpdateReceipt } from "../../UI/interface";

const ReceiptDetails: React.FC = () => {
  const { Receipt_CTR } = useParams();
  const [receiptDetails, setReceiptDetails] = useState<IReceiptDetails | null>(
    null
  );
  const [receiptImage, setReceiptImage] = useState<string | null>(null);
  const [isEditable, setIsEditable] = useState<boolean>(true);
  const [post, setPost] = useState<IUpdateReceipt[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const expenseCodeInputRef = useRef<HTMLInputElement>(null);
  const amountInputRef = useRef<HTMLInputElement>(null);
  const paymentTypeInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const remarksInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const fetchReceiptDetails = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/marketervisit/receiptdetails/${Receipt_CTR}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const jsonResponse = await response.json();
      setReceiptDetails(jsonResponse[0]);

      if (jsonResponse[0]) {
        setIsEditable(false);
      }
      const image = jsonResponse[0].Path;
      setReceiptImage(`${process.env.REACT_APP_API_URL}/${image}`);
      setIsLoading(false);
    };
    fetchReceiptDetails();
  }, [Receipt_CTR]);

  //Fetch Data from the server and fill up the form
  useEffect(() => {
    if (receiptDetails) {
      if (expenseCodeInputRef.current) {
        expenseCodeInputRef.current.value = receiptDetails.ExpenseCode;
      }
      if (amountInputRef.current) {
        amountInputRef.current.value = receiptDetails.Cost;
      }
      if (paymentTypeInputRef.current) {
        paymentTypeInputRef.current.value = receiptDetails.PaymentType;
      }
      if (remarksInputRef.current) {
        remarksInputRef.current.value = receiptDetails.Remarks;
      }
      if (imageInputRef.current) {
        imageInputRef.current.value = receiptDetails.Path;
      }
    }
  }, [receiptDetails]);

  const navigate = useNavigate();

  const updateReceiptHandler = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const viewReceiptUpdate: IUpdateReceipt = {
      Cost: amountInputRef.current!.value,
      Remarks: remarksInputRef.current!.value,
    };

    alertLoading("Submitting", "Please wait ...");
    const token = tokenLoader();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/marketervisit/updatereceipt/${Receipt_CTR}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(viewReceiptUpdate),
      }
    );

    if (!response.ok) {
      showErrorAlert(
        "The server encountered an internal error, Please contact the server administrator",
        "Internal Server Error"
      );
    }

    if ("update") {
      closeAlert();
      successAlert("Update Receipt Successfully");
      navigate(-1);
    }

    const jsonResponse = await response.json();
    setPost([...post, jsonResponse]);
  };

  const deleteReceiptHandler = async () => {
    const result = await confirmationAlert(
      "You won't be able to revert this!",
      "Are you sure?"
    );

    //Fetch Receipt to Delete
    if (result.isConfirmed) {
      alertLoading("Deleting", "Please wait ...");
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/marketervisit/deletereceipt/${Receipt_CTR}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.ok) {
        closeAlert();
        successAlert("Successfully Deleted the Receipt");
        navigate(-1);
      } else {
        showErrorAlert("Failed to delete the receipt.", "Error");
      }
    }
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Update Receipt</h1>
          </div>
        </div>
        <Form onSubmit={updateReceiptHandler}>
          <div className="mt-8 p-2 border-2 rounded-md">
            <ul>
              <li className="mt-4">
                <label htmlFor="expenseCode">Expense Code</label>
                <input
                  id="expenseCode"
                  className="w-full text-black pl-3 mt-4 h-8 rounded-md"
                  type="text"
                  ref={expenseCodeInputRef}
                  disabled={!isEditable}
                />
              </li>
              <li>
                <ul className="columns-2 mt-4">
                  <li>
                    <label htmlFor="amount">Amount</label>
                    <input
                      id="amount"
                      className="w-full mr-2 mt-4 h-8 px-2 text-black rounded-md flex"
                      type="number"
                      ref={amountInputRef}
                      step={0.01}
                    />
                  </li>
                  <li>
                    <label htmlFor="paymentType">Payment Type</label>
                    <input
                      id="paymentType"
                      className="w-full mt-4 h-8 px-2 rounded-md text-black flex"
                      type="text"
                      ref={paymentTypeInputRef}
                      disabled={!isEditable}
                    />
                  </li>
                </ul>
              </li>
              <li className="h-[400px] flex justify-center object-contain mt-4">
                {receiptImage && (
                  <img
                    className="h-[400px] w-[355px] rounded-md text-justify"
                    src={receiptImage}
                    alt="ReceiptImage"
                  />
                )}
              </li>
              <li className="mt-4">
                <label htmlFor="remarks">Remarks</label>
                <textarea
                  id="remarks"
                  className="w-full mt-4 mb-4 pl-2 rounded-md text-black"
                  ref={remarksInputRef}
                  rows={3}
                ></textarea>
              </li>
            </ul>
          </div>
          <div className="mt-4 flex justify-end flex-wrap">
            <Button id="update" type="submit" className="w-[100px] mr-3">
              Save
            </Button>
            <Button className="w-[100px]" onClick={deleteReceiptHandler}>
              Delete
            </Button>
          </div>
        </Form>
        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

export default ReceiptDetails;
