import BackButton from "../../UI/BackButton";

//interface
import { MoreInsuranceList } from "../types";

type Props = {
  insuranceList: MoreInsuranceList[];
};

const InsuranceList = (props: Props) => {
  const { insuranceList } = props;

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Insurance List</h1>
          </div>
        </div>
        <div className="mt-8 p-2 border-2 rounded-md">
          <div className="my-4">
            <div>
              <label>Agency:</label>
              <select className="w-full h-8 px-2 text-black mt-2 rounded-md">
                <option value="agency"></option>
                <option value="agency1">agency1</option>
              </select>
            </div>
            <div className="mt-4 h-[530px] bg-white">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#13457a] h-8">
                    <th className="w-[294px] font-normal text-left pl-2">
                      Insurance Name
                    </th>
                    <th className="w-[60px] font-normal text-center">F.A</th>
                  </tr>
                </thead>
                <tbody>
                  {insuranceList.map((insurance) => {
                    return (
                      <tr
                        key={insurance.id}
                        className="text-black odd:bg-white even:bg-slate-50 border-b-2 h-8"
                      >
                        <td className="w-[294px] pl-2">{`${insurance.insuranceName}`}</td>
                        <td className="w-[60px] text-center">
                          <input
                            type="checkbox"
                            name={insurance.id}
                            checked={insurance.forApproval}
                            readOnly
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <ul className="ml-2 text-[12px]">
            <li>Legend:</li>
            <li className="ml-2">
              <label>F.A - For Approval </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InsuranceList;
