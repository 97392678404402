import React from "react";
import ViewHistoryDetails from "../../components/ExternalSources/ViewHistoryDetails";

const ViewHistoryDetailsPage = () => {
  return (
    <div>
      <ViewHistoryDetails />
    </div>
  );
};

export default ViewHistoryDetailsPage;
