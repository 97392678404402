import React from "react";
import { comingSoonAlert } from "../UI/alert";
import { SPCModalType } from "../UI/interface";

const SPCModal: React.FC<SPCModalType> = ({ isVisible, onClose }) => {
  if (!isVisible) return null;
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
      onClick={() => onClose()}
    >
      <div className="bg-blue-300 w-[300px] rounded-md">
        <div className="py-6 px-4 text-center text-white">
          <button
            className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]"
            onClick={comingSoonAlert}
          >
            Strategic Planning List
          </button>
          <button
            className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]"
            onClick={comingSoonAlert}
          >
            Program 7 Parking 2
          </button>
          <button
            className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]"
            onClick={comingSoonAlert}
          >
            Program 7 Parking 3
          </button>
          <button
            className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]"
            onClick={comingSoonAlert}
          >
            Program 7 Parking 4
          </button>
          <button
            className="w-full py-2 px-6 mt-10 rounded-md bg-[#13547a]"
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SPCModal;
