import { useState, useEffect } from "react";
import { Form } from "react-router-dom";

import BackButton from "../../UI/BackButton";
import { tokenLoader } from "../../UI/auth";
import Spinner from "../../UI/Spinner";
import { ILastAdmitList } from "../../UI/interface";
import moment from "moment-timezone";

const ExternalSourcesLastAdmitList = () => {
  const [admitList, setAdmitList] = useState<ILastAdmitList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const listOfAdmit = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/externalsources/lastadmit`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await response.json();
      setAdmitList(jsonResponse);
      setIsLoading(false);
    };
    listOfAdmit();
  }, []);

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl md:max-w-xl ">
        {/* Header */}
        <div className="relative">
          <BackButton />
          <div>
            <h1 className="text-center text-[30px] mt-[-40px]">Last Admit</h1>
          </div>
        </div>

        <Form>
          <div className="mt-3 md:mt-7 md:m-5 p-2 border-2 rounded-md">
            <div className="h-[700px] mt-2 md:h-[850px] bg-white overflow-scroll">
              <table className="w-full">
                <thead className="bg-blue-900 text-left h-8 sticky top-0">
                  <tr className="">
                    <th className="font-normal pl-1">Facility</th>
                  </tr>
                </thead>
                <tbody className="text-black">
                  {admitList.map((LastAdmitList) => {
                    return (
                      <tr
                        key={LastAdmitList.EXTERNAL_REFERRAL_SITE}
                        className="odd:bg-white even:bg-slate-50 border-b-2"
                      >
                        <td className="list-none px-2">
                          <ul>
                            <li>{`${LastAdmitList.RFE_FACILITY_NAME}`}</li>
                            <li className="flex justify-around ">
                              <div className="justify-items-start">
                                <label className="justify-items-start ml-[-25px] md:ml-[-67px]">SOC Date:</label>
                                <label className="ml-3">
                                  {`${moment(LastAdmitList.SOC_DATE)
                                    .tz("UTC")
                                    .format("MM/DD/YYY")}`}
                                </label>
                              </div>
                              <div >
                                <label className="align-middle">Admit</label>
                                <input
                                  className="h-5 w-5 cursor-pointer ml-3 align-middle"
                                  type="checkbox"
                                  name={LastAdmitList.EXTERNAL_REFERRAL_SITE}
                                  checked={LastAdmitList.IsAdmitted}
                                  readOnly
                                />
                              </div>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Form>
        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

export default ExternalSourcesLastAdmitList;
