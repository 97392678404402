import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

import { SignatureProps } from "./interface/ModalInterface";

const Signature: React.FC<SignatureProps> = ({
  isVisible,
  onClose,
  onSubmitSignature,
}) => {
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);

  if (!isVisible) return null;

  const handleClear = () => {
    signatureRef.current?.clear();
    setIsSignatureDrawn(false);
  };

  const handleSubmit = () => {
    const dataUrl = signatureRef.current?.toDataURL();
    if (dataUrl) {
      onSubmitSignature(dataUrl);
      onClose();
    } else {
      console.error("No Signature to submit");
    }
  };

  const handleSignatureChange = () => {
    setIsSignatureDrawn(!!signatureRef.current?.isEmpty() === false);
  };

  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center">
        <div className="bg-blue-300 w-[450px] rounded-md ">
          <div className="py-6 px-4 text-center text-white">
            <h2 className="mb-3">Signature</h2>
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{
                width: 400,
                height: 200,
                className: "signature-canvas",
              }}
              onEnd={handleSignatureChange}
            />
            <button
              className="w-[100px] mt-4 py-2 px-6 rounded-md bg-[#13547a]"
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              className={`w-[100px] ml-4 py-2 px-6 rounded-md ${
                isSignatureDrawn
                  ? "bg-[#13547a]"
                  : "bg-gray-400 pointer-events-none"
              }`}
              onClick={handleSubmit}
              disabled={!isSignatureDrawn}
            >
              Save
            </button>
            <button
              className="w-[100px] ml-4 py-2 px-6 rounded-md bg-[#13547a]"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signature;