import React from "react";
import ExternalSourcesLastAdmitList from "../../components/ExternalSources/ESourcesLastAdmitList";


const LastAdmitListPage = () => {
  return (
    <div>
      <ExternalSourcesLastAdmitList/>
    </div>
  );
};

export default LastAdmitListPage;
