import { Form } from "react-router-dom";

import BackButton from "../../UI/BackButton";

const ViewHistoryDetails = () => {
  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Activity - Remarks</h1>
          </div>
        </div>
        <Form>
          <div className="mt-6 p-2 border-2 rounded-md">
            <ul>
              <li>
                <label>Date</label>
                <input
                  type="date"
                  className="ml-[60px] pl-2 mt-4 h-8 rounded-md text-black"
                />
              </li>
              <li className="mt-4">
                <label>Remakrs</label>
                <textarea
                  name=""
                  id=""
                  rows={12}
                  className="w-full p-2 mt-4 mb-[230px] rounded-md text-black"
                ></textarea>
              </li>
            </ul>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ViewHistoryDetails;
