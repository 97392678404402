import { Form, Link } from "react-router-dom";

import Button from "../../UI/button";

import { rankingList } from "../types";
import BackButton from "../../UI/BackButton";

type Props = {
  RankingList: rankingList[];
};

const SPCRankingList = (props: Props) => {
  const { RankingList } = props;

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[25px] mt-[-35px]">SP Ranking List - Top 20</h1>
          </div>
        </div>
        <Form>
          <div className="mt-8 p-2 border-2 rounded-md">
            <div className="mt-2 mb-2 h-[630px] bg-white">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#13457a] h-8 text-left">
                    <th className="font-normal w-[60px] pl-2">Rank</th>
                    <th className="font-normal ">Facility Name</th>
                  </tr>
                </thead>
                <tbody>
                  {RankingList.map((RankingData) => {
                    return (
                      <tr
                        key={RankingData.id}
                        className="odd:bg-white even:bg-slate-200 border-b-2 hover:bg-blue-400 active:bg-blue-300 focus:ring focus:ring-blue-400"
                      >
                        <td className="text-center text-black">{`${RankingData.rank}`}</td>
                        <td className="px-2 text-black list-none mt-2">
                          <li className="mt-2">
                            <label>{`${RankingData.facilityname}`}</label>
                          </li>
                          <li className="mt-2 mb-2">
                            <label>{`${RankingData.COPCode}`}</label>
                            <label className="ml-16">{`${RankingData.type}`}</label>
                          </li>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <Button className="w-[100px] mt-4">Details</Button>
            <Link to="/StrategicPlanningList/Ranking/Add">
              <Button className="w-[100px] ml-3">Add</Button>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SPCRankingList;
