import { Text, View, Image } from "@react-pdf/renderer";
import moment from "moment-timezone";

import css from "./ViewMer.Style";
import testPhoto from "./hhrglogo.png";
import { ITableViewMer } from "../../../UI/interface";

const HeaderPdf: React.FC<ITableViewMer> = ({ tableData }) => {

  if (tableData.length === 0) {
    return null;
  }

  const headerData = tableData[0];
  const currentDate = new Date();

  return (
    <View>
      <View style={css.header}>
        <Image src={testPhoto} style={css.image} />
        <View style={{ flexDirection: "column", marginLeft: 12 }}>
          <Text>{headerData.agencyName}</Text>
          <View style={css.row}>
            <Text>Marketer Name: </Text>
            <Text style={{ marginLeft: 5 }}>{headerData.marketerName}</Text>
          </View>
          <View style={css.row}>
            <Text>Extracted Date: </Text>
            <Text style={{ marginLeft: 7 }}>
              {moment(currentDate).tz("UTC").format("MMM DD, YYYY")}
            </Text>
          </View>
          <View style={css.row}>
            <Text>Cut Off Period: </Text>
            <Text style={{ marginLeft: 10 }}>{headerData.cutoffDate}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default HeaderPdf;
