import React from "react";
import InsuranceList from "../../components/More/InsuranceList";
import { insuranceList } from "../../components/types";

const InsuranceListPage = () => {
  return (
    <div>
      <InsuranceList insuranceList={insuranceList} />
    </div>
  );
};

export default InsuranceListPage;
