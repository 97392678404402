import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Button from "../../../UI/button";
import { FormContext } from "./FacilityForm";
import { tokenLoader } from "../../../UI/auth";
import { IState } from "../../../UI/interface";
import { showErrorAlert } from "../../../UI/alert";

const AddressFacility = () => {
  const {
    formData,
    setFormData,
    goToPreviousStep,
    goToNextStep,
    handleChange,
  } = useContext(FormContext);

  const [getState, setGetState] = useState<IState[]>([]);

  // Fetch state data
  useEffect(() => {
    const fetchState = async () => {
      const token = tokenLoader();
      const stateResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/rfe/usstates`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await stateResponse.json();
      setGetState(jsonResponse);
    };
    fetchState();
  }, []);

  const handleStateValueChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNextClick = () => {
    if (!formData.RFE_ADDRESS.trim())
      return showErrorAlert("Please input Address", "Address is Empty");
    if (!formData.RFE_CITY)
      return showErrorAlert("Please input City", "City is Empty");
    if (!formData.RFE_STATE_CODE)
      return showErrorAlert("Please select State code", "State code is Empty");
    if (!formData.RFE_ZIP_CODE)
      return showErrorAlert("Please input ZIP code", "ZIP code is Empty");
    goToNextStep();
  };

  return (
    <div>
      <div className="mt-8 p-2 border-2 rounded-md">
        <ul>
          <li className="mt-4">
            <label className="align-top sm-max:block" htmlFor="address">
              Address
            </label>
            <textarea
              className="w-[257px] ml-[40px] md:w-[609px] lg:w-[435px] pl-2 rounded-md text-black sm-max:w-full sm-max:ml-0"
              rows={4}
              id="address"
              name="RFE_ADDRESS"
              value={formData.RFE_ADDRESS}
              onChange={handleChange}
            ></textarea>
          </li>

          <li className="mt-4">
            <label className="sm-max:block" htmlFor="city">
              City
            </label>
            <input
              id="city"
              type="text"
              name="RFE_CITY"
              className="w-[257px] ml-[70px] md:w-[609px] lg:w-[435px] pl-2 h-8 rounded-md text-black sm-max:w-full sm-max:ml-0"
              value={formData.RFE_CITY}
              onChange={handleChange}
            />
          </li>

          <li className="mt-4">
            <label className="sm-max:block" htmlFor="state">
              State
            </label>
            <select
              className="w-[150px] ml-[62px] md:w-[300px] lg:w-[200px] pl-2 h-8 rounded-md text-black sm-max:w-full sm-max:ml-0"
              id="state"
              name="RFE_STATE_CODE"
              value={formData.RFE_STATE_CODE}
              onChange={handleStateValueChange}
            >
              <option value="state"></option>
              {getState.map((state) => (
                <option
                  key={state.TRAN_OPTION_CODE}
                  value={state.TRAN_OPTION_CODE}
                >
                  {state.TRAN_TRANSLATED_OP}
                </option>
              ))}
            </select>
          </li>

          <li className="mt-4">
            <label className="sm-max:block" htmlFor="zipCode">
              Zip Code
            </label>
            <input
              id="zipCode"
              type="text"
              name="RFE_ZIP_CODE"
              maxLength={5}
              className="w-[150px] ml-[33px] md:w-[300px] lg:w-[200px] pl-2 h-8 rounded-md text-black sm-max:w-full sm-max:ml-0"
              value={formData.RFE_ZIP_CODE}
              onChange={handleChange}
            />
          </li>

          <li className="mt-4">
            <label className="sm-max:block" htmlFor="phone1">
              Phone 1
            </label>
            <input
              id="phone1"
              type="text"
              name="RFE_PHONE_1"
              className="w-[257px] ml-[39px] md:w-[609px] lg:w-[435px] pl-2 h-8 rounded-md text-black sm-max:w-full sm-max:ml-0"
              value={formData.RFE_PHONE_1}
              onChange={handleChange}
            />
          </li>

          <li className="mt-4">
            <label className="sm-max:block" htmlFor="phone2">
              Phone 2
            </label>
            <input
              id="phone2"
              type="text"
              name="RFE_PHONE_2"
              className="w-[257px] ml-[39px] md:w-[609px] lg:w-[435px] pl-2 h-8 rounded-md text-black sm-max:w-full sm-max:ml-0"
              value={formData.RFE_PHONE_2}
              onChange={handleChange}
            />
          </li>

          <li className="mt-4">
            <label className="sm-max:block" htmlFor="phone3">
              Phone 3
            </label>
            <input
              id="phone3"
              type="text"
              name="RFE_PHONE_3"
              className="w-[257px] ml-[39px] md:w-[609px] lg:w-[435px] pl-2 h-8 rounded-md text-black sm-max:w-full sm-max:ml-0"
              value={formData.RFE_PHONE_3}
              onChange={handleChange}
            />
          </li>

          <li className="mt-4">
            <label className="sm-max:block" htmlFor="fax">
              Fax
            </label>
            <input
              id="fax"
              type="text"
              name="RFE_FAX"
              className="w-[257px] ml-[74px] md:w-[609px] lg:w-[435px] pl-2 h-8 rounded-md text-black sm-max:w-full sm-max:ml-0"
              value={formData.RFE_FAX}
              onChange={handleChange}
            />
          </li>

          <li className="mt-4">
            <label className="sm-max:block" htmlFor="geo-x">
              Geo-X
            </label>
            <input
              id="geo-x"
              type="text"
              name="GEO_LATITUDE"
              className="w-[257px] ml-[52px] md:w-[609px] lg:w-[435px] pl-2 h-8 rounded-md text-black sm-max:w-full sm-max:ml-0"
              value={formData.GEO_LATITUDE}
              onChange={handleChange}
              disabled
            />
          </li>

          <li className="mt-4 mb-4">
            <label className="sm-max:block" htmlFor="geo-y">
              Geo-Y
            </label>
            <input
              id="geo-y"
              type="text"
              name="GEO_LONGITUDE"
              className="w-[257px] ml-[53px] md:w-[609px] lg:w-[435px] pl-2 h-8 rounded-md text-black sm-max:w-full sm-max:ml-0"
              value={formData.GEO_LONGITUDE}
              onChange={handleChange}
              disabled
            />
          </li>
        </ul>
      </div>
      <div className="mt-6 text-right">
        <Link to="/ExternalReferralSourcesList">
          <Button className="w-[100px]">Cancel</Button>
        </Link>
        <Button
          type="button"
          className="w-[100px] ml-[12px]"
          onClick={goToPreviousStep}
        >
          Previous
        </Button>
        <Button
          type="button"
          className="w-[100px] ml-[12px]"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AddressFacility;
