import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";

import Back from "../../../image/back.png";
import { tokenLoader } from "../../../UI/auth";
import Spinner from "../../../UI/Spinner";
import {
  successAlert,
  showErrorAlert,
  confirmationAlert,
  warningAlert,
  alertLoading,
  closeAlert,
} from "../../../UI/alert";
import Signature from "../../../UI/signature";
import { combineImages } from "./combinedImage";
import Button from "../../../UI/button";
import ReceiptForm from "./ReceiptForm";
import { IAddReceipt } from "../../../UI/interface";

function dataURItoBlob(dataURI: any) {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

const AttachReceipt: React.FC = () => {
  const { VisitCTR, fullName } = useParams();
  const expenseCodeInputRef = useRef<HTMLSelectElement>(null);
  const amountInputRef = useRef<HTMLInputElement>(null);
  const paymentTypeInputRef = useRef<HTMLSelectElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const remarksInputRef = useRef<HTMLTextAreaElement>(null);
  const [referralSiteId, setReferralSiteId] = useState<string | null>(null);
  const [receiptImage, setReceiptImage] = useState<File | null>(null);
  const [post, setPost] = useState<IAddReceipt[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModalSign, setShowModalSign] = useState(false);
  const [signatureImage, setSignatureImage] = useState<string>("");
  const [attestationChecked, setAttestationChecked] = useState(false);
  const openModalHandlerSign = () => setShowModalSign(true);
  const closeModalHandlerSign = () => setShowModalSign(false);
  const navigate = useNavigate();

  //fetch Details
  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/meetings/visitdetails/${VisitCTR}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const jsonResponse = await response.json();
      const referralSiteId = jsonResponse[0];
      setReferralSiteId(referralSiteId.ReferralSiteID.toString());
      setIsLoading(false);
    };
    fetchDetails();
  }, [VisitCTR]);

  useEffect(() => {
    if (attestationChecked) {
      openModalHandlerSign();
    } else {
      closeModalHandlerSign();
    }
  }, [attestationChecked]);

  const imageClickHandler = () => {
    imageInputRef.current!.click();
    setIsLoading(true);
  };

  const selectImageHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setReceiptImage(file);
      setIsLoading(false);
    }
  };

  const cancelClickHandler = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const result = await confirmationAlert(
      "You have unsaved changes. Are you sure you want to exit?",
      "Exit Confirmation"
    );
    if (result.isConfirmed) {
      navigate(-1);
    }
  };

  //Submit the Receipt
  const addReceiptSubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      //Validation
      if (!expenseCodeInputRef.current!.value)
        return warningAlert(
          "Please select Expense Code",
          "Expense Code is Empty"
        );
      if (!amountInputRef.current!.value)
        return warningAlert("Please input an Amount", "Amount is Empty");
      if (!paymentTypeInputRef.current!.value)
        return warningAlert(
          "Please select Payment Type",
          "Payment Type is Empty"
        );
      if (!receiptImage)
        return warningAlert(
          "click gallery to attach you receipt",
          "Receipt Image is Empty"
        );
      if (!signatureImage)
        return warningAlert(
          "Click the attestation box to add Signature",
          "Signature is Empty"
        );

      const formData = new FormData();
      formData.append(`expenseCode`, expenseCodeInputRef.current!.value);
      formData.append(`cost`, amountInputRef.current!.value);
      formData.append(`paymentType`, paymentTypeInputRef.current!.value);
      formData.append(`remarks`, remarksInputRef.current!.value);
      if (referralSiteId) {
        formData.append("referralSiteId", referralSiteId);
      }

      if (receiptImage) {
        const combinedImageData = await combineImages(
          receiptImage,
          signatureImage,
          fullName || ""
        );
        const combinedImageBlob = dataURItoBlob(combinedImageData);
        formData.append(`receiptImage`, combinedImageBlob, "receiptImage.png");
      }

      alertLoading("Submitting", "Please wait ...");
      const token = tokenLoader();
      const addReceipt = await fetch(
        `${process.env.REACT_APP_API_URL}/marketervisit/addreceipt/${VisitCTR}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        }
      );

      setAttestationChecked(false);

      if (!addReceipt.ok)
        return showErrorAlert(
          "The server encountered an internal error, Please contact the server administrator",
          "Internal Server Error"
        );
      if ("saveReceipt") {
        closeAlert();
        successAlert("Receipt added Successfully");
        navigate(-1);
      }

      const responseAddReceipt = await addReceipt.json();
      setPost([...post, responseAddReceipt]);
    } catch (error) {
      showErrorAlert('Something went wrong, Please try again ...');
      return error;
    }
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div>
          <div>
            <button onClick={cancelClickHandler}>
              <img
                id="back"
                src={Back}
                className="h-10 md:-14 rounded-full"
                alt="Back Button"
              />
            </button>
          </div>
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Add Receipt</h1>
          </div>
        </div>
        <Form onSubmit={addReceiptSubmitHandler}>
          <ReceiptForm
            expenseCodeInputRef={expenseCodeInputRef}
            amountInputRef={amountInputRef}
            paymentTypeInputRef={paymentTypeInputRef}
            imageInputRef={imageInputRef}
            remarksInputRef={remarksInputRef}
            receiptImage={receiptImage}
            signatureImage={signatureImage}
            attestationChecked={attestationChecked}
            selectImageHandler={selectImageHandler}
            imageClickHandler={imageClickHandler}
            cancelClickHandler={cancelClickHandler}
            openModalHandlerSign={openModalHandlerSign}
            closeModalHandlerSign={closeModalHandlerSign}
            setSignatureImage={setSignatureImage}
            showModalSign={showModalSign}
          />
          <div>
            <ul className="flex flex-wrap justify-around">
              <li>
                <Button
                  className="flex-1 w-[150px] md:w-[160px] lg:w-[131px] lg:px-3 mt-4 bg-gray-500 "
                  disabled
                >
                  Camera
                </Button>
              </li>
              <li>
                <Button
                  className="flex-1 w-[150px] md:w-[160px] lg:w-[131px] lg:px-3 mt-4"
                  onClick={imageClickHandler}
                  disabled={isLoading}
                >
                  Gallery
                </Button>
              </li>
              <li>
                <Button
                  className="flex-1 w-[150px] md:w-[160px] lg:w-[131px] lg:px-3 mt-4"
                  onClick={cancelClickHandler}
                >
                  Cancel
                </Button>
              </li>
              <li>
                <Button
                  id="saveReceipt"
                  type="submit"
                  className="flex-1 w-[150px] md:w-[160px] lg:w-[131px] lg:px-3 mt-4"
                >
                  Save
                </Button>
              </li>
            </ul>
          </div>
        </Form>
        {isLoading && <Spinner />}
        <Signature
          isVisible={showModalSign}
          onClose={closeModalHandlerSign}
          onSubmitSignature={(imageData) => setSignatureImage(imageData)}
        />
      </div>
    </div>
  );
};

export default AttachReceipt;
