import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";
import { tokenLoader } from "../../UI/auth";
import Spinner from "../../UI/Spinner";
import {
  successAlert,
  warningAlert,
  showErrorAlert,
  alertLoading,
  closeAlert,
} from "../../UI/alert";

import {
  ISelectFacilityList,
  ISelectContactPerson,
  ISelectActivity,
  ICreateVisit,
} from "../../UI/interface";

const Visits: React.FC = () => {
  const selectFacilityInputRef = useRef<HTMLSelectElement>(null);
  const facilityNameInputRef = useRef<HTMLInputElement>(null);
  const contactPersonInputRef = useRef<HTMLSelectElement>(null);
  const activityInputRef = useRef<HTMLSelectElement>(null);
  const addressInputRef = useRef<HTMLTextAreaElement>(null);
  const cityInputRef = useRef<HTMLInputElement>(null);
  const stateCodeInputRef = useRef<HTMLInputElement>(null);
  const zipCodeInputRef = useRef<HTMLInputElement>(null);
  const BOCodeInputRef = useRef<HTMLInputElement>(null);
  const remarksInputRef = useRef<HTMLTextAreaElement>(null);
  const geolocationInputRef = useRef<HTMLInputElement>(null);

  const [selectFacilityList, setSelectFacilityList] = useState<
    ISelectFacilityList[]
  >([]);
  const [facilityOption, setFacilityOption]: any = useState<
    ISelectFacilityList | undefined
  >();
  const [selectContact, setSelectContact] = useState<ISelectContactPerson[]>(
    []
  );
  const [selectActivity, setSelectActivity] = useState<ISelectActivity[]>([]);
  const [post, setPost] = useState<ICreateVisit[]>([]);
  const [isEditable, setIsEditable] = useState(true);
  const [punchState, setPunchState] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //check the latest activity and punch state
  useEffect(() => {
    const checkLatestActivity = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/marketer/latestactivity`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await response.json();
      if (jsonResponse.latestPunchState === 1) {
        setIsEditable(false);
      }
      setPunchState(jsonResponse.latestPunchState);
      setIsLoading(false);
    };
    checkLatestActivity();
  }, []);

  // list of Facilities
  useEffect(() => {
    const fetchFacility = async () => {
      const token = tokenLoader();
      const responseFacility = await fetch(
        `${process.env.REACT_APP_API_URL}/meetings/facility`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const jsonResponse = await responseFacility.json();
      setSelectFacilityList(jsonResponse);
    };

    fetchFacility();
  }, []);

  // Activity List
  useEffect(() => {
    const fetchActivity = async () => {
      const token = tokenLoader();
      const responseActivity = await fetch(
        `${process.env.REACT_APP_API_URL}/meetings/activity`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const jsonResponseActivity = await responseActivity.json();
      setSelectActivity(jsonResponseActivity);
    };
    fetchActivity();
  }, []);

  //Contact List
  useEffect(() => {
    if (facilityOption) {
      const fetchContact = async () => {
        const token = tokenLoader();
        const responseContact = await fetch(
          `${process.env.REACT_APP_API_URL}/meetings/${facilityOption.RFE_ID}`,
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
        const jsonResponseContact = await responseContact.json();
        setSelectContact(jsonResponseContact);
      };
      fetchContact();
    }
  }, [facilityOption]);

  // handle every change in the Select Facility input field
  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const facilityListOption = selectFacilityList.find(
      (option) => option.RFE_ID === parseInt(event.target.value)
    );
    setFacilityOption(facilityListOption);

    if (selectedValue === "0") {
      facilityNameInputRef.current!.value = "";
      setSelectContact([]);
      activityInputRef.current!.value = "";
      addressInputRef.current!.value = "";
      cityInputRef.current!.value = "";
      stateCodeInputRef.current!.value = "";
      zipCodeInputRef.current!.value = "";
      BOCodeInputRef.current!.value = "";
      remarksInputRef.current!.value = "";
      geolocationInputRef.current!.checked = false;
    }
  };

  // Link Multiple input field
  useEffect(() => {
    if (facilityOption) {
      facilityNameInputRef.current!.value = facilityOption.RFE_FACILITY_NAME;
      addressInputRef.current!.value = facilityOption.RFE_ADDRESS;
      cityInputRef.current!.value = facilityOption.RFE_CITY;
      stateCodeInputRef.current!.value = facilityOption.RFE_STATE_CODE;
      zipCodeInputRef.current!.value = facilityOption.RFE_ZIP_CODE;
      BOCodeInputRef.current!.value = facilityOption.RFE_BO_CODE.trim();
    }
  }, [facilityOption]);
  //check geolocation state
  const getGeolocation = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => reject(error)
      );
    });
  };

  const checkGeolocationState = async () => {
    const result = await navigator.permissions
      .query({ name: "geolocation" })
      .then((result) => {
        console.log("result", result.state);
        return result.state;
      });
    return result;
  };

  const meetingsVisitSubmitHandler = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    alertLoading("Submitting", "Please wait ...");
    let coordinates: any;
    const geoState = await checkGeolocationState();
    if (geoState === "prompt") {
      coordinates = await getGeolocation();
    } else if (geoState === "denied") {
      closeAlert();
      warningAlert(
        "Geolocation disabled on your device. Please enable it, If you need help contact your system Admin",
        "Geolocation Disabled Alert"
      );
    } else {
      coordinates = await getGeolocation();
    }

    const meetingsVisitData: ICreateVisit = {
      RFE_ID: parseInt(selectFacilityInputRef.current!.value),
      EstablishmentName: facilityNameInputRef.current!.value,
      ContactID: contactPersonInputRef.current!.value,
      Activity: activityInputRef.current!.value,
      EstablishmentAddress: addressInputRef.current!.value,
      EstablishmentCity: cityInputRef.current!.value,
      EstablishmentState: stateCodeInputRef.current!.value,
      EstablishmentZIP: zipCodeInputRef.current!.value,
      RFE_BO_CODE: BOCodeInputRef.current!.value,
      Activity_Description: remarksInputRef.current!.value,
      Geolocation: geolocationInputRef.current!.checked,
      geoLatitude: coordinates.coords.latitude,
      geoLongitude: coordinates.coords.longitude,
    };

    if (!meetingsVisitData.EstablishmentName.trim()) {
      return warningAlert("Please input Facility Name", "Facility Name Empty");
    } else if (meetingsVisitData.EstablishmentName.length > 50) {
      return warningAlert("Facility name cannot exceed 50 characters", "Facility Name");
    }
    if (meetingsVisitData.RFE_ID > 1 && !meetingsVisitData.ContactID) {
      return warningAlert(
        "Please Select Contact Person(If no contact person listed, please create one)",
        "Contact Person Empty"
      );
    }
    if (!meetingsVisitData.Activity) {
      return warningAlert("Please Select Activity", "Activity Empty");
    }

    if (punchState === 1) {
      return showErrorAlert(
        "You must Punch Out before Punching In.",
        "Punch Out Required"
      );
    }

    try {
      const token = tokenLoader();
      const responsePunchIn = await fetch(
        `${process.env.REACT_APP_API_URL}/marketer/punch-in`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(meetingsVisitData),
        }
      );

      if (!responsePunchIn.ok)
        return showErrorAlert(
          "The server encountered an internal error, Please contact the server administrator",
          "Internal Server Error"
        );

      if ("punchInButton") {
        closeAlert();
        successAlert(
          `You checked-in at ${meetingsVisitData.EstablishmentName}`
        );
        setPunchState(1);
        setIsEditable(false);
      }

      const createdPost = await responsePunchIn.json();
      setPost([...post, createdPost]);
    } catch (error: any) {
      showErrorAlert(
        // "There is problem with your internet conn, Please try again"
        `${error.message}`
      );
      return error;
    }
  };

  //Punch Out Submitter
  const punchOutHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    alertLoading("Punching out", "Please wait ...");

    if (!punchState || isLoading)
      return showErrorAlert(
        "You must Punch In before Punching Out.",
        "Punch In Required"
      );

    const coordinates: any = await getGeolocation();

    // Form Content submitted
    const meetingsVisitData: ICreateVisit = {
      RFE_ID: parseInt(selectFacilityInputRef.current!.value),
      EstablishmentName: facilityNameInputRef.current!.value,
      ContactID: contactPersonInputRef.current!.value,
      Activity: activityInputRef.current!.value,
      EstablishmentAddress: addressInputRef.current!.value,
      EstablishmentCity: cityInputRef.current!.value,
      EstablishmentState: stateCodeInputRef.current!.value,
      EstablishmentZIP: zipCodeInputRef.current!.value,
      RFE_BO_CODE: BOCodeInputRef.current!.value,
      Activity_Description: remarksInputRef.current!.value,
      Geolocation: geolocationInputRef.current!.checked,
      geoLatitude: coordinates.coords.latitude,
      geoLongitude: coordinates.coords.longitude,
    };

    try {
      const token = tokenLoader();
      const responsePunchIn = await fetch(
        `${process.env.REACT_APP_API_URL}/marketer/punch-out`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(meetingsVisitData),
        }
      );

      if (!responsePunchIn.ok)
        return showErrorAlert(
          "The server encountered an internal error, Please contact the server administrator",
          "Internal Server Error"
        );

      if ("punchOutButton") {
        closeAlert();
        successAlert(
          "Have a safe trip to your next destination! Thank you for checking out!"
        );
        setPunchState(0);

        selectFacilityInputRef.current!.value = "0";
        facilityNameInputRef.current!.value = "";
        setSelectContact([]);
        activityInputRef.current!.value = "";
        addressInputRef.current!.value = "";
        cityInputRef.current!.value = "";
        stateCodeInputRef.current!.value = "";
        zipCodeInputRef.current!.value = "";
        BOCodeInputRef.current!.value = "";
        remarksInputRef.current!.value = "";
        geolocationInputRef.current!.value = "";

        setIsEditable(true);
        return;
      }

      const createdPost = await responsePunchIn.json();
      setPost([...post, createdPost]);
    } catch (error: any) {
      showErrorAlert(
        // "There is problem with your internet connection, Please try again"
        `${error.message}`
      );
      return error;
    }
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div className="relative">
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Visits</h1>
          </div>
        </div>
        <Form onSubmit={meetingsVisitSubmitHandler}>
          <div className="mt-3 md:mt-7 md:m-5 rounded-md border-2 p-2">
            <ul>
              <li>
                <label htmlFor="SelectFacility">Select Facility</label>
                <select
                  className="w-full px-1 mt-3 h-8 rounded-md text-black cursor-pointer"
                  id="SelectFacility"
                  ref={selectFacilityInputRef}
                  value={facilityOption?.value}
                  onChange={changeHandler}
                  disabled={!isEditable}
                >
                  <option value={0}></option>
                  {selectFacilityList.map((FacilityList) => (
                    <option
                      key={FacilityList.RFE_ID}
                      value={FacilityList.RFE_ID}
                    >
                      {FacilityList.RFE_FACILITY_NAME}
                    </option>
                  ))}
                </select>
              </li>
              <li className="mt-3">
                <label htmlFor="facilityName">Facility Name</label>
                <input
                  id="facilityName"
                  className="w-full px-2 mt-3 h-8 rounded-md text-black "
                  type="text"
                  ref={facilityNameInputRef}
                  placeholder="Unlisted Facility"
                  disabled={!isEditable}
                />
              </li>
              <li className="mt-3">
                <label htmlFor="contactPerson">Contact Person</label>
                <select
                  id="contactPerson"
                  className="w-full px-2 mt-3 h-8 rounded-md text-black cursor-pointer"
                  ref={contactPersonInputRef}
                  value={facilityOption?.value}
                  disabled={!isEditable}
                >
                  <option value=""></option>
                  {selectContact.map((contactPerson) => (
                    <option
                      key={contactPerson.ContactID}
                      value={contactPerson.ContactID}
                    >
                      {contactPerson.Contact_Fullname}
                    </option>
                  ))}
                </select>
              </li>
              <li className="mt-3">
                <label htmlFor="activity">Activity</label>
                <select
                  className="w-full px-1 mt-3 h-8 rounded-md text-black cursor-pointer"
                  id="activity"
                  ref={activityInputRef}
                  value={facilityOption?.value}
                  disabled={!isEditable}
                >
                  <option value=""></option>
                  {selectActivity.map((activityOption) => (
                    <option
                      key={activityOption.TRAN_OPTION_CODE}
                      value={activityOption.TRAN_OPTION_CODE}
                    >
                      {activityOption.TRAN_TRANSLATED_OP}
                    </option>
                  ))}
                </select>
              </li>
              <li className="mt-2">
                <label htmlFor="Address">Address</label>
                <textarea
                  className="w-full px-2 mt-3 rounded-md text-black"
                  id="Address"
                  rows={4}
                  ref={addressInputRef}
                  disabled={!isEditable}
                  readOnly
                ></textarea>
              </li>
            </ul>
            <div className="columns-4 mt-2 text-sm">
              <ul>
                <li>
                  <label htmlFor="City">City</label>
                  <input
                    className="w-[77px] md:w-[150px] lg:w-[100px] px-2 h-8 mt-2 rounded-md text-black flex"
                    type="text"
                    id="City"
                    ref={cityInputRef}
                    disabled={!isEditable}
                    readOnly
                  />
                </li>
                <li>
                  <label htmlFor="StateCode">State Code</label>
                  <input
                    className="w-[77px] md:w-[150px] lg:w-[100px] px-2 h-8 mt-2 rounded-md text-black flex"
                    type="text"
                    id="StateCode"
                    ref={stateCodeInputRef}
                    disabled={!isEditable}
                    readOnly
                  />
                </li>
                <li>
                  <label htmlFor="ZipCode">Zip Code</label>
                  <input
                    className="w-[77px] md:w-[150px] lg:w-[100px] px-2 h-8 mt-2 rounded-md text-black flex"
                    type="text"
                    id="ZipCode"
                    ref={zipCodeInputRef}
                    disabled={!isEditable}
                    readOnly
                  />
                </li>
                <li>
                  <label htmlFor="BOCode">BO Code</label>
                  <input
                    className="w-[77px] md:w-[150px] lg:w-[100px] px-2 h-8 mt-2 rounded-md text-black flex"
                    type="text"
                    id="BOCode"
                    ref={BOCodeInputRef}
                    disabled={!isEditable}
                    readOnly
                  />
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <label htmlFor="Remarks">Remarks</label>
              <textarea
                className="w-full px-2 rounded-md mt-2 text-black"
                id="Remarks"
                rows={4}
                ref={remarksInputRef}
                disabled={!isEditable}
              ></textarea>
              <span>
                <input
                  className="mt-2 cursor-pointer mr-1"
                  type="checkbox"
                  value="yes"
                  id="geolocation"
                  ref={geolocationInputRef}
                  disabled
                />
                <label htmlFor="geolocation">No Geolocation</label>
              </span>
            </div>
          </div>
          <div className="mt-4 md:m-5 flex justify-end">
            <Button
              name="punchInButton"
              type="submit"
              disabled
              className={`${
                punchState ? "bg-gray-400" : "bg-[#1da9e9]"
              } w-[140px] mr-3`}
            >
              Punch In
            </Button>
            <Button
              name="punchOutButton"
              type="button"
              onClick={punchOutHandler}
              disabled
              className={`${
                punchState ? "bg-[#1da9e9]" : "bg-gray-400"
              } w-[140px] mr-3`}
            >
              Punch Out
            </Button>
          </div>
        </Form>
        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

export default Visits;
