import { Form, Link } from "react-router-dom";

import Button from "../../UI/button";

import { strategicPlanningList } from "../types";
import BackButton from "../../UI/BackButton";

type Props = {
  PlanningList: strategicPlanningList[];
};

const StrategicPlanningList = (props: Props) => {
  const { PlanningList } = props;

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Strategic Planning</h1>
          </div>
        </div>
        <Form>
          <div className="mt-8 p-2 border-2 rounded-md">
            <div className="mt-2 mb-2 h-[580px] bg-white">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#13457a] h-8">
                    <th className="font-normal text-left pl-2">
                      {/*TODO: kcgarcia.mh will be replace by ${user} */}
                      {`Strategic Planning Campaign by kcgarcia.mh`}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {PlanningList.map((planningList) => {
                    return (
                      <tr
                        key={planningList.id}
                        className="odd:bg-white even:bg-slate-200 border-b-2 hover:bg-blue-400 active:bg-blue-300 focus:outline-none 
                        focus:ring
                        focus:ring-blue-400 ..."
                      >
                        <td className="text-black list-none">
                          <li className="px-2 p-1">{`${planningList.titleName}`}</li>
                          <li className="px-2 pb-1">
                            <label>Start</label>
                            <label className="ml-3">{`${planningList.startDate}`}</label>
                            <label className="ml-[50px]">End</label>
                            <label className="ml-3">{`${planningList.endDate}`}</label>
                          </li>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-4">
            <Link to="">
              <Button className="w-[102px]">Details</Button>
            </Link>
            <Link to="/StrategicPlanningList/Add">
              <Button className="w-[102px] ml-3">Add</Button>
            </Link>
            <Link to="/StrategicPlanningList/Ranking">
              <Button className="w-[102px] ml-3">Ranking</Button>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default StrategicPlanningList;
