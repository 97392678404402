import React, { useRef } from "react";
import { Form, Link, useNavigate } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";

const SPCAdd: React.FC = () => {
  const strategicTitleInputRef = useRef<HTMLInputElement>(null);
  const effectiveDateInputRef = useRef<HTMLInputElement>(null);
  const endDateInputRef = useRef<HTMLInputElement>(null);
  const targetInputRef = useRef<HTMLInputElement>(null);
  const goalInputRef = useRef<HTMLTextAreaElement>(null);
  const whyInputRef = useRef<HTMLTextAreaElement>(null);

  const navigate = useNavigate();

  const SPCAddSubmitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const SPCDetailsData = {
      strategicTitle: strategicTitleInputRef.current!.value,
      effectiveDate: effectiveDateInputRef.current!.value,
      endDate: endDateInputRef.current!.value,
      target: targetInputRef.current!.value,
      goal: goalInputRef.current!.value,
      why: whyInputRef.current!.value,
    };

    console.log(SPCDetailsData);

    navigate("/StrategicPlanningList");
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Add - SCP</h1>
          </div>
        </div>
        <Form onSubmit={SPCAddSubmitHandler}>
          <div className="mt-8 p-2 border-2 rounded-md">
            <ul>
              <li className="mt-4">
                <label>Strategic Campaign Plan Title</label>
                <input
                  type="text"
                  className="w-full mt-4 h-8 rounded-md px-2 text-black"
                  ref={strategicTitleInputRef}
                />
              </li>
              <li>
                <ul className="flex mt-4 w-full">
                  <li className="w-[130px]">
                    <label>Effectivity Date</label>
                    <input
                      type="date"
                      className="w-[130px] mt-4 h-8 px-2 rounded-md text-black"
                      ref={effectiveDateInputRef}
                    />
                  </li>
                  <li className="w-[130px] ml-3">
                    <label>End Date</label>
                    <input
                      type="date"
                      className="w-[130px] mt-4 h-8 px-2 rounded-md text-black"
                      ref={endDateInputRef}
                    />
                  </li>
                  <li className=" w-[70px] md:w-[110px] ml-3">
                    <label>Target #</label>
                    <input
                      type="text"
                      className="w-[70px] md:w-[110px] mt-4 h-8 px-2 rounded-md text-black text-center"
                      ref={targetInputRef}
                    />
                  </li>
                </ul>
              </li>
              <li className="mt-4">
                <label>Goal</label>
                <textarea
                  rows={3}
                  className="w-full mt-4 px-2 rounded-md text-black"
                  ref={goalInputRef}
                ></textarea>
              </li>
              <li className="mt-4">
                <label>Why ?</label>
                <textarea
                  rows={3}
                  className="w-full mt-4 mb-4 px-2 rounded-md text-black"
                  ref={whyInputRef}
                ></textarea>
              </li>
            </ul>
          </div>
          <div className="mt-4">
            <Button type="submit" className="w-[100px]">
              Save
            </Button>
            <Link to=".." relative="path">
              <Button className="w-[100px] ml-3">Close</Button>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SPCAdd;
