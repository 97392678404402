import React from "react";
import { Link } from "react-router-dom";
import { comingSoonAlert } from "../UI/alert";

interface ModalType {
  isVisible: boolean;
  onClose: () => void;
}

const ExternalSourcesModal: React.FC<ModalType> = ({ isVisible, onClose }) => {
  
  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center"
      onClick={() => onClose()}
    >
      <div className="bg-blue-300 w-[300px] rounded-md">
        <div className="py-6 px-4 text-center text-white">
          <Link to="/ExternalReferralSourcesList">
            <button className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]">
              External Referral Sources List
            </button>
          </Link>
          <Link to="/ExternalSourcesLastAdmit">
            <button className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]">
              External Sources Last Admit
            </button>
          </Link>
            <button
              className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]"
            onClick={comingSoonAlert}>
              External Resources Dummy
            </button>
            <button className="w-full py-2 px-6 mt-4 rounded-md bg-[#13547a]" onClick={comingSoonAlert}>
              Joint Call Visit List
            </button>
          <button
            className="w-full py-2 px-6 mt-10 mb-4 rounded-md bg-[#13547a]"
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExternalSourcesModal;