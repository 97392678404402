import React from "react";
import ReceiptList from "../../components/Meetings/ReceiptList";

const ReceiptListPage = () => {
  return (
    <div>
      <ReceiptList />
    </div>
  );
};

export default ReceiptListPage;
