import React from "react";
import COP from "../../components/Performance/COP";

const COPPage = () => {
  return (
    <div>
      <COP />
    </div>
  );
};

export default COPPage;
