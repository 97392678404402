import React from "react";
import CreateViewLeaveForm from "../../components/More/CreateViewLeaveForm";

const CreateViewLeavePage = () => {
  return (
    <div>
      <CreateViewLeaveForm />
    </div>
  );
};

export default CreateViewLeavePage;
