import { Form } from "react-router-dom";

import BackButton from "../../UI/BackButton";

const SurveyForm = () => {
  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Pre Admission</h1>
          </div>
        </div>
        <Form>
          <div className="mt-8 p-2 border-2 rounded-md">
            <div>
              <ul className="flex justify-between px-2">
                <li>Summary</li>
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
              </ul>
            </div>
            <div className="mt-7 mb-[250px]">
              <ul>
                <li>
                  <label>Patient Reffered Starting January 11, 2021</label>
                  <select
                    name=""
                    id=""
                    className="w-full mt-4 h-8 px-2 rounded-md text-black"
                  >
                    <option value="referred"></option>
                    <option value="referred1">referred1</option>
                  </select>
                </li>
                <li className="mt-4">
                  <label>Agency</label>
                  <input
                    type="text"
                    className="w-full mt-4 h-8 px-2 rounded-md text-black"
                  />
                </li>
              </ul>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SurveyForm;
