import React, { useEffect, useRef, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import { FaUser, FaKey } from "react-icons/fa";

import Button from "../UI/button";
import Spinner from "../UI/Spinner";
import { showErrorAlert, successAlert, warningAlert } from "../UI/alert";

const LoginForm = () => {
  const userNameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.log(`DEV RC 1.4.2`);
  }, []);

  const loginSubmitHandler = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    const credentials = {
      userCode: userNameInputRef.current!.value,
      password: passwordInputRef.current!.value,
    };

    if (credentials.userCode === "") {
      showErrorAlert("Please input Username", "Username");
      return;
    } else if (credentials.password === "") {
      showErrorAlert("Please input Password", "Password");
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/login`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(credentials),
          mode: "cors",
        }
      );

      if (!response.ok) {
        showErrorAlert("Server Error, Something went wrong");
        return;
      }

      const jsonResponse = await response.json();
      const userToken = jsonResponse.access;
      localStorage.setItem("token", userToken);

      if (!userToken) {
        warningAlert("Invalid Username and/or Password");
      } else {
        successAlert(`Welcome Back ${credentials.userCode}`);
        return navigate("/");
      }
      setIsLoading(false);
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <div className="p-2 min-h-screen bg-gradient-to-t from-[#13547a] to-blue-400">
        <div className="sm:w-full md:w-96 p-6 m-auto mt-64 md:mt-96 lg:mt-60 bg-white rounded-md  sm: max-w-xl">
          <div className="relative">
            <span className="inline-flex items-center justify-center absolute text-4xl h-full w-10 text-[#1da9e9]">
              <FaUser />
            </span>
            <h1 className="text-3xl ml-10 font-semibold text-gray-400">
              User Login
            </h1>
          </div>

          <Form className="mt-4" onSubmit={loginSubmitHandler}>
            <div className="relative mb-2">
              <div className="inline-flex items-center justify-center absolute h-full w-10 text-gray-400">
                <FaUser />
              </div>
              <input
                id="username"
                name="username"
                type="text"
                className="w-full pl-10 py-2 text-black bg-white border-2 border-gray-200 rounded-md placeholder-gray-300"
                placeholder="user name"
                ref={userNameInputRef}
              />
            </div>

            <div className={`relative mb-2`}>
              <div className="inline-flex items-center justify-center absolute h-full w-10 text-gray-400">
                <FaKey />
              </div>
              <input
                id="password"
                name="password"
                type="password"
                className="w-full pl-10 py-2 text-black bg-white border-2 border-gray-200 rounded-md placeholder-gray-300"
                placeholder="password"
                ref={passwordInputRef}
              />
            </div>

            <div className="flex items-center justify-between mt-6">
              <Button type="submit">Login</Button>
            </div>
          </Form>
          {isLoading && <Spinner />}
        </div>
        <div className="mt-2">
          <h5 className="text-right text-white text-xs pr-2 md:w-96 m-auto">
            Version Beta
          </h5>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
