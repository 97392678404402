import React, { useContext } from "react";
import { Form, Link } from "react-router-dom";
import Button from "../../../UI/button";
import { FormContext } from "./FacilityForm";
import Spinner from "../../../UI/Spinner";

const OthersFacility = () => {
  const { formData, handleChange, handleSubmit, goToPreviousStep, isLoading } =
    useContext(FormContext);

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <div className="mt-8 p-2 border-2 rounded-md">
          <ul>
            <li className="mt-4">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="text"
                name="RFE_EMAIL"
                className="w-full mt-4 h-8 px-2 rounded-md text-black"
                value={formData.RFE_EMAIL}
                onChange={handleChange}
              />
            </li>
            <li className=" mt-4 mb-4">
              <label htmlFor="website">Web Site</label>
              <input
                type="text"
                name="RFE_WEBSITE"
                className="w-full mt-4 h-8 px-2 rounded-md text-black"
                id="website"
                value={formData.RFE_WEBSITE}
                onChange={handleChange}
              />
            </li>
          </ul>
        </div>
        {isLoading && <Spinner/>}
        <div className="mt-6 text-right">
          <Link to="/ExternalReferralSourcesList">
            <Button className="w-[100px]">Cancel</Button>
          </Link>
          <Button
            type="button"
            className="w-[100px] ml-[12px]"
            onClick={goToPreviousStep}
          >
            Previous
          </Button>
          <Button type="submit" className="w-[100px] ml-[12px]">
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default OthersFacility;
