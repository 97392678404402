import MeetingCurrentList from "../../components/Meetings/MeetingsCurrentList";

const MeetingCurrentListPage = () => {
  return (
    <div>
      <MeetingCurrentList />
    </div>
  );
};

export default MeetingCurrentListPage;
