import React from "react";

import SPCRankingList from "../../components/Top20SPC/SPCRankingList";

import { rankingListDummy } from "../../components/types";

const SPCRankingListPage = () => {
  return (
    <div>
      <SPCRankingList RankingList={rankingListDummy} />
    </div>
  );
};

export default SPCRankingListPage;
