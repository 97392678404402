import React from "react";
import { Form } from "react-router-dom";

import BackButton from "../../UI/BackButton";

const CurrentMonth: React.FC = () => {
  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[28px] mt-[-40px]">Performance - Current</h1>
          </div>
        </div>
        <Form>
          <div className="mt-6 p-2 border-2 rounded-md">
            <div className="mt-4 p-2 border-2 rounded-md text-center">
              {/* Date */}
              <label>Date</label>
              <ul className="text-left md:ml-10">
                <li>
                  <label htmlFor="from">From:</label>
                  <input
                    type="date"
                    id="from"
                    className="ml-[20px] w-[150px] md:ml-[40px] md:w-[200px] h-8 mt-4 pl-2 rounded-md text-black text-center"
                  />
                </li>
                <li>
                  <label htmlFor="to">To:</label>
                  <input
                    type="date"
                    id="to"
                    className="ml-[40px] w-[150px] md:ml-[60px] md:w-[200px] h-8 mt-4 mb-4 pl-2 rounded-md text-black text-center"
                  />
                </li>
              </ul>
            </div>

            {/* Admitted */}
            <div className="mt-4 p-2 border-2 rounded-md text-center">
              <label>Total Admitted</label>
              <ul className="text-left md:ml-10">
                <li className="mt-4 ">
                  <label htmlFor="probono">Probono</label>
                  <input
                    type="text"
                    id="probono"
                    className="ml-[40px] w-[80px] md:ml-[70px] md:w-[100px] lg:ml-[50px] h-8 pr-2 rounded-md text-black text-right "
                  />
                  <label
                    htmlFor="ngs"
                    className="ml-[32px] md:ml-[90px] lg:ml-[50px] "
                  >
                    NGS
                  </label>
                  <input
                    type="text"
                    id="ngs"
                    className="ml-2 w-[80px] md:ml-[50px] md:w-[100px] lg:ml-[30px] h-8 pr-2 rounded-md text-black text-right "
                  />
                </li>

                <li className="mt-4 mb-4">
                  <label htmlFor="insurance">No Insurance</label>
                  <input
                    type="text"
                    id="insurance"
                    className="ml-2 w-[80px] md:ml-[38px] md:w-[100px] lg:ml-[18px] h-8 pr-2 rounded-md text-black text-right "
                  />

                  <label
                    htmlFor="hmo"
                    className="ml-[26px] md:ml-[84px] lg:ml-[44px]"
                  >
                    HMO
                  </label>
                  <input
                    type="text"
                    id="hmo"
                    className="ml-2 w-[80px] md:ml-[50px] md:w-[100px] lg:ml-[30px] h-8 pr-2 rounded-md text-black text-right"
                  />
                </li>
              </ul>
            </div>

            {/* Pending */}
            <div className="mt-4 p-2 border-2 rounded-md text-center">
              <label>Total Pending</label>
              <ul className="text-left mt-4 md:ml-10">
                <li className="mt-4">
                  <label htmlFor="pProbono">Probono</label>
                  <input
                    type="text"
                    id="pProbono"
                    className="ml-[40px] w-[80px] md:ml-[70px] md:w-[100px] lg:ml-[50px] h-8 pr-2 rounded-md text-black text-right "
                  />
                  <label
                    htmlFor="pNgs"
                    className="ml-[32px] md:ml-[90px] lg:ml-[50px]"
                  >
                    NGS
                  </label>
                  <input
                    type="text"
                    id="pNgs"
                    className="ml-2 w-[80px] md:ml-[50px] md:w-[100px] lg:ml-[30px] h-8 pr-2 rounded-md text-black text-right "
                  />
                </li>

                <li className="mt-4 mb-4">
                  <label htmlFor="pInsurance">No Insurance</label>
                  <input
                    type="text"
                    id="pInsurance"
                    className="ml-2 w-[80px] md:ml-[38px] md:w-[100px] lg:ml-[18px] h-8 pr-2 rounded-md text-black text-right "
                  />

                  <label
                    htmlFor="pHmo"
                    className="ml-[26px] md:ml-[84px] lg:ml-[44px]"
                  >
                    HMO
                  </label>
                  <input
                    type="text"
                    id="pHmo"
                    className="ml-2 w-[80px] md:ml-[50px] md:w-[100px] lg:ml-[30px] h-8 pr-2 rounded-md text-black text-right"
                  />
                </li>
              </ul>
            </div>

            {/* Summary */}
            <div className="mt-4 mb-4 p-2 border-2 rounded-md text-center">
              <label>Total Summary</label>
              <ul className="text-left md:ml-10">
                <li className="mt-4">
                  <label htmlFor="referral">Referral</label>
                  <input
                    type="text"
                    id="referral"
                    className="ml-[42px] w-[80px] md:ml-[77px] md:w-[100px] lg:ml-[57px] h-8 pr-2 rounded-md text-black text-right"
                  />
                  <label
                    htmlFor="pending"
                    className="ml-[16px] md:ml-[64px] lg:ml-[24px]"
                  >
                    Pending
                  </label>
                  <input
                    type="text"
                    id="pending"
                    className="ml-1 w-[80px] md:ml-[50px] md:w-[100px] lg:ml-[30px] h-8 pr-2 rounded-md text-black text-right"
                  />
                </li>

                <li className="mt-4">
                  <label htmlFor="soc">Possible SOC</label>
                  <input
                    type="text"
                    id="soc"
                    className="ml-1 w-[80px] md:ml-[39px] md:w-[100px] lg:ml-[19px] h-8 pr-2 rounded-md text-black text-right"
                  />
                  <label
                    htmlFor="failed"
                    className="ml-[29px] md:ml-[81px] lg:ml-[41px]"
                  >
                    Failed
                  </label>
                  <input
                    type="text"
                    id="failed"
                    className="ml-2 w-[80px] md:ml-[50px] md:w-[100px] lg:ml-[30px] h-8 pr-2 rounded-md text-black text-right"
                  />
                </li>

                <li className="mt-4">
                  <label htmlFor="stats">No Status</label>
                  <input
                    type="text"
                    id="stats"
                    className="ml-[27px] w-[80px] md:ml-[63px] md:w-[100px] lg:ml-[42px] h-8 pr-2 rounded-md text-black text-right"
                  />
                  <label
                    htmlFor="admit"
                    className="ml-1 md:ml-[56px] lg:ml-[17px]"
                  >
                    Admitted
                  </label>
                  <input
                    type="text"
                    id="admit"
                    className="ml-2 w-[80px] md:ml-[50px] md:w-[100px] lg:ml-[30px] h-8 pr-2 rounded-md text-black text-right"
                  />
                </li>
                <li className="mt-4 mb-4">
                  <label
                    htmlFor="clinician"
                    className="ml-[97px] md:ml-[206px] lg:ml-[145px]"
                  >
                    Assigned To Clinician
                  </label>
                  <input
                    type="text"
                    id="clinician"
                    className="ml-2 w-[80px] md:ml-[50px] md:w-[100px] lg:ml-[30px] h-8 pr-2 rounded-md text-black text-right"
                  />
                </li>
              </ul>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CurrentMonth;
