import { Text, View } from "@react-pdf/renderer";

import css from "./ViewVisit.Style";
import { ITableViewVisit } from "../../../UI/interface";

const FooterPdf: React.FC<ITableViewVisit> = ({ tableData }) => {
  if (tableData.length === 0) {
    return null;
  }

  const totalData = tableData[0];

  return (
    <View>
      <View style={css.line} />
      <View style={css.dataContainer}>
        <View style={css.dataContainer}>
          <Text style={css.label}>Record Count: </Text>
          <Text style={css.label}>{totalData.totalRecordCount}</Text>
        </View>
        <View style={{flexDirection : "row", marginRight: 180}}>
          <Text style={[css.label, { width: 100 }]}>Totals: </Text>
          <Text style={[css.label, { width: 95 }]}>
            {Number(totalData.totalMileage).toFixed(2)}
          </Text>
          <Text style={[css.label, { width: 80, marginRight: 20 }]}>
            {Number(totalData.totalMarketingExpense).toFixed(2)}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default FooterPdf;
