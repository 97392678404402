import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import { Form, Link, useNavigate, useParams } from "react-router-dom";

import NoPhoto from "../../../image/NoPhoto.png";
import Button from "../../../UI/button";
import BackButton from "../../../UI/BackButton";
import { tokenLoader } from "../../../UI/auth";
import { IContactDetails, IRating } from "../../../UI/interface";
import { alertLoading, closeAlert, showErrorAlert, successAlert } from "../../../UI/alert";

const ContactForm: React.FC = () => {
  const { ContactID, RFE_ID } = useParams();
  const contactIdInputRef = useRef<HTMLInputElement>(null);
  const firstNameInputRef = useRef<HTMLInputElement>(null);
  const lastNameInputRef = useRef<HTMLInputElement>(null);
  const positionInputRef = useRef<HTMLInputElement>(null);
  const telephoneInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const ratingInputRef = useRef<HTMLSelectElement>(null);
  const remarksInputRef = useRef<HTMLTextAreaElement>(null);
  const isActiveInputRef = useRef<HTMLInputElement>(null);
  const deleteInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const [starRating, setStarRating] = useState<IRating[]>([]);
  const [contact, setContact] = useState<IContactDetails | null>(null);
  const [contactImage, setContactImage] = useState<File | null>(null);
  const [deleteChecked, setDeleteChecked] = useState<boolean>(false);
  const [newContactId, setNewContactId] = useState<string>("");
  const isUpdateMode = !!ContactID;
  const navigate = useNavigate();

  const fetchData = async (url: string, token: string) => {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      return await response.json();
  };

  useEffect(() => {
    (async () => {
      const token = tokenLoader();
      if (!token) {
        throw new Error("Token is not available");
      }
      const jsonResponse = await fetchData(
        `${process.env.REACT_APP_API_URL}/rfe/starrating`,
        token
      );
      if (jsonResponse) {
        setStarRating(jsonResponse);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const token = tokenLoader();
      if (!token) {
        throw new Error("Token is not available");
      }
      if (ContactID) {
        const contactData = await fetchData(
          `${process.env.REACT_APP_API_URL}/rfc/getContact/${ContactID}`,
          token
        );
        if (contactData && contactData[0]) {
          setContact(contactData[0]);
        }
      }
    })();
  }, [ContactID]);

  useEffect(() => {
    (async () => {
      const token = tokenLoader();
      if (!token) {
        throw new Error("Token is not available");
      }
      if (!isUpdateMode) {
        const contactIdData = await fetchData(
          `${process.env.REACT_APP_API_URL}/rfc/targetId`,
          token
        );
        if (contactIdData) {
          setNewContactId(contactIdData.ContactID);
        }
      }
    })();
  }, [isUpdateMode]);

  useEffect(() => {
    if (contact) {
      const refValueMapping = [
        { ref: contactIdInputRef, value: contact.ContactID },
        { ref: firstNameInputRef, value: contact.Contact_Firstname.trim() },
        { ref: lastNameInputRef, value: contact.Contact_Lastname.trim() },
        { ref: positionInputRef, value: contact.Contact_Position.trim() },
        { ref: telephoneInputRef, value: contact.Contact_Cellphone.trim() },
        { ref: emailInputRef, value: contact.Contact_Email.trim() },
        { ref: ratingInputRef, value: contact.Contact_Star_Rating },
        { ref: remarksInputRef, value: contact.Remarks.trim() },
      ];

      refValueMapping.forEach((mapping) => {
        if (mapping.ref.current) {
          mapping.ref.current.value = mapping.value;
        }
      });

      if (isActiveInputRef.current) {
        isActiveInputRef.current.checked = contact.RFC_Is_Active;
      }

      if (telephoneInputRef.current) {
        const phoneNumber = contact.Contact_Cellphone.trim();
        telephoneInputRef.current.value = `(${phoneNumber.substring(
          0,
          3
        )}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, 10)}`;
      }
    }
  });

  const saveHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {

      const firstName = firstNameInputRef.current!.value.trim();
      const lastName = lastNameInputRef.current!.value.trim();
      const position = positionInputRef.current!.value.trim();
      const email = emailInputRef.current!.value.trim();
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const rawPhoneNumber = telephoneInputRef.current!.value.trim();
      const sanitizedPhoneNumber = rawPhoneNumber.replace(/[^\d]/g, "");
  
      const validPhoneNumberFormat =
        /^(\(\d{3}\)\s?|\d{3}-|\d{7}|\d{3})\d{3}-?\d{4}$/;
  
      let alertTitle = "";
      let alertText = "";
  
      if (!firstName || !lastName || !position || !rawPhoneNumber || !email) {
        if (!firstName && !lastName && !position && !rawPhoneNumber && !email) {
          alertTitle = "All fields are required!";
          alertText = "Please input all the required fields.";
        } else if (!firstName) {
          alertTitle = "First name is blank.";
          alertText = "Please input the first name.";
        } else if (!lastName) {
          alertTitle = "Last name is blank.";
          alertText = "Please input the last name.";
        } else if (!position) {
          alertTitle = "Position is blank.";
          alertText = "Please input the position.";
        } else if (!rawPhoneNumber) {
          alertTitle = "Telephone is blank.";
          alertText = "Please input telephone or cellphone number.";
        } else if (!email) {
          alertTitle = "Email is blank.";
          alertText = "Please input an email address.";
        }
      } else if (!validPhoneNumberFormat.test(rawPhoneNumber)) {
        alertTitle = "Telephone is invalid.";
        alertText = "Please input a valid phone number";
      } else if (!emailRegex.test(email)) {
        alertTitle = "Email is invalid.";
        alertText = "Please input a valid email address.";
      }
  
      if (alertTitle) {
        showErrorAlert(alertText, alertTitle);
        return;
      }
  
      const formData = new FormData();
  
      formData.append("contactId", contactIdInputRef.current!.value || "");
      formData.append("contactFirstName", firstNameInputRef.current!.value || "");
      formData.append("contactLastName", lastNameInputRef.current!.value || "");
      formData.append("contactPosition", positionInputRef.current!.value || "");
      formData.append("contactCellphone", sanitizedPhoneNumber || "");
      formData.append("contactEmail", emailInputRef.current!.value || "");
      formData.append("contactStarRating", ratingInputRef.current!.value || "");
      formData.append("remarks", remarksInputRef.current!.value || "");
      formData.append(
        "rfcIsActive",
        isActiveInputRef.current!.checked ? "true" : "false"
      );
      if (contactImage) {
        formData.append("contactImage", contactImage);
      }
  
      const apiUrl = isUpdateMode
        ? `${process.env.REACT_APP_API_URL}/rfc/updateContact/${RFE_ID}`
        : `${process.env.REACT_APP_API_URL}/rfc/createContact/${RFE_ID}`;
  
      const httpMethod = isUpdateMode ? "PUT" : "POST";
  
      alertLoading("Submitting", "Please wait ...");
      const response = await fetch(apiUrl, {
        method: httpMethod,
        headers: {
          Authorization: `Bearer ${tokenLoader()}`,
        },
        body: formData,
      });
  
      if (deleteChecked) {
        alertLoading("Deleting", "Please wait ...");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/rfc/deleteContact/${ContactID}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${tokenLoader()}`,
            },
          }
        );
  
        if (response.ok) {
          closeAlert();
          successAlert("Contact deleted successfully!");
          navigate(-1);
        }
        return;
      }
  
      if (response.ok) {
        closeAlert();
        successAlert("Data saved successfully!");
        navigate(-1);
      }
    } catch(error: any){
      showErrorAlert(`${error.message}`)
      // showErrorAlert('Something went wrong, Please try again ... ')
      return error;
    }
  };

  const imageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setContactImage(file);
    }
  };

  const uploadPhotoHandler = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div className="relative">
          <BackButton />
          <div className="text-center">
            {ContactID ? (
              <h1 className="text-[30px] mt-[-40px]">Contact Details</h1>
            ) : (
              <h1 className="text-[30px] mt-[-40px]">Add Contact</h1>
            )}
          </div>
        </div>

        <Form onSubmit={saveHandler}>
          <div className="mt-8 p-2 border-2 rounded-md">
            <div className="flex flex-col items-center mt-3">
              <div className="h-32 mb-4">
                {contactImage ? (
                  <img
                    src={URL.createObjectURL(contactImage)}
                    alt=""
                    className="w-[142px] h-32 mb-4 rounded-md"
                  />
                ) : (
                  <img
                    src={
                      contact
                        ? `${process.env.REACT_APP_API_URL}/${contact.RFC_Image}`
                        : NoPhoto
                    }
                    alt=""
                    className="w-[142px] h-32 mb-4 rounded-md"
                  />
                )}
                <input
                  id="contactImage"
                  type="file"
                  accept="image/*"
                  ref={imageInputRef}
                  hidden
                  onChange={imageChangeHandler}
                />
              </div>
              <Button className="text-right" onClick={uploadPhotoHandler}>
                Upload Photo
              </Button>
            </div>
            <ul>
              <li className="mt-6">
                <label className="sm-max:block" htmlFor="contactId">
                  Contact ID:{" "}
                </label>
                <input
                  id="contactId"
                  type="text"
                  className="w-[258px] h-8 px-2 ml-[10px] sm-max:w-full sm-max:ml-0 md:w-[610px] lg:w-[437px] mb-6 rounded-md text-black"
                  ref={contactIdInputRef}
                  value={newContactId}
                  readOnly
                  disabled
                />
              </li>
              <li>
                <label className="sm-max:block" htmlFor="contactFirstName">
                  First Name
                </label>
                <input
                  id="contactFirstName"
                  type="text"
                  className="w-[258px] h-8 px-2 ml-[19px] sm-max:w-full sm-max:ml-0 md:w-[610px] lg:w-[437px] lg:ml-[18px] mb-4 rounded-md text-black"
                  ref={firstNameInputRef}
                />
              </li>
              <li>
                <label className="sm-max:block" htmlFor="contactLastName">
                  Last Name
                </label>
                <input
                  id="contactLastName"
                  type="text"
                  className="w-[258px] h-8 px-2 ml-[20px] sm-max:w-full sm-max:ml-0 md:w-[610px] lg:w-[437px] mb-4 rounded-md text-black"
                  ref={lastNameInputRef}
                />
              </li>
              <li>
                <label className="sm-max:block" htmlFor="contactPosition">
                  Position
                </label>
                <input
                  id="contactPosition"
                  type="text"
                  className="w-[258px] h-8 px-2 ml-[39px] sm-max:w-full sm-max:ml-0 md:w-[610px] lg:w-[437px] lg:ml-[38px] mb-4 rounded-md text-black"
                  ref={positionInputRef}
                />
              </li>
              <li>
                <label className="sm-max:block" htmlFor="contactCellphone">
                  Telephone
                </label>
                <input
                  id="contactCellphone"
                  type="text"
                  className="w-[258px] h-8 px-2 ml-[22px] sm-max:w-full sm-max:ml-0 md:w-[610px] lg:w-[437px] mb-4 rounded-md text-black"
                  ref={telephoneInputRef}
                />
              </li>
              <li>
                <label className="sm-max:block" htmlFor="contactEmail">
                  Email
                </label>
                <input
                  id="contactEmail"
                  type="text"
                  className="w-[258px] h-8 px-2 ml-[57px] sm-max:w-full sm-max:ml-0 md:w-[610px] lg:w-[437px] mb-4 rounded-md text-black"
                  ref={emailInputRef}
                />
              </li>
              <li>
                <label className="sm-max:block" htmlFor="contactStarRating">
                  Rating
                </label>
                <select
                  id="contactStarRating"
                  className="w-[100px] h-8 px-2 ml-[50px] sm-max:w-full sm-max:ml-0 md:w-[150px] lg:ml-[49px] mb-4 rounded-md text-black"
                  ref={ratingInputRef}
                >
                  <option></option>
                  {starRating.map((starsOption) => (
                    <option
                      key={starsOption.RFE_RATING_CODE}
                      value={starsOption.RFE_RATING_CODE}
                    >
                      {starsOption.RFE_RATING}
                    </option>
                  ))}
                </select>
              </li>
              <li className="mb-4">
                <label className="sm-max:block" htmlFor="remarks">
                  Remarks
                </label>
                <textarea
                  id="remarks"
                  className="w-full rounded-md px-2 mb-4 text-black sm-max:w-full"
                  rows={4}
                  ref={remarksInputRef}
                ></textarea>
              </li>
            </ul>
            <ul className="columns-2 my-3">
              <li>
                <input
                  type="checkbox"
                  className="cursor-pointer mr-1"
                  id="rfcIsActive"
                  ref={isActiveInputRef}
                  defaultChecked={true}
                />
                <label htmlFor="active">Active</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  className="cursor-pointer mr-1"
                  id="delete"
                  ref={deleteInputRef}
                  onChange={(e) => setDeleteChecked(e.target.checked)}
                  disabled={!ContactID}
                />
                <label htmlFor="delete">Delete</label>
              </li>
            </ul>
          </div>
          <div className=" mt-4 text-right">
            <Link to=".." relative="path">
              <Button className="w-[100px] ml-2">Cancel</Button>
            </Link>
            <Button type="submit" className="w-[100px] ml-[12px]">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
