import { Form, Link } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";

import { viewHistoryList } from "../types";

type Props = {
  historyList: viewHistoryList[];
};

const ViewHistoryList = (props: Props) => {
  const { historyList } = props;

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div className="relative">
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Activity - History</h1>
          </div>
        </div>
        <Form className="mt-8 p-2 border-2 rounded-md">
          <div>
            <label htmlFor="">Facility Name:</label>
            <input
              type="text"
              className="w-[236px] ml-5 md:w-[588px] lg:w-[414px] mt-4 h-8 px-2 rounded-md text-black"
            />
          </div>
          <div className="h-[500px] md:h-[700px] mt-4 bg-white">
            <table className="w-full">
              <thead className="bg-blue-900 text-left">
                <tr>
                  <th className="font-normal p-2 w-[110px]">Date</th>
                  <th className="font-normal w-[160px]">Remarks</th>
                  <th className="w-[83px]"></th>
                </tr>
              </thead>
              <tbody className="text-black">
                {historyList.map((HistoryList) => {
                  return (
                    <tr
                      key={HistoryList.id}
                      className="odd:bg-white even:bg-slate-200 h-8 border-b-2"
                    >
                      <td className="px-2">{`${HistoryList.date}`}</td>
                      <td>{`${HistoryList.remarks}`}</td>
                      <td className="text-center">
                        <Link to="/ExternalReferralSourcesList/ViewHistory/Details">
                          <Button>More</Button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ViewHistoryList;
