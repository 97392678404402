import { Form, Link } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";

import { jointCallList } from "../types";

type Props = {
  jointCallList: jointCallList[];
};

const JointCallVisitList = (props: Props) => {
  const { jointCallList } = props;

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl ">
        {/* Header */}
        <div>
          <BackButton />
          <div>
            <h1 className="text-center text-[30px] mt-[-40px]">
              Joint Call Visit
            </h1>
          </div>
        </div>
        <Form>
          <div className="mt-3 md:mt-7 p-2 border-2 rounded-md">
            <div>
              <ul>
                <li>
                  <label htmlFor="">Status</label>
                  <select
                    className="ml-[60px] w-[251px] md:w-[603px] lg:w-[429px] pl-1 h-8 mt-4 rounded-md text-black"
                    name=""
                    id=""
                  >
                    <option value="status"></option>
                    <option value="status1">status1</option>
                  </select>
                </li>
                <li>
                  <label htmlFor="">BDM</label>
                  <select
                    className="ml-[68px] w-[251px] md:w-[603px] lg:w-[429px] pl-1 h-8 mt-4 rounded-md text-black"
                    name=""
                    id=""
                  >
                    <option value="bdm"></option>
                    <option value="bdm">BDM1</option>
                  </select>
                </li>
                <li>
                  <label htmlFor="">Marketer</label>
                  <select
                    className="ml-[39px] w-[251px] md:w-[603px] lg:w-[429px] pl-1 h-8 mt-4 rounded-md text-black"
                    name=""
                    id=""
                  >
                    <option value="marketer"></option>
                    <option value="marketer1">Marketer1</option>
                  </select>
                </li>
                <li>
                  <input type="checkbox" id="active" className="mt-4 mr-1" />
                  <label htmlFor="active">Active</label>
                </li>
              </ul>
            </div>
            {/* Table */}
            <div className="h-[470px] mt-4 mb-2 bg-white">
              <table className="w-full">
                <thead>
                  <tr className="text-left bg-[#13457a] h-8">
                    <th className="pl-1 font-normal ">Information</th>
                  </tr>
                </thead>
                <tbody className="text-black">
                  {jointCallList.map((calls) => {
                    return (
                      <tr
                        key={calls.id}
                        className="odd:bg-white even:bg-slate-200 border-b-2 hover:bg-blue-400 active:bg-blue-300 focus:ring focus:ring-blue-400"
                      >
                        <td>
                          <ul className="flex justify-between list-none px-2">
                            <li>
                              <label>BDM</label>
                              <h2>{`${calls.BDM}`}</h2>
                            </li>
                            <li>
                              <label>Joint Call Date:</label>
                              <h2 className="ml-2">{`${calls.date}`}</h2>
                            </li>
                          </ul>
                          <ul>
                            <li className="mt-4 px-2">
                              <label>Purpose</label>
                              <textarea
                                rows={3}
                                className="w-full px-2 border-2 border-black rounded-md"
                                value={calls.purpose}
                                readOnly
                              ></textarea>
                            </li>
                            <li className="pl-2">
                              <input
                                type="checkbox"
                                name={calls.id}
                                checked={calls.completed}
                                readOnly
                              />
                              <label className="ml-2">Completed</label>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="">
            <Link to="/ExternalSourcesJointCall/Details">
              <Button className="mt-4 w-[180px]">Modify</Button>
            </Link>
            <Button className="ml-2">Download Template</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default JointCallVisitList;
