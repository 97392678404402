import BackButton from "../../UI/BackButton";
import { Form } from "react-router-dom";

const PatientList = () => {
  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div className="relative">
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Patient List {``}</h1>
          </div>
        </div>

        <Form className="mt-8 border-2 rounded-md">
          <div className="h-[600px] md:h-[900px] bg-white overflow-scroll">
            <table className="w-full">
              <thead className="bg-blue-900 text-left h-8 sticky top-0">
                <tr>
                  <th>Patient</th>
                  <th>Agency</th>
                  <th>Insurance</th>
                  <th>Status</th>
                  <th>Facility</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-4 p-2">
            <ul className="columns-2">
                <li>Address : {}</li>
                <li className="ml-[30px]">City : {}</li>
                <li className="ml-[54px]">Contact # : {}</li>
                <li className="ml-[39px]">Emerg CON : {}</li>
            </ul>
            <ul className="columns-3">
                <li className="ml-[35px]">Zip : {}</li>
                <li>State : {}</li>
                <li className="portrait:hidden md:ml-[-57px] lg:ml-[-28px]">Emerg # : {}</li>
                <li className="landscape:hidden md:ml-[-57px]">Emerg # : {}</li>
            </ul>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PatientList;
