import React from "react";
import SurveyForm from "../../components/Presentation/SurveyForm";

const SurveyFormPage = () => {
  return (
    <div>
      <SurveyForm />
    </div>
  );
};

export default SurveyFormPage;
