import { useEffect, useState } from "react";
import { Form, Link, useParams } from "react-router-dom";

import BackButton from "../../UI/BackButton";
import { tokenLoader } from "../../UI/auth";
import Button from "../../UI/button";
import Spinner from "../../UI/Spinner";

import { IViewReceipt } from "../../UI/interface";

const formatNumber = (num: number): string => {
  const formattedNum = num.toFixed(2);
  const [integerPart, decimalPart] = formattedNum.split('.');
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimalPart === '00' ? integerWithCommas : `${integerWithCommas}.${decimalPart}`;
};

const ReceiptList = () => {
  const { VisitCTR } = useParams();
  const [viewReceipt, setViewReceipt] = useState<IViewReceipt[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const fetchReceipt = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/meetings/receiptlist/${VisitCTR}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const jsonResponse = await response.json();
      setViewReceipt(jsonResponse);
      setIsLoading(false);
    };
    fetchReceipt();
  }, [VisitCTR]);

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">List</h1>
          </div>
        </div>
        <Form>
          <div className="mt-8 p-2 border-2 rounded-md">
            <div className="h-[520px] mt-2 mb-2 bg-white overflow-scroll">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#13457a] h-8 text-left top-0 sticky">
                    <th className="font-normal pl-2 w-[204px]">Expense Code</th>
                    <th className="font-normal w-[150px] text-center">
                      Amount
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="text-black">
                  {viewReceipt.map((receipt) => {
                    return (
                      <tr
                        key={receipt.Receipt_CTR}
                        className="odd:bg-white even:bg-slate-200 h-8 border-b-2"
                      >
                        <td className="w-[210px] pl-2">{`${receipt.ExpenseCode}`}</td>
                        <td className="text-center">{formatNumber(parseFloat(receipt.Cost))}</td>
                        <td className="w-[90px]">
                          <Link to={`${receipt.Receipt_CTR}`}>
                            <Button>More</Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Form>
        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

export default ReceiptList;
