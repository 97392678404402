import React from "react";
import MarketersPresentation from "../../components/Presentation/MarketersPresentation";

const MarketerPresentationPage = () => {
  return (
    <div>
      <MarketersPresentation />
    </div>
  );
};

export default MarketerPresentationPage;
