import { Link } from "react-router-dom";

import BackButton from "../../UI/BackButton";

const MoreHrSlVl = () => {
  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13457a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        {/* Header */}
        <div>
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">HR SL/VL</h1>
          </div>
        </div>
        <div className="mt-[80px] p-2 border-2 rounded-md text-center">
          <div>
            <Link to="/HrSlVl/CreateViewLeaveRequest">
              <button className="w-full py-2 px-6 mt-[58px] rounded-md bg-[#13457a]">
                Create/View Leave Request
              </button>
            </Link>
          </div>
          <div>
            <Link to="">
              <button
                className="w-full py-2 px-6 mt-8 mb-[58px] rounded-md bg-[#5e6062]"
                disabled
              >
                Manage Leave Request
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreHrSlVl;
