import React from "react";
import PatientSurvey from "../../components/Presentation/PatientSurvey";

const PatientSurveypage = () => {
  return (
    <div>
      <PatientSurvey />
    </div>
  );
};

export default PatientSurveypage;
