import React, { useState, useEffect } from "react";
import moment from "moment-timezone";

import { tokenLoader } from "../../../UI/auth";
import { IExpenseCode, IPaymentType,IReceiptForm } from "../../../UI/interface";
import { useParams } from "react-router-dom";

const ReceiptForm: React.FC<IReceiptForm> = ({
  expenseCodeInputRef,
  amountInputRef,
  paymentTypeInputRef,
  imageInputRef,
  remarksInputRef,
  receiptImage,
  signatureImage,
  selectImageHandler,
  openModalHandlerSign,
  closeModalHandlerSign
}) => {
  const {fullName} = useParams()
  const [expenseCode, setExpenseCode] = useState<IExpenseCode[]>([]);
  const [paymentType, setPaymentType] = useState<IPaymentType[]>([]);
  const [currentTime, setCurrentTime] = useState<string>("");
  const [attestationChecked, setAttestationChecked] = useState(false);

  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    const currentTime = moment().tz(moment.tz.guess()).format("hh:mm:ss A");
    setCurrentTime(currentTime);
  }, []);

  useEffect(() => {
    if (receiptImage) {
      const img = new Image();
      img.src = URL.createObjectURL(receiptImage);
      img.onload = () => {
        setImageDimensions({ width: img.width, height: img.height });
      };
    }
  }, [receiptImage]);

  useEffect(() => {
    const fetchExpenseCode = async () => {
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/meetings/expensecode`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const jsonResponse = await response.json();

      setExpenseCode(jsonResponse);
    };
    fetchExpenseCode();
  }, []);

  useEffect(() => {
    const fetchPaymentType = async () => {
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/meetings/paymenttype`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const jsonResponse = await response.json();
      setPaymentType(jsonResponse);
    };
    fetchPaymentType();
  }, []);

  let canvasWidth = imageDimensions.width || 530;
  let canvasHeight = imageDimensions.height || 400;

  return (
    <div className="mt-8 p-2 border-2 rounded-md">
      <ul>
        <li className="mt-4">
          <label htmlFor="expenseCode">Expense Code</label>
          <select
            id="expenseCode"
            name="expenseCode"
            className="w-full mt-4 h-8 px-2 text-black rounded-md"
            ref={expenseCodeInputRef}
          >
            <option value=""></option>
            {expenseCode.map((expense: any) => (
              <option
                key={expense.TRAN_OPTION_CODE}
                value={expense.TRAN_OPTION_CODE}
              >
                {expense.TRAN_TRANSLATED_OP}
              </option>
            ))}
          </select>
        </li>
        <li>
          <ul className="columns-2 mt-4">
            <li>
              <label htmlFor="cost">Amount</label>
              <input
                id="cost"
                type="number"
                className="w-full mr-2 mt-4 h-8 px-2 text-black rounded-md text-right flex"
                ref={amountInputRef}
                step={0.01}
              />
            </li>
            <li>
              <label htmlFor="paymentType">Payment Type</label>
              <select
                id="paymentType"
                className="w-full mt-4 h-8 px-2 rounded-md text-black flex"
                ref={paymentTypeInputRef}
              >
                <option value=""></option>
                {paymentType.map((payment) => (
                  <option
                    key={payment.TRAN_OPTION_CODE}
                    value={payment.TRAN_OPTION_CODE}
                  >
                    {payment.TRAN_TRANSLATED_OP}
                  </option>
                ))}
              </select>
            </li>
          </ul>
        </li>
        <li className="h-[500px] w-full mt-4 border-2 rounded-md">
          {receiptImage && (
            <div className="h-[400px]">
              <img
                src={URL.createObjectURL(receiptImage)}
                alt=""
                className="h-[350px] w-full object-contain rounded-md"
                width={canvasWidth}
                height={canvasHeight}
              />
              {signatureImage && (
                <div className="ml-[42%] text-center">
                  <img
                    src={signatureImage}
                    alt="Signature"
                    className="h-[50px] w-full object-contain rounded-md mt-4 "
                  />
                  <div>{`${fullName}`}</div>
                  <div>{`${moment()
                    .tz("UTC")
                    .format("MMMM DD, YYYY")}, ${currentTime}`}</div>
                </div>
              )}
            </div>
          )}
          <input
            id="receiptImage"
            type="file"
            accept="image/*"
            ref={imageInputRef}
            onChange={selectImageHandler}
            hidden
          />
        </li>
        <li className="flex items-start">
          <input
            type="checkbox"
            id="attestation"
            checked={attestationChecked}
            onChange={(e) => {
              setAttestationChecked(e.target.checked);
              if (e.target.checked) {
                openModalHandlerSign();
              } else {
                closeModalHandlerSign();
              }
            }}
            disabled={!receiptImage}
            className="mt-2 h-5 w-5"
          />
          <label htmlFor="attestation" className="ml-3">
            I certify and attest that the attached receipts are true and
            accurate to the best of my knowledge.
          </label>
        </li>
        <li className="mt-4">
          <label htmlFor="remarks">Remarks</label>
          <textarea
            id="remarks"
            rows={5}
            className="w-full mt-4 px-2 rounded-md text-black"
            ref={remarksInputRef}
          ></textarea>
        </li>
      </ul>
    </div>
  );
};

export default ReceiptForm;
