import { redirect } from "react-router-dom";

export function getUserToken() {
  const token = localStorage.getItem("token");
  if (!token) {
    return null;
  }
  return token
}

export function tokenLoader() {
  const token = getUserToken();
  return token;
}

export function checkUserLoader() {
  const token = getUserToken();

  if(!token) {
    return redirect('/login')
  }
}

export function isAuthorized() {
  const token = localStorage.getItem('token');
  const Auth = {'Authorization': "Bearer " + token};
  return Auth
}