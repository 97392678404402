import moment from "moment-timezone";

export async function combineImages(
  receiptImage: File | null,
  signatureImage: string,
  fullName: string,
): Promise<string> {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!canvas || !ctx) {
    throw new Error("Canvas not supported");
  }

  canvas.width = 800;
  canvas.height = 600;

  if (receiptImage) {
    const receiptImageElement = new Image();
    receiptImageElement.src = URL.createObjectURL(receiptImage);
    await new Promise((resolve) => (receiptImageElement.onload = resolve));
    const receiptAspectRatio = receiptImageElement.width / receiptImageElement.height;
    const receiptHeight = canvas.height / 1.5;
    const receiptWidth = receiptHeight * receiptAspectRatio;

    ctx.drawImage(
      receiptImageElement,
      (canvas.width - receiptWidth) / 2, // Center the image horizontally
      0,
      receiptWidth,
      receiptHeight
    );
  }

  const signatureImageElement = new Image();
  signatureImageElement.src = signatureImage;
  await new Promise((resolve) => (signatureImageElement.onload = resolve));

  const maxSignatureHeight = canvas.height / 6;
  const signatureWidth =
    (signatureImageElement.width * maxSignatureHeight) /
    signatureImageElement.height;
  const signatureHeight = maxSignatureHeight;

  const offset = 150
  const signatureX = (canvas.width - signatureWidth) / 2 + offset;
  const signatureY = canvas.height / 1.5;

  ctx.drawImage(
    signatureImageElement,
    signatureX,
    signatureY,
    signatureWidth,
    signatureHeight
  );

  ctx.fillStyle = "black";
  ctx.font = "16px Arial";
  ctx.fillText(fullName, signatureX, signatureY + signatureHeight + 5);
  const currentTime = moment().tz(moment.tz.guess()).format("MMMM DD, YYYY, h:mm:ss A");
  ctx.fillText(currentTime, signatureX, signatureY + signatureHeight + 25);

  const combinedImageData = canvas.toDataURL("image/png");

  return combinedImageData;
}
