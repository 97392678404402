import { Text, View, Image } from "@react-pdf/renderer";
import moment from "moment-timezone";

import css from "./ViewVisit.Style";
import testPhoto from "./healthconnectlogo.png";
import { ITableViewVisit } from "../../../UI/interface";

const HeaderPdf: React.FC<ITableViewVisit> = ({ tableData }) => {
  if (tableData.length === 0) {
    return null;
  }

  const headerData = tableData[0];

  return (
    <div>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Image src={testPhoto} style={css.image} />
        <View style={{ flexDirection: "column", marginLeft: 14 }}>
          <Text style={css.labelText}>{headerData.AgencyName}</Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={css.labelText}>Marketer Name: {headerData.FULL_NAME} </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={css.labelText}>Cut Off Period: {headerData.cutOffDetails} </Text>
          </View>
        </View>
        <View style={{ flexDirection: "column", marginLeft: 330 }}>
            <Text style={css.commonText}>Not the basis for mileage computation. Raw Data</Text>
            <Text style={css.labelText}>Date Extracted: {moment(headerData.submitDate).tz("UTC").format("MM/DD/YYYY")}, {moment(headerData.submitTime, "HHmmss").format("hh:mm:ss A")}</Text>
        </View>
      </View>
      <View style={css.line} />
      <View style={css.dataContainer}>
        <Text style={[css.label, css.midWidth]}>Visit Date</Text>
        <Text style={[css.label, css.midWidth]}>Visit Time</Text>
        <Text style={[css.label, css.narrowWidth]}>Unlisted</Text>
        <Text style={[css.label, css.wideWidth]}>Facility Name</Text>
        <Text style={[css.label, css.wideWidth]}>Activity</Text>
        <Text style={[css.label, css.midWidth]}>Raw Mileage</Text>
        <Text style={[css.label, css.midWidth]}>Mktg Expense</Text>
        <Text style={[css.label, css.wideWidth]}>Modified Reciept Date</Text>
      </View>
      <View style={css.line} />
    </div>
  );
};

export default HeaderPdf;
