import React from "react";
import PreviousMonth from "../../components/Performance/PreviousMonth";

const PreviousMonthPage = () => {
  return (
    <div>
      <PreviousMonth />
    </div>
  );
};

export default PreviousMonthPage;
