import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  container: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
  },

  header: {
    flexDirection: "row",
    fontFamily: "Helvetica-Bold",
    marginBottom: 3,
    fontSize: 12.5,
    marginTop: 5,
    marginLeft: 15,
  },

  table: {
    width: "100%",
  },

  tableHeader: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    paddingLeft: 5,
    paddingTop: 3,
    borderTop: "1pt solid #666",
  },

  tableBody: {
    fontSize: 12,
    padding: 5,
    marginLeft: 11,
    flexDirection: "row",
  },

  tableFooter: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12.5,
    padding: 5,
    marginLeft: 5,
    flexDirection: "row",
  },

  tableBodyC1: {
    flexDirection: "row",
    justifyContent: "flex-start",
    width: 150,
  },

  tableBodyC2: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  dailyTotal: {
    flexDirection: "row",
    color: "red",
  },

  image: {
    width: 115,
    height: 60,
  },

  row: {
    flexDirection: "row",
  },

  odd: {
    backgroundColor: "white",
  },

  even: {
    backgroundColor: "#EEEBEA",
  },

  cutOffContainer: {
    width: "100%",
    marginHorizontal: 3,
    backgroundColor: "#BEDCFF",
    marginLeft: 0,
  },
});
