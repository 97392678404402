import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Image, Page, Text, View, Document, pdf } from "@react-pdf/renderer";
import moment from "moment-timezone";
import { successAlert, confirmationAlert, showErrorAlert } from "../../../UI/alert";

import { tokenLoader } from "../../../UI/auth";
import css from "./ViewMer.Style";
import Spinner from "../../../UI/Spinner";
import CutOff from "./CutOff";
import HeaderPdf from "./HeaderPdf";
import { ICutOff } from "../../../UI/interface";

const ViewMer: React.FC = () => {
  const {
    CutoffDateFrom,
    CutoffDateTo,
    ReimbursementDateDescription,
    ReimbursementDateID,
  } = useParams();
  const location = useLocation();
  const { signatureImage } = location.state;
  const [currentTime, setCurrentTime] = useState<string>("");
  const [tableData, setTableData] = useState<ICutOff[]>([]);
  const [merPdf, setMerPdf] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [post, setPost] = useState<any[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    const currentTime = moment().tz(moment.tz.guess()).format("h:mm:ss A");
    setCurrentTime(currentTime);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/report/expenseReport/${CutoffDateFrom}/${CutoffDateTo}/${ReimbursementDateDescription}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const jsonResponse = await response.json();
      setTableData(jsonResponse);
      setIsLoading(false);
    };
    fetchData();
  }, [
    CutoffDateFrom,
    CutoffDateTo,
    ReimbursementDateDescription,
    ReimbursementDateID,
  ]);

  const generatePDF = (
    tableData: any,
    signatureImage: string | undefined,
    currentTime: any
  ) => {
    return (
      <Document>
        <Page size={"LEGAL"}>
          <View fixed>
            <HeaderPdf tableData={tableData} />
          </View>
          {tableData.slice(1).map((cutOffDate: any, index: any) => (
            <View
              style={
                index % 2 === 0
                  ? { ...css.table, ...css.odd }
                  : { ...css.table, ...css.even }
              }
              key={cutOffDate.visitDateCount}
              wrap={false}
            >
              <View style={css.tableHeader}>
                <Text>
                  {moment(cutOffDate.visitDate).tz("UTC").format("DD-MMM ddd")}
                </Text>
              </View>
              {cutOffDate.dailyTotal !== 0 && (
                <View style={css.tableBody}>
                  <View>
                    <View style={css.tableBodyC1}>
                      <Text>Reimburse Mileage</Text>
                      <Text style={{ marginLeft: 30, flex: 1 }}>
                        {cutOffDate.dailyMileage.toFixed(3)}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flex: 1, marginLeft: 40 }}>
                    <View style={css.tableBodyC2}>
                      <Text>Adjustment</Text>
                      <Text style={{ marginLeft: 22 }}>
                        {cutOffDate.adjustment.toFixed(3)}
                      </Text>
                    </View>
                    <View style={css.tableBodyC2}>
                      <Text>Car Insurance</Text>
                      <Text style={{ marginLeft: 7 }}>
                        {cutOffDate.carInsurance.toFixed(3)}
                      </Text>
                    </View>
                    <View style={css.tableBodyC2}>
                      <Text>Food</Text>
                      <Text style={{ marginLeft: 55 }}>
                        {cutOffDate.food.toFixed(3)}
                      </Text>
                    </View>
                    <View style={css.tableBodyC2}>
                      <Text>Gas</Text>
                      <Text style={{ marginLeft: 59 }}>
                        {cutOffDate.gasoline.toFixed(3)}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flex: 1, marginLeft: 0 }}>
                    <View style={css.tableBodyC2}>
                      <Text>Gift</Text>
                      <Text style={{ marginLeft: 70 }}>
                        {cutOffDate.gift.toFixed(3)}
                      </Text>
                    </View>
                    <View style={css.tableBodyC2}>
                      <Text>Accomodation</Text>
                      <Text style={{ marginLeft: 13 }}>
                        {cutOffDate.hotelAccomodation.toFixed(3)}
                      </Text>
                    </View>
                    <View style={css.tableBodyC2}>
                      <Text>Office Supplies</Text>
                      <Text style={{ marginLeft: 8 }}>
                        {cutOffDate.officeSupplies.toFixed(3)}
                      </Text>
                    </View>
                    <View style={css.tableBodyC2}>
                      <Text>Parking</Text>
                      <Text style={{ marginLeft: 48 }}>
                        {cutOffDate.parking.toFixed(3)}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flex: 1, marginLeft: 0 }}>
                    <View style={css.tableBodyC2}>
                      <Text>Toll</Text>
                      <Text style={{ marginLeft: 34 }}>
                        {cutOffDate.toll.toFixed(3)}
                      </Text>
                    </View>
                    <View style={css.tableBodyC2}>
                      <Text>Transpo</Text>
                      <Text style={{ marginLeft: 10 }}>
                        {cutOffDate.transport.toFixed(3)}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={css.tableFooter}>
                <View>
                  <View style={css.tableBodyC1}>
                    <Text>Total Reimburseable:</Text>
                    <Text style={{ marginLeft: 10, flex: 1 }}>
                      {cutOffDate.totalReimburseable.toFixed(3)}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1, marginLeft: 70 }}>
                  <View style={css.tableBodyC2}>
                    <Text>Total Mktg. Expense:</Text>
                    <Text style={{ marginLeft: 100 }}>
                      {cutOffDate.totalMarketingExpense.toFixed(3)}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1, marginLeft: 100 }}>
                  <View style={css.dailyTotal}>
                    <Text>Daily Total:</Text>
                    <Text style={{ marginLeft: 5 }}>
                      {cutOffDate.dailyTotal.toFixed(3)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          ))}
          <View wrap={false}>{<CutOff tableData={tableData} />}</View>
          {signatureImage && (
            <View>
              <View style={{ margin: 10, fontSize: 11 }}>
                <Text>
                  This is to assume full responsibility for the legitimacy of
                  the document where my signature appears
                </Text>
              </View>
              <View style={{ marginLeft: 330, marginTop: 20, fontSize: 14 }}>
                <View>
                  <Image
                    src={signatureImage}
                    style={{ width: 200, height: 100 }}
                  />
                </View>
                <View>
                  <Text style={{ marginLeft: 60 }}>
                    {tableData[0]?.marketerName}
                  </Text>
                  <Text>
                    {`${moment()
                      .tz("UTC")
                      .format("MMMM DD, YYYY")}, ${currentTime}`}
                  </Text>
                </View>
              </View>
            </View>
          )}
        </Page>
      </Document>
    );
  };

  useEffect(() => {
    const generateAndSetPdfFile = async () => {
      const pdfComponent = generatePDF(tableData, signatureImage, currentTime);
      const pdfBlob = await pdf(pdfComponent).toBlob();
      const pdfFile = new File([pdfBlob], "mer.pdf", {
        type: "application/pdf",
      });
      setMerPdf(pdfFile);
    };
    generateAndSetPdfFile();
  }, [tableData, signatureImage, currentTime]);

  if (!tableData || tableData.length === 0) {
    return <Spinner />;
  }

  const handleClose = async () => {
    const result = await confirmationAlert(
      "Are you sure you want to close?",
      "Confirm Close"
    );
    if (result.isConfirmed) {
      navigate("/meetingPrintOut");
    }
  };

  const handleSubmit = async () => {
    try{
      const pdfComponent = generatePDF(tableData, signatureImage, currentTime);
      const pdfBlob = await pdf(pdfComponent).toBlob();
      const pdfFile = new File([pdfBlob], "mer.pdf", { type: "application/pdf" });
  
      const formData = new FormData();
      formData.append("pdfFile", pdfFile);
  
      const token = tokenLoader();
      const result = await confirmationAlert(
        "Are you sure you want to Submit the File?",
        "Confirm Submit"
      );
  
      if (result.isConfirmed) {
        const submitMer = await fetch(
          `${process.env.REACT_APP_API_URL}/report/submitEmr/${ReimbursementDateID}`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
            },
            body: formData,
          }
        );
  
        successAlert("MER Submitted Successfully");
        navigate("/meetingPrintOut");
  
        const submitMerResponse = await submitMer.json();
        setPost([...post, submitMerResponse]);
    }
    }catch (error: any){
      console.log(error.message)
      showErrorAlert("EMR is not submitted. Please contact your administrator. Thank you!",
      "Error")
      return error;
    }
  };

  return (
    <div>
      <div>
        {merPdf && (
          <iframe
            src={URL.createObjectURL(merPdf)}
            style={{
              display: "flex",
              position: "absolute",
              width: "100%",
              height: "100%",
              left: "0",
              top: "0",
            }}
            title="PDF Viewer"
          />
        )}
        {isLoading && <Spinner />}
      </div>
      {signatureImage && (
        <div style={{ position: "absolute", bottom: "0", right: 20 }}>
          <button
            id="submitPDF"
            type="submit"
            className="w-[100px] ml-4 py-2 px-6 rounded-md bg-[#1da9e9] text-white"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="w-[100px] ml-4 py-2 px-6 rounded-md bg-[#1da9e9] text-white"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default ViewMer;
