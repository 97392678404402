import React from "react";
import ViewHistoryList from "../../components/ExternalSources/ViewHistoryList";

import { ViewHistoryListDummy } from "../../components/types";

const VisitHistoryListPage = () => {
  return (
    <div>
      <ViewHistoryList historyList={ViewHistoryListDummy} />
    </div>
  );
};

export default VisitHistoryListPage;
