import BusinessOpportunityCode from "../../components/Performance/BusinessOpportunityCode";

const BusinessOpportunityCodePage = () => {
  return (
    <div>
      <BusinessOpportunityCode />
    </div>
  );
};

export default BusinessOpportunityCodePage;
