import Swal from "sweetalert2";

export const showErrorAlert = (message: string, title = "") => {
  Swal.fire({
    icon: "info",
    title: title,
    text: message,
  });
};

export const comingSoonAlert = () => {
  Swal.fire({
    icon: "info",
    title: "Coming Soon",
    showCloseButton: true, 
    confirmButtonText: "OK",
    customClass: {
        confirmButton: "w-[130px]",
    }
  });
};

export const successAlert = (message: string) => {
  Swal.fire({
    title: "Success",
    icon: "success",
    text: message,
  });
};

export const warningAlert = (message: string, title = "") => {
  Swal.fire({
    title: title,
    icon: "warning",
    text: message,
  });
};

export const confirmationAlert = async (message: string, title = "") => {
  const result = await Swal.fire({
    title: title,
    text: message,
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "Cancel",
    customClass: {
      confirmButton: "w-[130px] px-4 py-2 rounded",
      cancelButton: "w-[130px] px-4 py-2 rounded",
    },
  });
  return result;
};

export const alertLoading= async (title: string, msg: string) =>{

  Swal.fire({
    title: title,
    text: title + "... " + msg,
    icon: "info",
    allowOutsideClick: false,
    showConfirmButton: false

  });
}

export const closeAlert = () => {
  Swal.close();
}