import React from "react";

const Spinner: React.FC = () => {
    return (
        <div className="fixed inset-0 bg-transparent bg-opacity-25 backdrop-blur flex justify-center items-center">
            <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
        </div>
    )
}

export default Spinner