import React from "react";

import FacilityForm from "../../../components/ExternalSources/ReferralSources/FacilityForm";

const FacilityFormPage = () => {
  return (
    <div>
      <FacilityForm />
    </div>
  );
};

export default FacilityFormPage;
