import React, { useEffect, useRef, useState } from "react";
import { Form, useNavigate } from "react-router-dom";

import Button from "../../UI/button";
import BackButton from "../../UI/BackButton";
import { tokenLoader } from "../../UI/auth";
import SignatureModal from "../../modals/SignatureModal";
import { showErrorAlert } from "../../UI/alert";
import Spinner from "../../UI/Spinner";
import { IMeetingCutOff } from "../../UI/interface";

const MeetingsPrintOut: React.FC = () => {
  const cutOffPeriodInputRef = useRef<HTMLSelectElement>(null);
  const marketerInputRef = useRef<HTMLInputElement>(null);
  const BDMInputRef = useRef<HTMLInputElement>(null);
  const managerInputRef = useRef<HTMLInputElement>(null);
  const payrollInputRef = useRef<HTMLInputElement>(null);
  const [cutOffList, setCutOffList] = useState<IMeetingCutOff[]>([]);
  const [selectedCutOff, setSelectedCutOff] = useState<
    IMeetingCutOff | undefined
  >();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [showModalSign, setShowModalSign] = useState(false);
  const [signatureImage, setSignatureImage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openModalHandlerSign = () => setShowModalSign(true);
  const closeModalHandlerSign = () => setShowModalSign(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCutOff = async () => {
      setIsLoading(true);
      const token = tokenLoader();
      const responseCutOff = await fetch(
        `${process.env.REACT_APP_API_URL}/meetings/cutoffdate`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const jsonResponse = await responseCutOff.json();
      setCutOffList(jsonResponse);
      setIsLoading(false);
    };
    fetchCutOff();
  }, []);

  useEffect(() => {
    if (selectedCutOff) {
      const fetchMerStatus = async () => {
        const token = tokenLoader();
        const responseMer = await fetch(
          `${process.env.REACT_APP_API_URL}/report/marketerFlagStatus/${selectedCutOff.ReimbursementDateID}`,
          {
            headers: { Authorization: "Bearer " + token },
          }
        );

        const contentType = responseMer.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const jsonResponse = await responseMer.json();

          if (jsonResponse) {
            const isMarketerChecked =
              jsonResponse.ApprovedByMarketer_Flag || false;

            if (marketerInputRef)
              marketerInputRef.current!.checked =
                jsonResponse.ApprovedByMarketer_Flag || false;
            if (BDMInputRef)
              BDMInputRef.current!.checked =
                jsonResponse.ApprovedByBDM_Flag || false;
            if (managerInputRef)
              managerInputRef.current!.checked =
                jsonResponse.ApprovedBySeniorManager_Flag || false;
            if (payrollInputRef)
              payrollInputRef.current!.checked =
                jsonResponse.ApprovedByPayroll_Flag || false;

            setIsSubmitDisabled(isMarketerChecked);
          }
        }
      };
      fetchMerStatus();
    }
  }, [selectedCutOff]);

  const cutOffChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const cutOffOption = cutOffList.find(
      (option) => option.ReimbursementDateID === parseInt(event.target.value)
    );

    marketerInputRef.current!.checked = false;
    marketerInputRef.current!.checked = false;
    managerInputRef.current!.checked = false;
    payrollInputRef.current!.checked = false;

    setSelectedCutOff(cutOffOption);
  };

  const viewVisitHandler = () => {
    if (!selectedCutOff) {
      showErrorAlert("Please select a cutoff period before viewing the visit.");
    } else {
      navigate(
        `/meetingPrintOut/${selectedCutOff?.CutoffDateFrom}/${selectedCutOff?.CutoffDateTo}/${selectedCutOff?.ReimbursementDateDescription}`
      );
    }
  };

  const ViewMerHandler = () => {
    if (!selectedCutOff) {
      showErrorAlert("Please select a cutoff period before viewing the MER.");
    } else {
      navigate(
        `/meetingPrintOut/${selectedCutOff?.ReimbursementDateID}/${selectedCutOff?.CutoffDateFrom}/${selectedCutOff?.CutoffDateTo}/${selectedCutOff?.ReimbursementDateDescription}`,
        {
          state: { signatureImage },
        }
      );
    }
  };

  const printOutSubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    switch (true) {
      case !selectedCutOff:
        showErrorAlert(
          "Please select a cutoff period before adding a signature."
        );
        return;
      case isSubmitDisabled && marketerInputRef.current?.checked:
        showErrorAlert(
          "You cannot add a signature because it has already been submitted"
        );
        return;
    }
    openModalHandlerSign();
  };

  return (
    <div className="p-2 pt-6 min-h-screen text-white bg-gradient-to-t from-[#13547a] to-blue-400">
      <div className="sm:w-full md:w-[750px] md:p-3 mx-auto sm:max-w-3xl lg:max-w-xl">
        <div className="relative">
          <BackButton />
          <div className="text-center">
            <h1 className="text-[30px] mt-[-40px]">Meetings - Print Out</h1>
          </div>
        </div>
        <Form
          className="mt-6 p-2 border-2 rounded-md"
          onSubmit={printOutSubmitHandler}
        >
          <div className="p-2 mt-6 border-2 rounded-md text-center ">
            <label htmlFor="cutOffPeriod">Cut Off Period</label>
            <select
              id="cutOffPeriod"
              className="w-full mt-4 mb-6 h-8 px-2 rounded-md text-black cursor-pointer"
              ref={cutOffPeriodInputRef}
              onChange={cutOffChangeHandler}
              disabled={isLoading}
            >
              <option value=""></option>

              {cutOffList.map((date) => (
                <option
                  key={date.ReimbursementDateID}
                  value={date.ReimbursementDateID}
                >
                  {date.ReimbursementDateDescription}
                </option>
              ))}
            </select>
          </div>
          <div className="p-2 mt-6 mb-6 border-2 rounded-md text-center">
            <label>MER Status</label>
            <ul className="text-start mt-3">
              <li>
                <input
                  className="mt-8 cursor-pointer mr-1"
                  type="checkbox"
                  id="marketer"
                  ref={marketerInputRef}
                  disabled
                />
                <label htmlFor="marketer">Submitted by Marketer</label>
              </li>
              <li>
                <input
                  className="mt-8 cursor-pointer mr-1"
                  type="checkbox"
                  id="BDM"
                  ref={BDMInputRef}
                  disabled
                />
                <label htmlFor="BDM">Approved by BDM</label>
              </li>
              <li>
                <input
                  className="mt-8 cursor-pointer mr-1"
                  type="checkbox"
                  id="manager"
                  ref={managerInputRef}
                  disabled
                />
                <label htmlFor="manager">Approved by Manager</label>
              </li>
              <li>
                <input
                  className="mt-8 mb-8 cursor-pointer mr-1"
                  type="checkbox"
                  id="payroll"
                  ref={payrollInputRef}
                  disabled
                />
                <label htmlFor="payroll">Approved by Payroll</label>
              </li>
            </ul>
          </div>
          <div className="mb-4 flex flex-wrap justify-end items-end lg:justify-around">
            <Button
              className="w-[150px] mb-2 md:mb-0 md:mr-2"
              onClick={viewVisitHandler}
            >
              View Visit
            </Button>
            <Button
              className="w-[150px] mb-2 ml-2 md:ml-0 md:mb-0 md:mr-2"
              onClick={ViewMerHandler}
            >
              View MER
            </Button>
            <Button
              type="submit"
              className={"w-[150px] ml-2 md:ml-0 mb-2 md:mb-0"}
            >
              Add Signature
            </Button>
          </div>
        </Form>
        {isLoading && <Spinner />}
        <SignatureModal
          isVisible={showModalSign}
          onClose={closeModalHandlerSign}
          onSubmitSignature={(imageData) => setSignatureImage(imageData)}
          selectedCutOff={selectedCutOff}
        />
      </div>
    </div>
  );
};

export default MeetingsPrintOut;
