import PatientList from "../../components/Performance/PatientList";

const PatientListPage = () => {
    return(
        <div>
            <PatientList />
        </div>
    )
}

export default PatientListPage;